import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import Icon from '../Icon'

import style from './style'

const IconButton = ({
    classes,
    name = null,
    size = 'md',
    color = 'black',
    iconSize = 'md',
    clickHandler = null,
    classNames = {},
    disabled = false,
}) => {
    return name && (
        <div className={ cx(classes.btn, classNames.btn, classes[size], classes[color], { disabled }) } onClick={ () => typeof clickHandler === 'function' && clickHandler() } >
            <Icon { ...{ name, size: iconSize, className: cx(classes.icon, classNames.icon) } } />
        </div>
    )
}

export default is(style)(IconButton)
