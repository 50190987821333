export default {
    name: 'EN',
    label: 'English',
    slug: 'en',
    ISO: 'en',

    socialLinks: [
        { type: 'Discord', to: 'http://bit.ly/ThunderTierOne' },
        { type: 'Facebook', to: 'https://www.facebook.com/ThunderTierOne/' },
        { type: 'Twitter', to: 'https://twitter.com/thundertierone?lang=en' },
        { type: 'Instagram', to: 'https://www.instagram.com/thundertierone/' },
    ],

    home: {
        seo: {
            title: 'Thunder Tier One',
            description: 'Thunder Tier One is a realistic top-down shooter where the player will join an elite task force fighting enemy operatives. Players must utilize all their tactical skills in real time to neutralize enemy combatants and achieve mission objectives.',
        },

        hero: {
            discordLabel: 'Join on Discord',
            subscribeLabel: 'Subscribe',
            trailerLabel: 'Trailer',
        },

        newsletter: {
            heading: {
                default: 'Subscribe to our newsletter',
                success: 'Well done operator',
            },
            inputPlaceholder: 'Email',
            buttonText: 'Submit',
            checkboxLabel: 'KRAFTON can email me occasionally to let me know about news and limited time swag I can earn (Optional)',
            readMore: {
                label: 'Read More',
                text: '<strong>Receiving Email</strong> In order to provide email relating to the games and services, KRAFTON, Inc. and/or its affiliates processes your personal information with your explicit consent. You have the right to withdraw your consent or opt-out receiving it at any time, even after you have given your consent to the collection and processing of your personal information.',
                link: 'Details',
            },
            messages: {
                success: 'You are subscribed.',
                error: 'Satellite uplink error, please try again.',
                existing: 'You are already signed up!',
                missing: 'Please check and try again',
            },
        },
    },

    press: {
        seo: {
            title: 'About Thunder Tier One',
            description: 'Thunder Tier One is a realistic top-down shooter where the player will join an elite task force fighting enemy operatives. Players must utilize all their tactical skills in real time to neutralize enemy combatants and achieve mission objectives.',
        },

        hero: {
            heading: 'About Thunder Tier One',
            description: "Thunder Tier One is a realistic top-down shooter where the player will join an elite task force fighting enemy operatives. Players must utilize all their tactical skills in real time to neutralize enemy combatants and achieve mission objectives.",
            button: { to: '', label: 'thundertierone.com' },
        },

        about: {
            heading: 'About the </br>developer',
            description: 'Thunder Tier One started as a personal project for PUBG Head of Action and Gunplay Unit Pawel Smolewski when he was working at Bohemia Interactive in 2016. He shared the idea with colleagues Nelson Duarte and Arseniy Smirnov, and together they began developing the game. However, production stopped after a year as the team had other commitments. After restarting work and pitching the idea to Krafton in 2020, the team recruited Pedro Pinho, Jorge Aguiar, Lorenzo Salvadori, Ricardo Mota, Ricardo Sousa, Joao Rebelo and Michał Flodrowski to help them work on the game.',
        },

        krafton: {
            description: 'KRAFTON, Inc. is a collective of independent game development teams responsible for various entertainment properties, including PLAYERUNKNOWN’S BATTLEGROUNDS, ELYON and TERA. The name, which is derived from the word craftsmanship, is an acknowledgement of the company’s never-ending pursuit of innovation and creating engaging experiences. KRAFTON currently consists of PUBG Studio, Bluehole Studio, RisingWings Studio and Striking Distance Studios. For more information about KRAFTON, Inc., please visit https://www.krafton.com.',
        },

        links: {
            left: { heading: 'Press / </br>Business </br>Contact', email: 'mediarequests@krafton.com' },
            right: { heading: 'Press News </br>& Assets', description: 'View Thunder Tier One <a href="https://press.pubg.com/TTO" target="_blank" rel="noopener nofollow">press assets and news</a>' },
        },
    },

    privacy: {
        heading: {
            default: 'Thunder Tier One Privacy Policy',
            california: 'Privacy Notice for California Residents',
        },
        content: {
            default: `
                <p>Effective Date: 2021.12.07</p>

                <h2 id="introduction">1. Introduction</h2>
                <p>KRAFTON, Inc. and/or its affiliates (“KRAFTON”, “we”, “Company” or “us”) collect and process information about you (“you”, “user”, or “player”). We provide video game (“Game(s)”), official sites (“Site(s)”) and services related to the Game (i.e. event, survey, or customer service) (“Service(s)”) to players. This Privacy Policy (“Policy”) describes how we collect, use, and share personal information about you during and after relationship with us in accordance with applicable data protection laws.</p>

                <p>It is important that you read this Policy, together with any other notice we will provide on specific occasions when we are collecting or processing personal information about you, so that you are aware of how and why we are using such personal information and what are your rights.</p>

                <h2 id="information-we-collect">2. Information We Collect</h2>

                <p>We collect information about you and the Services that you use. This information may be required to enter into a contractual relationship with you, or to perform a contractual obligation with you (such as to provide the Service at your request), and failure to provide any information may result in our inability to provide the requested services. The information we collect falls into three different categories:</p>


                <p>1) Information collected directly from you</p>

                <p>You may provide us with the following information when playing our Game and/or using our Services. The information you provide to us may differ depending on the circumstances. For example, these include:</p>

                <ul>
                    <li>Steam ID (to provide or operate the Game), and</li>
                    <li>Email address, country of residence, and information for delivering goods (i.e. name, postal address, and phone number) (for participation in the test, survey, and other events).</li>
                </ul>

                <p>When you participate in social features (such as in-game chat), the information that you disclose is public information. We may store and access it, and it may be read, copied, collected or used by other users without your consent.</p>

                <p>2) Information collected automatically</p>

                <p>We automatically collect certain information when you are playing our Games and/or use our Services. The type of information that we automatically collect may vary, but generally includes:</p>
                    
                <ul>
                    <li>Device information (including but not limited to, IP address, device ID, device model, network type, OS version, time zone settings, battery status, screen resolution, etc.),</li>

                    <li>Device event information, including crash reports and system activity details (e.g. whether you encountered an error using our services or lost internet access, etc.), and</li>

                    <li>Your browsing behavior while using our Site and/or Services.</li>
                </ul>

                <p>3) Information collected from third-parties</p>

                <p>When you access our Games and/or Services using a third-party platform, we may receive certain information from your profile/account on third-party platform’s as permitted by the terms of the third-party platform and your privacy settings thereon. For example, Steam ID, etc.</p>


                <h2 id="cookies-and-related-technologies">3. Cookies and Related Technologies</h2>

                <p>We use cookies and other technologies to recognize your browser or device, learn more about your interests, and provide you with essential features and services and for additional purposes. Approved third parties may also set cookies and related technologies when you use our site or games.</p>

                <p>1) Cookies</p>

                <p>Cookies are small files downloaded to your computer (if your browser is enabled to accept cookies) to track movements within websites and to customize your experience within the website. Most Internet browsers will allow you to erase cookies from your computer hard drive, block acceptance of cookies, or receive a warning before a cookie is stored. You should refer to your browser instructions or “Help” screen to learn more about how to manage cookies. Please note, however, that if you block cookies, some portions of the Site may not function properly.</p>

                <p>We do not control cookies in third party ads, and you are encouraged to check the privacy policies of advertisers and/or ad services to learn about their use of cookies and other technology. The ads appearing on the Site may be delivered to you by third-party advertising companies. These companies may use information about your visits to this and other websites in order to provide advertisements on the Site and other sites about goods and services that may be of interest to you.</p>


                <p>2) Website Analysis</p>

                <p>We use analysis services on this Site to allow us to record information on the use of this Site and to constantly improve our offering.</p>

                <p>This Site uses Google® Analytics, a web analysis services offered by Google Inc. (“Google”). We may use the following advertising functions of Google® Analytics and collect personal information as provided by Google, and you may withdraw your consent by deactivating such functions in Google’s advertising settings: Remarketing by using Google® Analytics; Google® Display Network Exposure Report; Google® Analytics Demographics and Interests Report; and Integrated service allowing the collection of data through Google® Analytics by using advertising cookies and anonymous IDs.</p>

                <p>More information about Google’s own privacy policy can be found at <a href="https://www.google.com/policies/privacy" target="_blank" rel="noreferrer">https://www.google.com/policies/privacy.</a></p>

                <p>If you wish to opt-out from your information or data being used by Google Analytics, you may download the Google Analytics Opt-out Browser Add-on available here: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout</a></p>

                <p>4) Anti-Cheat and Fraud Prevention Technology</p>

                <p>We strive to provide a safe and fair gaming environment for all players. When you play the Game, we or third parties may use cookies and similar technologies, or collect data about your machine or device, for fraud prevention, security and authentication purposes. We also may use other anti-cheat technology with our services.</p>


                <h2 id="how-we-use-your-information">4. How We Use Your Information</h2>

                <p>We use the collected information for a variety of purposes, primarily, to provide the Game and the Service (collectively, the “Game Service”) to players like you and to improve the quality of the Game Service. These include:</p>

                <ul>
                    <li>Operating and providing the Game Service,</li>
                    <li>Providing opportunities to participate on the events, or surveys that relating to the Game Service,</li>
                    <li>Solving technical problems and improving the quality of the Game Service (for example, to provide realistic gaming environment to users),</li>
                    <li>Identification of individual players to provide the Game Service,</li>
                    <li>Technical protection against usage of unauthorized programs,</li>
                    <li>Preventing inappropriate gameplay that could harm other players,</li>
                    <li>Prevent, detect, identify, investigate, and respond to potential or actual claims, liabilities, prohibited behavior, and criminal activity.</li>
                </ul>

                <p>In the majority of cases, our processing of your personal information is necessary to respond to you or process your request. We may also process your information to comply with a relevant legal obligation. From time to time, we may ask for your consent to use your information for certain specific reasons. You may withdraw your consent at any time without affecting the lawfulness of processing based on consent before its withdrawal by contacting us at the address provided in this Policy.</p>

                <h2 id="sharing-your-information">5. Sharing Your Information</h2>

                <p>We give permission to third parties, including service providers which process personal information on behalf of us to provide customer support and to manage our community. Examples of these third parties include:</p>

                <ul>
                    <li>third party program restriction providers to block cheating tools and unauthorized programs,</li>
                    <li>cloud computing service providers to provide cloud storage services,</li>
                    <li>online survey service providers in order to improve the Game.</li>
                </ul>

                <p>We may also share your information where such disclosure is essential to comply with a legal obligation or a lawful data access request, pursuant to a merger or acquisition, with our affiliates for the purposes described in this Policy, or where you have otherwise provided your consent or at your direction.</p>

                <h2 id="international-data-transfers">6. International Data Transfers</h2>

                <p>We operate globally and transfer your data to countries and other regions outside of where you are located. If your personal information is transferred, stored or processed by us, we will take reasonable steps to safeguard the privacy of personal information. For certain countries we process the data in certain regions (as set out in more detail below).</p>

                <h2 id="retaining-and-deleting-personal-information">7. Retaining and Deleting Personal Information</h2>

                <p>In principle, we will remove your personal information without delay after the purpose of collecting personal information is achieved. Personal information stored in physical form will be shredded or incinerated, and personal information stored electronically will be removed using technical measures in a way that the information cannot be reproduced. However, we may retain your personal information even after you have closed your account with us or we have ceased providing Services to you, if retention of your personal information is reasonably necessary to comply with our legal obligations, meet regulatory requirements, resolve disputes, prevent fraud, cheating or abuse, or enforce this Policy or any other agreement we may have with a player.</p>

                <h2 id="our-policy-concerning-children">8. Our Policy Concerning Children</h2>

                <p>We do not knowingly collect, use or share any information about children (generally considered to be under the age of 13 in the U.S. or depending on the country where you reside) without verifiable parental consent or as permitted by law. If you are a parent or guardian and you believe your child has provided us with information, you can contact us and request your child’s information to be deleted from our system.</p>

                <h2 id="link-and-third-party-services">9. Links and Third-Party Services</h2>

                <p>If you click on a link to a third-party website, including, without limitation, an advertisement, you will leave this website and go to the site you selected. Because we cannot control the activities of third parties, we do not have responsibility for any use of your personal information by such third parties, and we cannot guarantee that they will adhere to the same privacy and security practices as we do. We encourage you to review the privacy policies of any other service provider from whom you request services. If you visit a third-party website that is linked to the Site, you should consult that site’s privacy policy before providing any personal Information.</p>

                <h2 id="security-of-your-information">10. Security of Your Information</h2>

                <p>We have put in place appropriate security measures, including encryption and de-identification, to prevent your personal information from being accidentally leaked, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal information to those employees, agents, etc. who have a business need to know. They will only process your personal information on our instructions, and they are subject to a duty of confidentiality. We have put in place safety procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so. However, we will not be responsible for any problems that may arise of despite those safety measures and procedures, such as your negligence or problems on the internet. Please note that no data transmission over the internet is 100% secure, and that complete security of any information we collect and use therefore cannot be guaranteed.</p>

                <h2 id="your-privacy-rights">11. Your Privacy Rights</h2>

                <p>Subject to limitations in applicable law, you are entitled to object to or request the restriction of processing of your personal information, and to request access to, rectification, erasure, and portability of your own personal information. To make a request concerning your rights or if you have any other question, please contact us by sending an email to the address specified in the “13. Contact Us” section. We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.</p>

                <h2 id="amendments">12. Amendments</h2>

                <p>We may amend, at our discretion, any portion of this Policy at any time by posting or displaying the amended this Policy on our Site. You will be deemed to have accepted such amendments by continuing to use the Service, so please review it frequently.</p>

                <h2 id="contact-us">13. Contact Us</h2>

                <p>If you have any questions about this Policy, or your dealings with this Site, please contact us by using one of the following communication channels.</p>

                <ul>
                    <li>Email: <a href="mailto:privacy@krafton.com" target="_blank">privacy@krafton.com</a></li>
                    <li>Address: Centerfield East Tower, 231, Teheran-ro, Gangnam-gu, Seoul, Republic of Korea</li>
                </ul>

                <p>This Privacy Policy has been translated into other languages for your convenience. In case of any discrepancies between the English version and the other versions, the English version shall prevail. However, Korean Privacy Policy shall prevail for Korean players.</p>

                <h2 id="supplemental-terms-jurisdiction-specific">14. Supplemental Terms - Jurisdiction Specific</h2>

                <p>In the event of a conflict between of the supplemental Terms – Jurisdiction Specific that are relevant to your jurisdiction from which you are playing our Games or using our Services, and the rest of the Policy, the relevant jurisdiction’s Supplemental Terms – Jurisdiction Specific will supersede and control.</p>

                <h3>United States</h3>
                
                <h4>California</h4>

                <p>If you are a California residents, you may have additional rights. For more information concerning your privacy rights, please review our <a href="/en/privacy/california">Privacy Notice for California Residents.</a></p>

                <h4>Nevada</h4>

                <p>If you are a Nevada residents, we do not sell your personal information that we collected, Nonetheless, if you are Nevada residents who purchased our game items or goods from us, you may submit a request to record your preference to opt out for the future.</p>

                <h3>EEA, Switzerland and UK</h3>

                <p>If you are an EEA, Switzerland and UK residents, you may have additional rights.</p>

                <h4>International data transfers</h4>

                <p>If we transfer your data outside of the EEA to a country that is not recognized by the European Commission as ensuring an adequate level of data protection, we implement appropriate measures to safeguard your personal data in accordance with applicable data protection and privacy laws. These safeguards may include data transfer agreements implementing standard contractual clauses (a form of data transfer agreement pre-approved by the European Commission as providing adequate safeguards for personal data). You may ask for a copy of such appropriate measures by contacting us as set out in this Policy. We otherwise transfer personal data with consent; to perform a contract with you; or to fulfill a compelling legitimate interest in a manner that does not outweigh your rights and freedoms. If you have questions about this Policy you can contact us at data protection officer(<a href="mailto:DPO@pubg.com" target="_blank" rel="noreferrer">DPO@pubg.com</a>)</p>
            `,
            california: `
                <p>Effective Date: Dec 07, 2021</p>

                <p>TThis Privacy Notice (“Notice”) for California residents provided pursuant to the California Consumer Privacy Act (“CCPA”) supplements the <a href="/en/privacy">Privacy Policy</a> of KRAFTON, Inc. and its affiliates (“KRAFTON”, “we”, or “us”) and provides additional information on how your personal information is collected, used, disclosed and processed online or offline. Unless otherwise specified, all terms used within this Notice shall carry the same meaning as they are defined in our <a href="/en/privacy">Privacy Policy</a>. This Notice and the <a href="/en/privacy">Privacy Policy</a> together constitutes KRAFTON’s “California Privacy Policy”.
                </p>

                <h2>1. Information We Collect</h2>

                <p>We will collect the following categories of information that may directly or indirectly identify or describe you or your device, or information that may be reasonably capable of being associated with or reasonably linked to you or your device (“personal information”). We have collected these same categories of personal information from consumers over the past 12 months. Please refer to our <a href="/en/privacy">Privacy Policy</a> concerning the categories of sources from which this personal information is collected.</p>

                <ul>
                    <li>Identifiers such as IP address, Steam ID;</li>
                    <li>Internet or other similar network activity information such as website, application or advertisement browsing history, device model, OS version, device storage, and information about the device that you are playing.</li>
                </ul>

                <h2>2. Purposes of Collection</h2>

                <p>The above personal information was or will be used for the following purposes. We will also notify you and obtain your consent if and when previously collected personal information is used for any purpose other than those listed below.</p>

                <ul>
                    <li>Operating and providing the Game Service,</li>
                    <li>Providing opportunities to participate on the events, or surveys that relating to the Game Service,</li>
                    <li>Solving technical problems and improving the quality of the Game Service (for example, to provide realistic gaming environment to users),</li>
                    <li>Identification of individual players to provide the Game Service,</li>
                    <li>Technical protection against usage of unauthorized programs,</li>
                    <li>Preventing inappropriate gameplay that could harm other players,</li>
                    <li>Prevent, detect, identify, investigate, and respond to potential or actual claims, liabilities, prohibited behavior, and criminal activity.</li>
                </ul>

                <h2>3. Personal Information Disclosed to Third Parties</h2>

                <p>We may provide service providers or third parties with certain personal information to provide or improve our services. Within the past 12 months, we disclosed or shared each of the following categories of personal information with the following categories of third parties. We do not ‘sell’ personal information to third parties as that term is defined by the CCPA.</p>

                <table>
                    <thead>
                        <tr>
                            <th>Categories of Personal Information</th>
                            <th>Categories of Third-Parties of whom Personal Information Was Disclosed</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Identifiers</td>
                            <td rowspan="2">
                                <ul>
                                    <li>Program restriction providers</li>
                                    <li>cloud computing service providers</li>
                                    <li>online survey service providers</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Internet or other similar network activity</td>
                        </tr>
                    </tbody>
                </table>


                <h2>4. California Privacy Rights</h2>

                <p>You may exercise the following rights at any point in time in connection with your personal information. To exercise such rights, please email us at <a href="mailto: privacy@krafton.com" rel="noreferrer" target="_blank">privacy@krafton.com.</a></p>

                <p>Please note that at least your email address will be required to submit a request. If you exercise your rights via email, you must specify the right(s) you wish to exercise.</p>

                <p>We will verify your identity and respond to your request within 45 calendar days. If necessary, we can take up to an additional 45 days to respond, for a maximum total of 90 days from the day the request is received, provided that we notify you to explain the reason for the delay.</p>

                <table>
                    <thead>
                        <tr>
                            <th>Your Rights</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Right to Access</td>
                            <td>
                                <p>You have the right to request the following information up to two times within a 12-month period.</p>
                                <ul>
                                    <li>The categories of personal information that we have collected from you over the past 12 months, and the specific pieces of personal information we have collected from you</li>
                                    <li>Categories of the sources from which personal information was collected</li>
                                    <li>Business or commercial purposes for which personal information is collected</li>
                                    <li>Categories of the personal information that was disclosed for a business purpose or sold during the past 12 months, and categories of third parties to whom the information was disclosed or sold.</li>
                                </ul>
                            </td>
                        </tr>
                        <td>Right to Request Deletion</td>
                        <td>You have the right to request that the personal information collected from you be deleted.</td>
                        </tr>
                        <tr>
                            <td>Right to Opt-Out of Personal Information Sales</td>
                            <td>You have the right to “opt out” by requesting that your personal information not be sold in any form. However, we do not sell personal information.</td>
                        </tr>
                        <tr>
                            <td>Right to Non-Discrimination</td>
                            <td>You have the right to not be discriminated against for exercising your privacy rights under the CCPA.</td>
                        </tr>
                        <tr>
                            <td>Rights for California Minors</td>
                            <td>Minors under the age of 18 have the right to request the deletion of and/or obtain information posted on websites.</td>
                        </tr>
                        <tr>
                            <td>Shine the Light</td>
                            <td>You have the right to request the categories of the personal information that we disclosed to third parties in the last year for direct marketing purposes and information on the relevant third parties. Furthermore, you may also request that your personal information not be disclosed to third parties for direct marketing purposes.</td>
                        </tr>
                    <tbody>
                </table>

                <h2>5. Authorized Agent</h2>

                <p>You may designate another person or entity (“authorized agent”) to act on your behalf in connection with your request for rights that are guaranteed by the CCPA. An authorized agent is a natural person or business entity registered with the Secretary of State to conduct business in California that you have authorized to act of your behalf to make a request to know or to delete.</p>

                <p>In order to use an authorized agent for your request, we require that you provide signed permission to your agent, using the form available at <a href="/CCPA-Agent-Authorization-Form.pdf" target="_blank" download>this link</a>. Your authorized agent can submit a request to know or delete by sending an email to <a href="privacy@krafton.com" target="_blank" rel="noreferrer">privacy@krafton.com</a> attaching the signed permission.</p>

               <p>The above requirement does not apply should your agent have power of attorney pursuant to Probate Code sections 4000 to 4465. Please note that we may deny a request from an agent that does not submit proof that they have been authorized by you to act on your behalf. Under the CCPA, your agent is responsible for implementing and maintaining reasonable security procedures and practices to protect your information and use your personal information only for the purpose of fulfilling your request.</p>

               <h2>6. Contact Us</h2>

               <p>If you have any questions about this Notice or about exercising your rights described above, you can contact us by email at <a href="mailto:privacy@krafton.com" target="_blank" rel="noreferrer">privacy@krafton.com.</a></p>
            `,
        },
    },

    terms: {
        heading: 'Thunder Tier One Official Website Terms of Service',
        content: `
            <p>Effective Date: 10 of Dec 2020</p>

            <p>KRAFTON, Inc. (“KRAFTON”, “we”, or “us”) provides through its official website (OOO), game related services (Collectively, “Service(s)”). This Terms of Service govern the relationship between you and KRAFTON regarding the use of the Services (the “Terms”) and “user” or “you” means the person who agrees to these Terms. By using the Services, you accept and agree to be bound and abide by these Terms and If you do not want to agree to these Terms, you must not use the Service.</p>

            <h2>1. Updating Services and Terms</h2>
            <ol>
                <li>1) The Terms of Service can be found at any time at KRAFTON’s website.</li>
                <li>2) KRAFTON may change these Terms at any time if necessary to the extent that is consistent with applicable laws and regulations.</li>
                <li>3) You are responsible to check this page regularly to make note of any changes. Your continued use of the Services means that you accept and agree to the changes in the Terms.</li>
            </ol>

            <h2>2. Prohibited Uses</h2>
            <p>KRAFTON provides the Service to users only for personal, non-commercial and entertainment purposes. You agree not to violate terms and conditions set in these Terms . Violation of any criteria in this agreement may lead to temporary or permanent suspension of the Service in accordance with these Terms. You agree to only use the Service, or any part of it, in a manner that is consistent with these Terms and you SHALL NOT:</p>
            <ul>
                <li>(a) without written permission from KRAFTON, use, advertise or exploit in any manner the Service or any of its parts commercially, including but not limited to use at a cyber (Internet) café, computer gaming center or any other location-based site;</li>
                <li>(b) reverse engineer, derive source code, modify, decompile, disassemble, or create derivative works of the Service, in whole or in part (except as the applicable law expressly permits, in which case all and any lawful modifications, adaptations, improvements, etc., and all copyrights and moral rights therein, shall be deemed assigned to, and shall belong to, vest in and be the exclusive property of KRAFTON and/or its licensors on creation, in any event);</li>
                <li>(c) remove, disable or circumvent any security protections or any technical measures that control access to the Service;</li>
                <li>(d) remove, modify, deface or circumvent any proprietary notices or labels contained on or within the Service;</li>
                <li>(e) steal and/or publish information from others (including but not limited to personal information, non-personal information and payment information);</li>
                <li>(f) impersonate KRAFTON or our staff;</li>
                <li>(g) harm the reputation of KRAFTON, our affiliates, our service providers and licensors;</li>
                <li>(h) post, link to, advertise, promote, or transmit anything that is illegal, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, sexually explicit, obscene, hateful, or racially or ethnically offensive;</li>
                <li>(i) take advantage by exploiting others in any way;</li>
                <li>(j) use the Service in a manner that is illegal or that negatively impacts the delivery of the Service;</li>
                <li>(k) access or use the Services for any purpose that is illegal or beyond the scope of the Services’ intended use (in Company’s sole judgment);</li>
                <li>(l) disrespect or use offensive words towards others, including based on their race, gender, nationality;</li>
            </ul>
            <p>Any action which is not defined in these Terms that negatively impacts the delivery of KRAFTON’s Service or negatively affects other users may be subject to warning and penalty upon careful review of the circumstances.</p>

            <h2>3. Accessing the Service</h2>
            <p>As a general rule, the service is accessible for 24 hours a day throughout the whole year.  However, the accessibility of the Service is subject to KRAFTON shutting down the Service, in whole or in part, for any period KRAFTON deems appropriate to enable the update or technical maintenance of the Service, or any other action that KRAFTON determines necessary in its sole discretion.</p>
            <p>We may amend the Service or restrict access from all or specific users without notice and liability. Additionally, the Service may be unavailable depending on geographic location.</p>

            <h2>4. Ownership</h2>
            <p>You agree and acknowledge that all title, ownership rights, and intellectual property rights connected with the Service and any and all copies thereof (including but not limited to any derivative works, titles, computer code, themes, objects, characters, character names, stories, dialogs, catch phrases, locations, concepts, artwork, graphics, animation, sounds, musical compositions, audio-visual effects, text, screen displays, methods of operation, moral rights, and any related documentation) are owned by KRAFTON or its licensors.</p>

            <h2>5. Limitation of Liability & Indemnification</h2>
            <p>KRAFTON will not be responsible for any damage or loss caused to you where such damage or loss is not reasonably foreseeable to you and us when you use the Service, including where the damage or loss results from our breach of these Terms.</p>
            <p>The Service may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons beyond our reasonable control. Except as set out elsewhere in these Terms we will not be liable to you or to any other person in the event that all or part of the Service is unavailable, discontinued, modified or changed in any way.</p>
            <p>KRAFTON does not accept any responsibility for:</p>
            <ul>
                <li>(a) any damage or loss caused to you where you are not entering into these Terms as a consumer;</li>
                <li>(b) any failure, any suspension and/or any termination of access to the Game or the Service or any content in connection with or arising out of a force majeure event. In these Terms, a "force majeure" event shall include, without limitation, strikes, lock-outs or other industrial disputes, nuclear accident or acts of God, war or terrorist activity, riot, civil commotion, malicious damage, compliance with any law or government order, rule regulation or direction, accident or breakdown of plant or machinery, fire, flood, storm or default of suppliers, and where they are beyond our reasonable control, any other acts, events, omissions or accidents; and/or</li>
                <li>(c) any claims brought against you by a third party except as stated in these Terms.</li>
                <li>(d) any interruption of the service, the use of the service, or termination of the contract due to user’s fault.</li>
                <li>(e) if the telecommunications providers cease or fail to provide the telecommunication service. Users shall be liable for any damages incurred during failure of telecommunication service.</li>
                <li>(f) any unavoidable reasons that caused Service failure, such as maintenance, replacement, regular inspection, construction, etc..</li>
                <li>(g) any problems caused by the user’s computer or problems related to the network environment.</li>
                <li>(h) the contents of the information, data, facts, reliability, accuracy, etc. posted or transmitted by the user or a third party in the service or on the website of KRAFTON.</li>
                <li>(i) damages from Services provided free of charge by KRAFTON.</li>
                <li>(j) damages caused by other services providers.</li>
            </ul>
            <p>Nothing in these Terms will:</p>
            <ul>
                <li>(a) restrict your statutory rights as a consumer (including your rights to state or to receive a reasonable standard of service, more details about which can be obtained from your local Trading Standards Office or Citizen's Advice Bureau); or</li>
                <li>(b) limit our liability for death or personal injury resulting from our negligence or for fraud.</li>
            </ul>
            <p>By using the Service, you agree to defend, protect, indemnify and hold harmless to KRAFTON from any claims, lawsuits, damages, losses, liabilities and expenses (including attorneys’ fees) arising out of or in conjunction to your use or misuse of the Service and violation of KRAFTON’s policy. KRAFTON reserves the right to assume exclusive defense and control over all matters subject to indemnification by users.</p>
            <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL KRAFTON BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO LOSS OF BUSINESS, LOSS OF DATA, LOSS OF BUSINESS RIGHTS, LOSS OF PROFITS) AND DAMAGE (INCLUDING BUT NOT LIMITED TO INTERRUPTION OF WORK OPERATION, HARDWARE MALFUNCTION, OR OTHER COMMERCIAL DAMAGE OR LOSS) ARISING OUT OF YOUR USE OR MISMUSE OF THE SERVICE. THIS IS ALSO TRUE WHETHER OR NOT KRAFTON ALREADY KNEW OR HAD TO KNOW ABOUT THE POSSBILITY OF THE DAMAGES.</p>
            <p>COMPANY SHALL NOT BE LIABLE FOR ANY INTERRUPTION OF SERVICE, INCLUDING, BUT NOT LIMITED TO, ISP FAILURE, SOFTWARE OR HARDWARE FAILURE, OR ANY OTHER SITUATION THAT MAY RESULT IN DATA LOSS OR SERVICE INTERRUPTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS ARE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW IN SUCH COUNTRIES OR JURISDICTIONS.</p>

            <h2>6. Disclaimer of Warranties</h2>
            <p>THE SERVICES AND CONTENT ARE PROVIDED ON AN “AS IS” BASIS AND AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY LAW, KRAFTON HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES OR CONDITIONS OF ANY KIND, WRITTEN OR ORAL, EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTY OF TITLE, NONINFRINGEMENT, MERCHANTABILITY, QUIET ENJOYMENT, ACCURACY, OR FITNESS FOR A PARTICULAR PURPOSE.</p>
            <p>Without limiting any of the foregoing, KRAFTON does not ensure continuous, error-free, secure or virus-free operation of KRAFTON’s Services. Some states or countries do not allow the disclaimer of implied warranties, and the foregoing disclaimer may not be applicable to You. This warranty gives You specific legal rights, and You may also have other legal rights that vary from state to state or from country to country.</p>

            <h2>7. Period and Termination</h2>
            <p>These Terms are effective unless and until terminated by either you or by KRAFTON. KRAFTON and the user may terminate these terms at any time without prior notice. Termination by KRAFTON will come into an effect at any time in KRAFTON’s sole discretion. You may terminate these Terms by not using the Service.  Sections 4 (Ownership), 5 (Limitation of Liability and Indemnification), 6 (Disclaimers of Warranties), 7 (Period and Termination), 8 (Grievance Handling and Dispute Resolution), 9 (Governing Law), 10 (Class Action Waiver) and 12 (Other Policies) shall survive any termination of these Terms.</p>

            <h2>8. Grievance Handling and Dispute Resolution</h2>
            <p>If a dispute arises between you and KRAFTON, our goal is to provide you with a neutral and cost-effective means of resolving the dispute quickly. Accordingly, except where and to the extent prohibited by law, by using the Services, you and KRAFTON agree to the use of arbitration on an individual basis through a mutually agreed arbitrator selected with the consent of the Company and you, where a claim is for less than $10,000 US Dollars or the equivalent. ARBITRATION ON AN INDIVIDUAL BASIS MEANS THAT YOU WILL NOT HAVE, AND YOU WAIVE, THE RIGHT FOR A JUDGE OR JURY TO DECIDE YOUR CLAIMS, AND THAT YOU MAY NOT PROCEED IN A CLASS, CONSOLIDATED, OR REPRESENTATIVE CAPACITY. Other rights that you and we would otherwise have in court will not be available or will be more limited in arbitration, including discovery and appeal rights. All such disputes shall be exclusively submitted to the chosen arbitrator for binding arbitration under the arbitrator’s rules then in effect, before one arbitrator to be mutually agreed upon by both parties. </p>

            <h2>9. Governing Law</h2>
            <p>These Terms shall be interpreted and governed by the laws of the Republic of Korea. All lawsuits related to these Terms are subject to the jurisdiction of the courts pursuant to relevant laws such as the Korean Code of Civil Procedure. Other laws may apply if you use the Service outside of the Republic of Korea. In such event, the applicable local laws shall only affect these Terms to the extent required by the applicable jurisdiction, and these Terms shall be construed as to make their terms and conditions as effective as possible. You must comply with all local laws to the extent that such local law applies.</p>

            <h2>10. Class Action Waiver</h2>
            <p>You agree not to participate in, including but not limited to, a class or representative action, private attorney general action, or collective arbitration related to the Service or this Agreement. You also agree not to seek to combine any action or arbitration related to the Service or this Agreement with any other action or arbitration without the consent of all parties to this Agreement and all other actions or arbitrations.</p>

            <h2>11. Copyright Notice</h2>
            <p>If you think any materials from KRAFTON and/or KRAFTON branded websites infringe your copyright-protected work under the copyright law, you may provide an infringement notice to KRAFTON’s designated agent as set forth below. Also, to ensure your notice is effective, you must include the following information:</p>
                <li>(a) Sufficient details to enable us to identify the work claimed to be infringed;</li>
                <li>(b) Your contact information, including your address and email address;</li>
                <li>(c) A statement that you have a good faith belief that the disputed work is not authorized by the copyright owner, its agent, or the law;</li>
                <li>(d) A statement, under penalty of perjury, that the information in the notification is accurate and that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; and</li>
                <li>(e) Your physical or electronic signature.</li>

            <p>The contact information of Company’s designated agent is as follows:</p>
            <ul>
                <li>KRAFTON, Inc.</li>
                <li>Attn: Legal Affairs Department Agent</li>
                <li>Tower, 117, Bundangnaegok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea. </li>
                <li>Email: privacy@krafton.com</li>
            </ul>
            <h2>12. Other policies</h2>
            <ol>
                <li>1) KRAFTON may use the Privacy Policy to cover specific details that are not covered in Terms of Service in order to protect user interests and maintain order.</li>
                <li>2) By agreeing to conditions set in Terms of Service, you also agree to be bound by the terms and conditions set forth in the Privacy Policy.</li>
                <li>3) the Privacy Policy can be found at any time at KRAFTON’s website.</li>
            </ol>
            <p>This Terms of Service has been translated into other languages for the convenience of the users. In case of any discrepancies between the English version and the other versions, the English version shall prevail. However, Korean Terms of Service shall prevail for Korean users.</p>
        `,
    },

    header: {
        links: [
            { to: 'press', label: 'Press Pack' },
        ],
    },

    menu: [
        { to: '', label: 'Thunder Tier One' },
        { to: 'press', label: 'Press Pack' },
        { to: 'privacy', label: 'Privacy' },
    ],

    footer: {
        links: [
            { to: 'press', label: 'Press Pack' },
            { to: 'privacy', label: 'Privacy' },
            { to: 'terms', label: 'Terms' },
        ],
        icon: {
            type: 'pegi',
            src: '/pegi16.jpg',
            alt: 'Pending rating (PEGI 16)',
        },
        termsMessage: 'KRAFTON, Inc. All Rights Reserved',
    },

    languageToggle: {
        questionText: 'Would you like to change to <strong class="c-blonde">{LANG}</strong>',
        yesLabel: 'Yes',
        noLabel: 'No',
    },

    error: {
        seo: {
            title: 'Error 404: Not Found',
            description: 'Thunder Tier One is a realistic top-down shooter where the player will join an elite task force fighting enemy operatives. Players must utilize all their tactical skills in real time to neutralize enemy combatants and achieve mission objectives.',
        },
        heading: 'The page you’re looking for can’t be found.',
    },
}
