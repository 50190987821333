import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import htmlParser from 'react-html-parser'
import cx from 'classnames'
import Cookies from 'universal-cookie'
import is from 'react-jss'

import translations from '../../../config/translations'

import Icon from '../Icon'
import IconButton from '../IconButton'
import Button from '../Button'

import style from './style'

const LanguageSuggestion = ({
    classes,
    ISO,
}) => {
    const [ suggestedLanguage, setSuggestedLanguage ] = useState(false)
    const [ loaded, setLoaded ] = useState(false)

    const cookies = new Cookies()
    const stopLanguageSuggest = cookies.get('tto_stop_language_suggest')

    const browserISOs = (typeof window === 'undefined') ? [] : window.navigator.languages.map((code) => code.replace('-', '_'))
    const acceptCurrentLanguage = browserISOs.indexOf(ISO) >= 0

    const handleClose = () => {
        cookies.set('tto_stop_language_suggest', 'yes')
        setLoaded(false)
    }

    const handleAccept = () => {
        cookies.set('tto_stop_language_suggest', 'yes')
        setLoaded(false)
    }

    useEffect(() => {
        if (!acceptCurrentLanguage && !stopLanguageSuggest) {
            setSuggestedLanguage(translations.find(({ ISO }) => browserISOs.indexOf(ISO) >= 0))

            setTimeout(() => {
                setLoaded(true)
            }, 1000)
        }
    }, [ suggestedLanguage ])

    return suggestedLanguage?.label ? (
        <div className={ cx(classes.languageSuggestion, { loaded }) }>
            <div className={classes.inner}>
                <IconButton
                    name="close"
                    iconSize="sm"
                    size="sm"
                    color="white"
                    classNames={{ btn: classes.close }}
                    clickHandler={ () => handleClose() }
                />
                <Icon name="globe" size="lg" className={classes.icon} />
                <p>{ htmlParser(suggestedLanguage.languageToggle.questionText.replace('{LANG}', suggestedLanguage.label)) }?</p>

                <div className={classes.buttons}>
                    <Link
                        to={ `/${suggestedLanguage.slug}` }
                        size="sm"
                        onClick={ () => handleAccept() }
                        className={classes.link}
                    >{ suggestedLanguage.languageToggle.yesLabel }</Link>
                    <Button
                        color="white"
                        size="sm"
                        clickHandler={ () => handleClose() }
                        className={classes.btn}
                    >{ suggestedLanguage.languageToggle.noLabel }</Button>
                </div>
            </div>
        </div>
    ) : null
}

export default is(style)(LanguageSuggestion)
