export default {
    name: 'KR',
    label: '한국어',
    slug: 'kr',
    ISO: 'kr',

    socialLinks: [
        { type: 'Discord', to: 'https://discord.gg/thundertierone' },
        { type: 'Facebook', to: 'https://www.facebook.com/ThunderTierOne/' },
        { type: 'Twitter', to: 'https://twitter.com/thundertierone?lang=en' },
        { type: 'Instagram', to: 'https://www.instagram.com/thundertierone/' },
    ],

    home: {
        seo: {
            title: 'Thunder Tier One',
            description: 'Thunder Tier One is a realistic top-down shooter where the player will join an elite task force fighting enemy operatives. Players must utilize all their tactical skills in real time to neutralize enemy combatants and achieve mission objectives.',
        },

        hero: {
            discordLabel: 'Discord 커뮤니티 가입',
            subscribeLabel: '구독',
        },

        newsletter: {
            heading: {
                default: '뉴스레터 구독',
                success: '잘하셨습니다, 요원',
            },
            inputPlaceholder: '이메일',
            buttonText: '제출',
            checkboxLabel: 'KRAFTON이 제공하는 이벤트 등 프로모션 안내 메일을 수신합니다',
            readMore: {
                label: '(선택) 더 보기',
                text: '<strong>이메일 수신</strong> 게임 및 서비스와 관련된 이메일을 제공하기 위해, KRAFTON 또는 그 계열사는 귀하의 동의에 따라 개인 정보를 처리합니다. 귀하는 개인 정보 수집 및 처리에 동의 한 후에도 언제든지 동의를 철회하거나 수신 거부 할 권리가 있습니다.',
                link: ' 자세히',
            },
            messages: {
                success: '구독이 완료되었습니다.',
                error: '위성 업링크 오류, 다시 시도하세요',
                existing: 'You are already signed up!',
                missing: '확인 후 다시 시도하세요',
            },
        },
    },

    press: {
        seo: {
            title: '개발자 소개',
            description: 'Thunder Tier One은 현실감 넘치는 탑다운 슈팅 게임으로, 플레이어는 적의 요원을 상대하기 위한 엘리트 기동 부대의 일원이 됩니다. 적의 전투 부대를 제압하고 미션 목표를 완료하려면 모든 전술 능력을 실시간으로 발휘해야 합니다.',
        },

        hero: {
            heading: '개발자 소개',
            description: "Thunder Tier One은 현실감 넘치는 탑다운 슈팅 게임으로, 플레이어는 적의 요원을 상대하기 위한 엘리트 기동 부대의 일원이 됩니다. 적의 전투 부대를 제압하고 미션 목표를 완료하려면 모든 전술 능력을 실시간으로 발휘해야 합니다.",
            button: { to: '', label: 'thundertierone.com' },
        },

        about: {
            heading: '개발자 소개',
            description: 'Thunder Tier One의 시초는 플레이어언노운스 배틀그라운드(PLAYERUNKNOWN’S BATTLEGROUNDS, PUBG)의 액션 및 건플레이(Action and Gunplay) 총괄 책임자인 Pawel Smolewski가 2016년에 Bohemia Interactive에서 근무할 당시에 진행하던 프로젝트였습니다. 그는 자신의 아이디어를 동료인 Nelson Duarte 및 Arseniy Smirnov와 공유했고, 함께 게임을 개발하기 시작했죠. 그러나 다른 업무로 인해 1년 만에 개발이 중단된 바 있습니다. 이후 2020년, 작업을 다시 시작하고 PUBG 스튜디오가 속한 ㈜크래프톤에 아이디어를 제안한 끝에 팀은 게임 개발에 도움을 줄 Pedro Pinho, Jorge Aguiar, Lorenzo Salvadori, Ricardo Mota, Ricardo Sousa, Joao Rebelo 및 Michał Flodrowski를 영입했습니다.2020년에 작업을 다시 시작하고 Krafton에 아이디어를 제안한 끝에 팀은 게임 개발에 도움을 줄 Pedro Pinho, Jorge Aguiar, Lorenzo Salvadori, Ricardo Mota, Ricardo Sousa, Joao Rebelo 및 Michał Flodrowski를 영입했습니다.',
        },

        krafton: {
            description: "크래프톤은 명작 게임 제작에 대한 장인정신과 끊임없는 도전을 추구하는 스튜디오들로 구성되어 있습니다. PUBG 스튜디오(PUBG Studios)’, 블루홀스튜디오(Bluehole Studio)’, 라이징윙스(RisingWings)', 스트라이킹 디스턴스 스튜디오(Striking Distance Studios)’와 여러 제작팀들이 전세계 게임플레이어들에게 최고의 게임 경험을 제공하기 위해 개발에 임하고, 배틀로얄 장르의 ‘플레이어언노운스 배틀그라운드’, MMORPG ‘테라(TERA)’와 ‘엘리온(ELYON)’ 등 게임의 규모와 장르, 플랫폼을 넘나들며 도전하고 있습니다. 보다 자세한 내용은 https://www.krafton.com에서 확인하실 수 있습니다.",
        },

        links: {
            left: { heading: '홍보 담당 및 사업부 연락처', email: 'mediarequests@krafton.com' },
            right: { heading: '보도자료 및 관련 자료', description: 'Thunder Tier One <a href="https://press.pubg.com/TTO" target="_blank" rel="noopener nofollow">홍보 자료 및 뉴스 보기</a>' },
        },
    },

    privacy: {
        heading: 'Thunder Tier One 개인정보 처리방침',
        content: {
            default: `
                <p>㈜크래프톤(이하 "회사"라 함)은 「개인정보 보호법」 등 관련 법령상의 개인정보 보호 규정을 준수합니다. 회사는 이용자의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</p>

                <h2 id="수집하는-개인정보-항목-및-수집방법">1. 수집하는 개인정보 항목 및 수집방법</h2>

                <p>회사는 “Thunder Tier One” 게임의 안정적인 서비스 제공 및 운영을 목적으로 다음의 필요한 시점에 귀하의 개인정보를 수집하고 있습니다. 아래에서 수집 및 이용목적에 필요한 최소한의 개인정보는 ‘필수 항목’, 그 외의 개인정보는 ‘선택 항목’으로 구분되며, ‘선택 항목’에 해당하는 개인정보는 동의하지 않는 경우에도 서비스 이용에 제한이 없습니다.</p>

                <table>
                    <thead>
                        <tr>
                            <th>수집 및 이용목적</th>
                            <th>필수/선택 항목 여부</th>
                            <th>수집 및 이용항목</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>게임서비스 운영</td>
                            <td>필수</td>
                            <td>SteamID</td>
                        </tr>
                        <tr>
                            <td>고객지원 서비스</td>
                            <td>필수</td>
                            <td>이메일 주소, 문의 내용</td>
                        </tr>
                        <tr>
                            <td>이벤트 등 프로모션 제공</td>
                            <td>선택</td>
                            <td>이메일 주소(또는 휴대폰 번호) (필요 시) 경품 지급을 위하여 필요한 정보</td>
                        </tr>
                    </tbody>
                </table>

                <p style="margin-top:10px">※ 게임서비스 제공 과정에서 서비스 이용 기록(방문 일시(접속기록), IP주소, 부정 이용 기록(제제 정보)) 및 디바이스 정보(OS 정보, 기종 및 모델명, 사용 언어, 광고 식별자 등), 채팅 기록,  쿠키가 생성, 수집될 수 있습니다.</p>

                <h2 id="개인정보의-처리목적">2. 개인정보의 처리목적</h2>

                <p>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 등 관계 법령에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. </p>

                <p>이용자의 게임서비스 이용 과정에서 생성된 게임데이터(게임 플레이 횟수, 킬/데스, 헤드샷 비율, 게임 이용시간 등)를 포함한 비개인정보는 서비스 이용환경 분석 및 서비스 개선, 게임 이용 경험 향상 등을 목적으로 이용될 수 있습니다.</p>

                <h3>1) 게임서비스 운영</h3>

                <ul>
                    <li>이용자 식별 및 서비스 이용 의사 확인</li>
                    <li>부정 이용 방지</li>
                    <li>서비스 이용 환경 분석 및 서비스 개선</li>
                </ul>

                <h3>2) 이벤트 등 프로모션 제공</h3>

                <ul>
                    <li>플레이 테스트, 이벤트 등 프로모션의 개최에 따른 알림, 참여 의사 확인</li>
                    <li>경품 배송, 제세공과금 처리</li>
                    <li>중복 참여 및 당첨 등 부정 이용 방지</li>
                </ul>

                <h2 id="개인정보의-제3자-제공">3. 개인정보의 제3자 제공</h2>

                <p>회사는 개인정보를 "2. 개인정보의 처리목적"에서 고지한 범위 내에서 사용하며, 원칙적으로 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>

                <h3>1) 관련 법령에 따라 개인정보 제공의 의무가 있는 경우</h3>
                <h3>2) 이용자로부터 별도의 동의를 받은 경우</h3>

                <h2 id="개인정보의-보유-기간">4. 개인정보의 보유 기간</h2>

                <p>이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기 또는 분리보관 합니다. 단, 다음의 사유에 한하여 일정기간 동안 해당 정보를 다른 정보와 분리하여 저장, 관리하며 아래에 명시된 기간 이후에 지체 없이 파기 또는 분리보관 합니다. </p>

                <h3>1) 불만 및 분쟁 해결</h3>

                <p>개인정보 삭제를 요청하는 경우, 이에 대한 지원과 이용자 불만 및 분쟁해결을 목적으로 게임서비스 운영 등을 위하여 수집한 개인정보는 요청일자로부터 15일간 보관 후 파기 또는 분리보관 합니다.</p>

                <h3>2) 부정 가입 및 이용 방지</h3>

                <p>게임서비스 이용 중 발생한 부정 이용 기록은 부정 가입 및 이용 방지를 목적으로 발생일자로부터 1년간 보관 후 파기 또는 분리보관 합니다. </p>

                <h3>3) 이벤트 개최 등 부가 서비스 제공</h3>

                <p>이벤트 개최 등을 위하여 수집한 개인정보는 이벤트의 종류 등에 따라 보관 기간이 상이할 수 있어 각 이벤트 페이지에 기재된 보관 기간에 따르며, 수집 이용 목적 달성 시 파기 또는 분리보관 합니다.</p>

                <p>이 외에 관계법령의 규정에 의하여 수집 및 이용목적 달성 후에도 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 이용자의 정보를 보관합니다.</p>

                <table>
                    <thead>
                        <td>구 분</td>
                        <td>보존 근거(법률 명)</td>
                        <td>보존 기간</td>
                    </thead>
                    <tbody>
                        <tr>
                            <td>컴퓨터 통신, 인터넷 로그 기록자료, 접속지 추적자료</td>
                            <td>통신비밀보호법</td>
                            <td>3개월</td>
                        </tr>
                        <tr>
                            <td>표시, 광고에 관한 기록</td>
                            <td rowspan="4">전자상거래 등에서의 소비자 보호에 관한 법률</td>
                            <td>6개월</td>
                        </tr>
                        <tr>
                            <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                            <td>3년</td>
                        </tr>
                        <tr>
                            <td>계약 또는 청약철회 등에 관한 기록</td>
                            <td>5년</td>
                        </tr>
                        <tr>
                            <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                            <td>5년</td>
                        </tr>
                        <tr>
                            <td>세법이 규정하는 처리에 관한 기록</td>
                            <td>국세기본법, 소득세법</td>
                            <td>5년</td>
                        </tr>
                        <tr>
                            <td>전자금융 거래에 관한 기록</td>
                            <td>전자금융거래법</td>
                            <td>5년</td>
                        </tr>
                    </tbody>
                </table>

                <h2 id="개인정보-파기절차-및-방법">5. 개인정보 파기절차 및 방법</h2>

                <p>회사는 원칙적으로 ‘4. 개인정보의 보유 기간’에 명시된 보유 기간이 경과되는 경우 지체없이 개인정보를 파기 또는 분리보관 합니다. 파기 시, 물리적 형태로 저장된 개인정보는 파쇄하거나 소각하며, 전자적 파일 형태로 저장된 개인정보는 기록을 저장할 수 없는 기술적 방법을 사용하여 삭제합니다. </p>

                <h2 id="개인정보의-처리-위탁">6. 개인정보의 처리 위탁</h2>

                <p>회사는 원활한 게임 서비스 및 고객 지원 서비스 제공 등을 위하여 다음과 같이 개인정보의 처리를 외부 전문업체에 위탁, 운영하고 있으며, 해당 수탁업체에 대하여 개인정보의 안전한 관리를 위한 사항을 규정하고 있습니다. 이 중, 일부 위탁 업무는 관련 개인정보를 국외로 이전하여 처리됩니다. 만약 개인정보 처리 위탁 내역에 대한 변경사항이 발생할 경우, 사전에 관련 내용을 안내 드리겠습니다.</p>

                <h3>1) 개인정보 처리 위탁 내역 (국외이전)</h3>

                <table>
                    <thead>
                        <th>구 분
                        (연락처)</th>
                        <th>위탁업무 내용</th>
                        <th>개인정보 항목</th>
                        <th>이전되는 국가</th>
                        <th>이전 일시 및 방법</th>
                        <th>개인정보 이용 기간</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Amazon Web Services 
                            (<a href="https://aws.amazon.com/compliance/contact" target="_blank" rel="noreferrer">https://aws.amazon.com/compliance/contact</a>)</td>
                            <td>클라우드 서버 운영 및 관리</td>
                            <td>본 개인정보 처리방침의 “수집하는 개인정보의 항목 및 수집방법”에 명시된 개인정보 항목 일체</td>
                            <td>미국</td>
                            <td>게임 서비스 이용 시 네트워크를 통해 전송</td>
                            <td>회원탈퇴 시  혹은 위탁 계약 종료 시 까지</td>
                        </tr>
                    </tbody>
                </table>

                <h3>2) 개인정보 처리 위탁에 따른 관리, 규정사항</h3>

                <ul>
                    <li>위탁업무의 목적 및 범위</li>
                    <li>위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항</li>
                    <li>개인정보에 대한 접근 제한 등 기술적·관리적 안전성 확보 조치에 관한 사항</li>
                    <li>재위탁 제한에 관한 사항</li>
                    <li>위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 회사의 감독 의무에 관한 사항</li>
                    <li>상기 조항에 대한 수탁업체의 준수 의무 위반으로 인한 손해배상 등 책임에 관한 사항 등</li>
                </ul>

                <h2 id="이용자-및-법정대리인의-권리와-그-행사방법">7. 이용자 및 법정대리인의 권리와 그 행사방법</h2>

                <p>1) 관련 법령에서 정하는 바에 따라, 이용자는 개인정보 처리를 거부, 제한하거나 개인정보를 열람, 수정, 삭제, 이전할 권리를 보유합니다. 개인정보 관련 업무에 대해 궁금하신 점이 있으실 경우 개인정보보호 담당자에게 이메일로 연락하시기 바라며, 담당자는 합리적인 방법을 통해 본인 여부를 확인할 것입니다. 회사는 개인정보 보호 관련 법령에 따라 이용자의 권리 행사에 성실히 응할 것입니다.</p>

                <p>2) 본 조 1)의 이용자의 각 권리는 이용자의 법정 대리인을 통하여 행사 하실 수 있습니다. 이 경우, 법정 대리인은 이용자의 모든 권리를 가집니다. </p>

                <p>3) 이용자의 법정대리인이 개인정보의 열람, 정정/삭제, 처리정지를 요구하는 경우, 회사는 정당한 대리권 여부를 확인하기 위하여 위임장 등의 필요한 추가적인 증빙을 요구할 수 있습니다.</p>

                <p>4) 회사는 이용자 및 법정대리인의 열람 요구에 대하여 10일 이내에 해당 개인정보를 열람할 수 있도록 합니다. 단, 해당 기간 내에 열람이 불가능한 정당한 사유가 있는 경우 이를 알리고 기한을 연기할 수 있으며, 그 사유가 소멸하면 지체없이 개인정보의 열람 등을 제공합니다.</p>
                
                <p>5) 회사는 이용자 및 법정대리인의 정정·삭제 요구에 대하여 다른 법령에서 요구하는 절차 등이 있는 경우를 제외하고 해당 개인정보에 대하여 10일 이내에 정정·삭제 등의 조치 후 그 결과를 알려드립니다. 단, 다른 법령에서 해당 개인정보가 수집 대상으로 명시되어 있는 경우와 같이 삭제가 불가능한 경우 이용자 및 법정대리인에게 통지합니다.</p>

                <p>6) 회사는 이용자 및 법정대리인의 처리정지 요구에 대하여 법률에 특별한 규정이 있거나 법적 준수 의무가 있는 경우를 제외하고 해당 개인정보에 대하여 지체없이 처리정지 등의 조치 후 그 결과를 알려드립니다.</p>

                <h2 id="개인정보의-기술적/관리적-보호-대책">8. 개인정보의 기술적/관리적 보호 대책</h2>

                <p>회사는 이용자의 개인정보가 분실, 도난, 누출유출, 변조 또는 훼손되지 않도록 다음과 같은 최선의 관리적, 기술적, 물리적 보호대책을 마련, 이행하고 있습니다. 그러나 모든 정보의 완전한 보호를 보증하기에는 어려움이 있습니다. 이용자가 회사에 개인정보를 제공한다는 것은 이러한 어려움에 대하여 인지하였으며, 이에 동의하는 것을 의미합니다.</p>

                <h3>1) 관리적 보호대책</h3>

                <ul>
                    <li>내부 관리계획의 수립·시행, 정기적 직원 교육, 수탁업체에 대한 보안 준수여부 감독 등</li>
                </ul>
                
                <h3>2) 기술적 보호대책</h3>

                <ul>
                    <li>개인정보처리시스템에 대한 접근권한 관리, 접근통제 시스템 설치·운영, 개인정보의 암호화, 보안 프로그램의 설치·운영 등</li>
                </ul>

                <h3>3) 물리적 보호대책</h3>

                <ul>
                    <li>전산실, 자료보관실 등의 접근통제 등</li>
                </ul>

                <h3>4) 개인정보처리방침 이행 및 준수</h3>

                <ul>
                    <li>회사는 개인정보처리방침 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.</li>
                    <li>단, 이용자 본인의 부주의나 인터넷상의 문제로 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.</li>
                </ul>

                <h2 id="개인정보-자동-수집-장치의-설치/운영-및-거부에-관한-사항">9. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h2>

                <p>회사는 이용자들에게 맞춤형 서비스 제공 및 서비스 이용 형태 분석 등을 목적으로 개인정보를 자동 수집하고 이용할 수 있습니다.</p>

                <h3>1) 맞춤형 서비스 제공</h3>

                <p>회사는 이용자들이 회사의 서비스를 어떻게 이용하는지 분석 및 평가하고 수요를 파악하며, 서비스와 제품을 개선하고 맞춤화 하여 효율적인 서비스를 제공하기 위하여 이용자의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키란 회사의 홈페이지를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. </p>

                <p>이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 홈페이지에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, 쿠키 설치를 거부하였을 경우 홈페이지 서비스 사용에 어려움이 있을 수 있습니다.</p>

                <ul>
                    <li>쿠키 설정 거부 방법 (Web browser)</li>

                    <table>
                        <thead>
                            <th>Internet Explorer에서 설정하기</th>
                            <th>Chrome에서 설정하기</th>
                        </thead>
                        <tbody>
                            <td>
                                <p>① [도구] 메뉴에서 [인터넷 옵션]을 선택</p>
                                <p>② [개인정보] 탭의 [고급]을 선택M</p>
                                <p>③ [쿠키를 처리하는 방법]에서 ‘차단’</p>
                            </td>
                            <td>
                                <p>① 오른쪽 상단의 더보기 메뉴의 [설정]을 선택</p>
                                <p>② 왼쪽 상단 메뉴에 [개인정보 보호 및 보안]에서 [사이트 설정]을 선택</p>
                                <p>③ [쿠키 및 사이트 데이터]를 선택 후, [사이트에서 쿠키 데이터 저장 및 읽기 허용]을 ‘사용중지’</p>
                            </td>
                        </tbody>
                    </table>

                    <li style="margin-top: 10px">쿠키 설정 거부 방법 (Mobile browser)</li>

                    <table>
                        <thead>
                            <th>Safari에서 설정하기</th>
                            <th>Chrome에서 설정하기</th>
                        </thead>
                        <tbody>
                            <td>
                                <p>① [설정] 메뉴에서 [Safari]를 선택</p>
                                <p>② [개인 정보 보호 및 보안]에서 ‘모든 쿠키 차단’ 활성화</p>
                                <p>③ [방문 기록 및 웹 사이트 데이터 지우기]를 선택 후, ‘방문 기록 및 웹 사이트 데이터 지우기’</p>
                            </td>
                            <td>
                                <p>① 왼쪽 하단의 [더보기(•••)] 메뉴의 [설정]을 선택</p>
                                <p>② [동기화 및 Google 서비스]에서 ‘검색어 및 URL 자동 완성’ 및 ‘검색 및 탐색 기능 개선]을 ‘비활성화’</p>
                            </td>
                        </tbody>
                    </table>
                </ul>

                <h3>2) 서비스 이용 형태 분석 </h3>

                <p>회사는 이용자에게 더 나은 서비스를 제공하기 위한 목적으로 Google이 제공하는 웹 분석 서비스인 Google Analytics를 사용합니다. Google Analytics는 쿠키(Cookie)를 통해 회사의 홈페이지 이용자에 대한 행태정보를 수집하며, 단, 이용자 개인을 식별할 수 없는 비식별정보만을 수집합니다. 그럼에도 불구하고 이용자는 쿠키 설정을 거부하거나, 다음의 부가기능 설치 등을 통해 Google Analytics의 쿠키 이용을 거부할 수 있습니다.</p>

                <ul>
                    <li>Google Analytics 차단 방법 (브라우저 부가기능 설치) : <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout</a></li>
                </ul>

                <h2 id="개인정보-보호책임자의-연락처">10. 개인정보 보호책임자의 연락처</h2>

                <p>회사는 개인정보에 대한 의견 및 요청을 담당하는 개인정보 보호책임자를 지정하고 있습니다.</p>
                <h4>개인정보 보호책임자</h4>
                <ul>
                    <li>이름 : 이석찬</li>
                    <li>메일 : <a href="privacy@krafton.com" target="_blank" rel="noreferrer">privacy@krafton.com</a></li>
                </ul>

                <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>

                <ul>
                    <li>개인정보침해신고센터 (<a href="privacy.kisa.or.kr" target="_blank" rel="noreferrer">privacy.kisa.or.kr</a> / (국번없이) 118 )</li>
                    <li>개인정보 분쟁조정위원회 (<a href="www.kopico.go.kr" target="_blank" rel="noreferrer">www.kopico.go.kr</a> / (국번없이) 1833-6972 )</li>
                    <li>대검찰청 사이버수사과 (<a href="www.spo.go.kr" target="_blank" rel="noreferrer">www.spo.go.kr</a> / (국번없이) 1301 )</li>
                    <li>경찰청 사이버안전국 (<a href="ecrm.cyber.go.kr" target="_blank" rel="noreferrer">ecrm.cyber.go.kr</a> / (국번없이) 182)</li>
                </ul>

                <h2 id="개인정보-처리방침의-공개와-개정">11. 개인정보 처리방침의 공개와 개정 </h2>

                <p>본 개인정보 처리방침은 웹 사이트에서 언제든지 확인하실 수 있습니다.</p>
                <p>이전의 개인정보 처리방침은 본 페이지 하단의 ‘시행일자’를 통하여 열람하실 수 있습니다.</p>

                <h4>부 칙</h4>
                <p>본 개인정보 처리방침은 2021년 12월 07일부터 시행합니다. </p
            `,
        },
    },

    terms: {
        heading: 'Thunder Tier One 공식 홈페이지 이용약관',
        content: `
            <h2>제1조 (목적) </h2>
            <p>이 약관은 ㈜크래프톤(이하 “회사”)가 온라인으로 제공하는 공식 홈페이지(“OOO”) 등 제반 서비스(이하 “서비스”)의 이용과 관련하여 회사와 이용자 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>

            <h2>제2조 (용어의 정의) </h2>
            <p>이 약관에서 사용하는 정의는 다음과 같습니다.</p>
            <ol>
                <li>1. “회사”라 함은 온라인을 통하여 서비스를 제공하는 사업자를 의미합니다.</li>
                <li>2. “이용자”라 함은 회사가 제공하는 서비스를 이용하는 자를 의미합니다.</li>
            </ol>
            <p>이 약관에서 사용하는 용어의 정의는 제1항 각호에서 정하는 것을 제외하고는 관계법령 및 기타 일반적인 상관례에 의합니다.</p>

            <h2>제3조 (회사정보 등의 제공) </h2>
            <p>회사는 다음 각 호의 사항을 홈페이지에 게시하여, 이용자가 이를 쉽게 알 수 있도록 합니다. </p>
            <ol>
                <li>1. 상호 및 대표자의 성명</li>
                <li>2. 영업소 소재지 주소(이용자의 불만을 처리할 수 있는 곳의 주소를 포함한다) </li>
                <li>3. 전화번호 및 전자우편주소</li>
                <li>4. 사업자등록번호, 통신판매업 신고번호 </li>
                <li>5. 개인정보 처리방침</li>
                <li>6. 이용약관</li>
            </ol>

            <h2>제4조 (약관의 효력 및 개정) </h2>
            <ol>
                <li>회사는 이 약관의 내용을 이용자가 알 수 있도록 홈페이지에 게시하는 방법으로 이용자에게 공지함으로써 효력이 발생합니다.</li>
                <li>회사는 이용자가 회사와 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다.</li>
                <li>회사는 이용자가 약관의 내용을 쉽게 알 수 있도록 작성하고 회사의 면책사항 및 이용자에 대한 피해보상 등과 같은 중요한 내용을 이용자가 쉽게 이해할 수 있도록 굵은 글씨 등으로 처리합니다.</li>
                <li>회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「게임산업진흥에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」, 「콘텐츠산업진흥법」 등 관련 법령에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
                <li>회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 그 적용일자로부터 최소한 7일 이전(이용자에게 불리하거나 중대한 사항의 변경은 30일 이전)부터 그 적용일자 경과 후 상당한 기간이 경과할 때까지 홈페이지의 초기화면 또는 초기화면의 팝업 등을 통해 이용자가 알기 쉽게 공지합니다.</li>
                <li>이용자는 제5항에 따라 개정된 약관을 확인할 책임이 있으며, 개정된 약관에도 불구하고 회사가 제공하는 서비스를 이용하는 경우 개정된 약관에 동의하는 것으로 간주할 수 있습니다. </li>
                <li> 이용자가 개정약관의 적용에 동의하지 않는 경우 이용자는 서비스의 이용을 중단함으로써 서비스 이용계약을 해지할 수 있습니다.</li>
            </ol>

            <h2>제5조 (약관 외 준칙) </h2>
            <p>이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법률」,「약관의 규제에 관한 법률」,「게임산업진흥에 관한 법률」,「정보통신망이용촉진 및 정보보호 등에 관한 법률」,「콘텐츠산업진흥법」 등 관련 법령에 따릅니다.</p>

            <h2>제6조 (개인정보의 보호 및 관리) </h2>
            <ol>
                <li>회사는 관계 법령이 정하는 바에 따라 이메일 주소를 포함한 이용자의 개인정보를 보호하기 위해 노력합니다. 이용자 개인정보의 보호 및 사용에 대해서는 관계법령 및 회사가 별도로 고지하는 개인정보 처리방침이 적용됩니다.</li>
                <li>서비스의 일부로 제공되는 개별 서비스를 제외한 것으로서 홈페이지 및 서비스별 웹사이트에서 단순히 링크된 제3자 제공의 서비스에 대하여는 회사의 개인정보 처리방침이 적용되지 않습니다.</li>
                <li>회사는 이용자의 귀책사유로 인하여 노출된 이용자의 이메일 주소를 포함한 모든 정보에 대해서 일체의 책임을 지지 않습니다.</li>
            </ol>

            <h2>제7조 (회사의 의무) </h2>
            <ol>
                <li>회사는 관련 법령을 준수하고, 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 따라 성실하게 합니다.</li>
                <li>회사는 이용자가 안전하게 서비스를 이용할 수 있도록 개인정보 보호를 위해 보안시스템을 갖추어야 하며 개인정보 처리방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보 처리방침에서 정한 경우를 제외하고는 이용자의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다. </li>
                <li>회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 결함 및 장애 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.</li>
            </ol>

            <h2>제8조 (이용자의 의무) </h2>
            <p>이용자는 다음 행위를 하여서는 안 됩니다.</p>
            <ol>
                <li>1. 타인의 정보도용 </li>
                <li>2. 회사의 임직원, 운영자, 기타 관계자를 사칭하는 행위</li>
                <li>3. 회사가 게시한 정보의 변경</li>
                <li>4. 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해 </li>
                <li>5. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 </li>
                <li>6. 기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지 않는 행위</li>
            </ol>
            <p>이용자는 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 확인하고 준수할 의무가 있습니다.</p>

            <h2>제9조 (서비스의 제공 및 중단 등)</h2>
            <ol>
                <li>서비스의 이용시간은 연중무휴, 1일 24시간으로 함을 원칙으로 합니다.</li>
                <li>제1항에도 불구하고, 다음 각 호의 어느 하나에 해당하는 경우에는 일정한 시간동안 서비스가 제공되지 아니할 수 있으며, 해당 시간 동안 회사는 서비스를 제공할 의무가 없습니다.</li>
                <li>
                    <ol>
                        <li>1. 컴퓨터 등 정보통신설비의 보수점검, 교체, 정기점검을 위하여 필요한 경우</li>
                        <li>2. 해킹 등의 전자적 침해사고, 통신사고, 이용자들의 비정상적인 서비스 이용행태, 미처 예상하지 못한 서비스의 불안정성에 대응하기 위하여 필요한 경우</li>
                        <li>3. 관련 법령에서 특정 시간 또는 방법으로 서비스 제공을 금지하는 경우</li>
                        <li>4. 천재지변, 비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우</li>
                        <li>5. 회사의 분할, 합병, 영업양도, 영업의 폐지 등 회사의 경영상 중대한 필요에 의한 경우</li>
                    </ol>
                </li>
                <li>회사는 회사가 제공하는 무료서비스 이용과 관련하여 이용자에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.</li>
            </ol>

            <h2>제10조 (저작권 등의 귀속)</h2>
            <ol>
                <li>서비스 내 회사가 제작한 콘텐츠에 대한 저작권 기타 지적재산권은 회사의 소유입니다.</li>
                <li>이용자는 회사가 제공하는 서비스를 이용함으로써 얻은 정보 중 회사 또는 제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.</li>
            </ol>

            <h2>제11조 (손해배상)</h2>
            <ol>
                <li> 회사가 고의 또는 중과실로 이용자에게 손해를 끼친 경우, 손해에 대하여 배상할 책임이 있습니다.</li>
                <li> 이용자가 본 약관을 위반하여 회사에 손해를 끼친 경우, 이용자는 회사에 대하여 그 손해에 대하여 배상할 책임이 있습니다.</li>
            </ol>

            <h2>제12조 (회사의 면책) </h2>
            <ol>
                <li>회사는 전시, 사변, 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 기술적 결함 기타 불가항력적 사유로 서비스를 제공할 수 없는 경우에는 책임이 면제됩니다.</li>
                <li>회사는 이용자의 귀책사유로 인한 서비스의 중지, 이용장애 및 계약해지에 대하여 책임이 면제됩니다.</li>
                <li>회사는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 이용자에게 손해가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
                <li>회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
                <li>회사는 이용자의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는 회사의 고의 또는 중대한 과실이 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서 책임이 면제됩니다.</li>
                <li>회사는 이용자 또는 제3자가 서비스 내 또는 웹사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
                <li>회사는 이용자 상호간 또는 이용자와 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.</li>
                <li>회사가 제공하는 서비스 중 무료서비스의 경우에는 회사의 고의 또는 중대한 과실이 없는 한 회사는 손해배상을 하지 않습니다.</li>
                <li>본 서비스 중 일부의 서비스는 다른 사업자가 제공하는 서비스를 통하여 제공될 수 있으며, 회사는 다른 사업자가 제공하는 서비스로 인하여 발생한 손해 등에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
                <li>회사는 이용자의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전자우편주소를 부정확하게 기재하거나 미기재하여 손해가 발생한 경우에 대하여 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
                <li>회사는 관련 법령, 정부 정책 등에 따라 서비스를 제한할 수 있으며, 이러한 제한사항 및 제한에 따라 발생하는 서비스 이용 관련 제반사항에 대해서는 책임이 면제됩니다.</li>
            </ol>

            <h2>제13조 (이용자의 고충처리 및 분쟁해결)</h2>
            <ol>
                <li>회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 이용자에게 장기간이 소요되는 사유와 처리일정을 서비스 홈페이지에 공지하거나 전자우편, 전화 또는 서면 등으로 통보합니다.</li>
                <li>  회사와 이용자간에 분쟁이 발생하여 제3의 분쟁조정기관이 조정할 경우 회사는 이용제한 등 이용자에게 조치한 사항을 성실히 증명하고, 조정기관의 조정에 따를 수 있습니다.</li>
            </ol>

            <h2>제14조 (이용자에 대한 통지)</h2>
            <p>회사는 이용자 전체에게 통지를 하는 경우 7일 이상 회사의 홈페이지의 초기화면에 게시하거나 팝업화면 등을 제시함으로써 제1항의 통지에 갈음할 수 있습니다.</p>

            <h2>제15조 (이용계약의 해지)</h2>
            <ol>
                <li>본 약관에 따른 이용계약은 이용자와 회사 모두 사전 고지 없이 일방적으로 해지할 수 있습니다. 회사는 언제든지 서비스를 종료함으로써 이용계약을 해지할 수 있으며, 이용자는 서비스의 이용을 중단함으로써 이용계약을 해지할 수 있습니다. </li>
                <li>제1항에 따라 이용계약이 해지되더라도 제10조 내지 제13조 및 제15조, 재16조는 효력이 존속됩니다.</li>
            </ol>

            <h2>제16조 (재판권 및 준거법)</h2>
            <p>본 약관은 대한민국 법률에 따라 규율되고 해석되며, 회사와 이용자간에 발생한 분쟁으로 소송이 제기되는 경우, 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.</p>

            <p>부칙</p>
            <p>본 이용약관은 2020년 12월 XX일부터 시행합니다.</p>
        `,
    },

    header: {
        links: [
            { to: 'press', label: '홍보 자료' },
        ],
    },

    menu: [
        { to: '', label: 'Thunder Tier One' },
        { to: 'press', label: '홍보 자료' },
        { to: 'privacy', label: '개인정보 보호' },
    ],
    
    footer: {
    links: [
            { to: 'press', label: '홍보 자료' },
            { to: 'privacy', label: '개인정보 보호' },
            { to: 'terms', label: '이용 약관' },
        ],
        icon: {
            type: 'gcbr',
            src: '/gcbr-15.svg',
            alt: 'GCBR15',
        },
        termsMessage: 'KRAFTON, Inc. All Rights Reserved',
    },

    languageToggle: {
        questionText: '해당 <strong class="c-blonde">{LANG}</strong> 로 변경하시겠습니까?',
        yesLabel: '네',
        noLabel: '아니오',
    },

    error: {
        seo: {
            title: '찾으시는 페이지가',
            description: 'Thunder Tier One is a realistic top-down shooter where the player will join an elite task force fighting enemy operatives. Players must utilize all their tactical skills in real time to neutralize enemy combatants and achieve mission objectives.',
        },
        heading: '없는 듯하네요.',
    },
}
