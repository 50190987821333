import React from 'react'
import loadable from '@loadable/component'
import htmlParser from 'react-html-parser'
import cx from 'classnames'
import is from 'react-jss'

import translations from '../../../config/translations'
import { isServer, ReactLazyPreload } from '../../../client/helpers'

import Meta from '../../components/Meta'
import Header from '../../components/Header'
import Icon from '../../components/Icon'
import Footer from '../../components/Footer'

import style from './style'

const BlackHero = isServer() ? loadable(() => import('../../components/BlackHero'), { ssr: true }) : ReactLazyPreload(() => import('../../components/BlackHero'))

const Press = ({
    classes,
    content: {
        slug: locale,
        ISO,
        socialLinks,
        menu,
        header,
        press: {
            seo,
            hero,
            about,
            krafton,
            links,
        },
        footer,
    },
}) => {
    const hreflang = translations.map((translation, i) => ({
        rel: ISO !== translation.ISO ? 'alternate' : '',
        href: `/${translation.slug}/press`,
        hrefLang: translation.ISO,
    }))

    return (
        <div className={classes.press}>
            <Meta { ...{ seo, hreflang, ISO } } />
            <Header { ...{ ISO, locale, header, links: menu } } />
            <BlackHero { ...{ locale, heading: hero.heading, description: hero.description, button: hero.button } } />

            <div className={classes.about}>
                <div className="container wide">
                    <div className="row">
                        <div className="col-mi-12 col-md-8">
                            <h2 className={classes.heading}>{ htmlParser(about.heading) }</h2>
                            <div className={classes.description}>
                                <p>{ about.description }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.krafton}>
                <div className="container">
                    <div className="row">
                        <div className="col-mi-12 col-md-offset-4 col-md-8">
                            <Icon name="krafton" color="white" size="custom" className={classes.kraftonLogo} />
                            <p>{ krafton.description }</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.links}>
                <div className="container wide">
                    <div className="row">
                        <div className={ cx('col-mi-12 col-md-6', classes.linksCol) }>
                            <h2 className={ cx(classes.heading, 'yellow') }>{ htmlParser(links.left.heading) }</h2>
                            <a href={ `mailto:${links.left.email}` } className={classes.linkDescription}>{ links.left.email }</a>
                        </div>

                        <div className={ cx('col-mi-12 col-md-6', classes.linksCol) }>
                            <h2 className={ cx(classes.heading, 'yellow') }>{ htmlParser(links.right.heading) }</h2>
                            <p className={classes.linkDescription}>{ htmlParser(links.right.description) }</p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer { ...{ locale, socialLinks, ...footer } } />
        </div>
    )
}

export default is(style)(Press)
