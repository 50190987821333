import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import Icon from '../../components/Icon'
import ExternalLink from '../../components/Link'

import style from './style'

const Footer = ({
    classes,
    locale,
    icon,
    socialLinks,
    links,
    termsMessage,
}) => {
    const date = new Date()
    const year = date.getFullYear()

    const handleSocialClick = (label) => {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: 'Social',
                action: 'Click',
                label,
            },
        })
    }

    return (
        <div className={classes.footer}>
            <div className={classes.top}>
                <div className={classes.left}>
                    <Link to={ `/${locale}` }>
                        <img src="/logo.png" className={classes.logo} alt="Thunder Tier One" />
                    </Link>
                </div>
                <div className={classes.right}>
                    <div className={classes.logos}>
                        <img src={icon.src} alt={icon.alt} className={ cx(classes.pegi16, icon.type) } />
                        <ExternalLink to="https://krafton.com" external={true}>
                            <Icon size="custom" name="krafton" color="white" className={classes.krafton} />
                        </ExternalLink>
                    </div>
                    <div className={classes.socialLinks}>
                        { socialLinks?.filter(({ type }) => type !== 'Instagram')?.map(({ type, to }, i) => (
                            <ExternalLink
                                { ...{
                                    key: i,
                                    to,
                                    external: true,
                                    className: classes.socialLink,
                                    clickHandler: () => handleSocialClick(type),
                                } }
                            ><Icon name={ type.toLowerCase() } /></ExternalLink>
                        )) }
                    </div>
                </div>
            </div>
            <div className={classes.bottom}>
                <div className={classes.left}>
                    <div className={classes.links}>
                        { links.map(({ to, label }, i) => (
                            <Link
                                { ...{
                                    key: i,
                                    to: `/${locale}/${to}`,
                                    className: classes.link,
                                } }
                            >{ label }</Link>
                        )) }
                    </div>
                </div>

                <div className={classes.right}>&copy;{ year } { htmlParser(termsMessage) }</div>
            </div>
        </div>
    )
}

export default is(style)(Footer)
