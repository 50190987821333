import variables from './variables'

const {
    colors,
    fontstacks,
    typography,
} = variables

export default {
    '*, *::before, *::after': {
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: 'currentColor',
        outline: 'none',
    },

    html: {
        ...typography.md,
        ...fontstacks.primary,
        fontWeight: 400,
        color: colors.midnight,
    },

    hr: {
        borderTopWidth: 1,
    },

    img: {
        borderStyle: 'solid',
    },

    textarea: {
        resize: 'vertical',
    },

    'input::-webkit-input-placeholder, textarea::-webkit-input-placeholder': {
        color: '#a0aec0',
    },

    'input::-moz-placeholder, textarea::-moz-placeholder': {
        color: '#a0aec0',
    },

    'input::-ms-input-placeholder, textarea::-ms-input-placeholder': {
        color: '#a0aec0',
    },

    'input::placeholder, textarea::placeholder': {
        color: '#a0aec0',
    },

    'button, [role="button"]': {
        cursor: 'pointer',

        '&:focus, &:active': {
            outline: 0,
        },
    },

    table: {
        borderCollapse: 'collapse',
    },

    'h1, h2, h3, h4, h5, h6': {
        fontSize: 'inherit',
        fontWeight: 'inherit',
    },

    a: {
        color: 'inherit',
        textDecoration: 'inherit',
    },

    'button, input, optgroup, select, textarea': {
        padding: 0,
        lineHeight: 'inherit',
        color: 'inherit',
    },

    'pre, code, kbd, samp': {
        fontFamily: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    },

    'img, svg, video, canvas, audio, iframe, embed, object': {
        display: 'block',
        verticalAlign: 'middle',
    },

    'img, video': {
        maxWidth: '100%',
        height: 'auto',
    },

    p: {
        ...typography.md,
        lineHeight: '30px',
        color: colors.white,
    },
}
