import React from 'react'

import { BrowserProvider, useBrowserState } from './browser'
import { MenuProvider, useMenuState } from './menu'

const ContextProvider = ({
	children,
}) => (
	<BrowserProvider>
		<MenuProvider>
			{ children }
		</MenuProvider>
	</BrowserProvider>
)

export {
	ContextProvider,
	useBrowserState,
	useMenuState,
}
