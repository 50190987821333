export default {
    name: '繁體中文',
    label: '繁體中文',
    slug: 'zh-tw',
    ISO: 'zh_TW',

    socialLinks: [
        { type: 'Discord', to: 'https://discord.gg/thundertierone' },
        { type: 'Facebook', to: 'https://www.facebook.com/ThunderTierOne/' },
        { type: 'Twitter', to: 'https://twitter.com/thundertierone?lang=en' },
        { type: 'Instagram', to: 'https://www.instagram.com/thundertierone/' },
    ],

    home: {
        seo: {
            title: 'Thunder Tier One',
            description: 'Thunder Tier One is a realistic top-down shooter where the player will join an elite task force fighting enemy operatives. Players must utilize all their tactical skills in real time to neutralize enemy combatants and achieve mission objectives.',
        },

        hero: {
            discordLabel: '在 Discord 上加入',
            subscribeLabel: '訂閱',
        },

        newsletter: {
            heading: {
                default: '訂閱我們的電子報',
                success: '做得好，特工。',
            },
            inputPlaceholder: '電子郵件',
            buttonText: '提交',
            checkboxLabel: '我同意 KRAFTON 發送遊戲新聞及活動消息至電子信箱。(選項)。',
            readMore: {
                label: '了解更多',
                text: '<strong>接收信件</strong> 如您同意以上條款並接受 KRAFTON 團隊發送的最新遊戲內容及活動消息，KRAFTON 團隊將記錄並蒐集您所填寫的資料；若日後不想繼續看到 KRAFTON 寄發的遊戲相關消息，將可隨時進行取消訂閱，但將可能無法獲得 KRAFTON 最新遊戲消息。',
                link: '了解更多',
            },
            messages: {
                success: '您已成功訂閱。',
                error: '衛星上行鏈路發生錯誤，請再試一次',
                existing: 'You are already signed up!',
                missing: '請確認後再試一次',
            },
        },
    },

    press: {
        seo: {
            title: '關於《Thunder Tier One》 ',
            description: '《Thunder Tier One》是一款充滿現實感的俯視視覺射擊遊戲，玩家在遊戲中將加入一支菁英特遣隊，對抗遍布歐洲各地的敵方要員。為了消滅敵方戰鬥隊，玩家必須要實時利用所有的戰術戰略並達成任務目標。',
        },

        hero: {
            heading: '關於《Thunder Tier One》 ',
            description: "《Thunder Tier One》是一款充滿現實感的俯視視覺射擊遊戲，玩家在遊戲中將加入一支菁英特遣隊，對抗遍布歐洲各地的敵方要員。為了消滅敵方戰鬥隊，玩家必須要實時利用所有的戰術戰略並達成任務目標。",
            button: { to: '', label: 'thundertierone.com' },
        },

        about: {
            heading: '關於開發團隊',
            description: '《Thunder Tier One》原是 PUBG 動作與槍戰部門 (Action and Gunplay Unit) 負責人 Pawel Smolewski 2016 年任職於 Bohemia Interactive 期間所發想出的一項個人計畫。他與同事 Nelson Duarte 和 Arseniy Smirnov 分享這個構想後，三人便開始一起開發這款遊戲。然而，開發工作在一年後便因團隊有其他要務而暫停。團隊於 2020 年再次著手開發並向 Krafton 提出這個專案構想後，便招募了 Pedro Pinho、Jorge Aguiar、Lorenzo Salvadori、Ricardo Mota、Ricardo Sousa、Joao Rebelo 以及 Michał Flodrowski 來共同打造這款遊戲。',
        },

        krafton: {
            description: 'KRAFTON, Inc. 旗下擁有多支獨立的遊戲開發團隊，負責各式各樣的娛樂作品，包含《絕地求生》、《ELYON》及《TERA》。公司名稱「KRAFTON」源自英文單字「Craftsmanship (工藝)」，這是呼應公司致力追求創新和創造絕佳遊戲體驗的精神。KRAFTON 目前旗下擁有 PUBG Studio、Bluehole Studio、RisingWings Studio 和 Striking Distance Studios 等工作室。欲知更多有關 KRAFTON, Inc. 的詳細資訊，請前往 https://www.krafton.com 了解。',
        },

        links: {
            left: { heading: '媒體 / 業務聯繫', email: 'mediarequests@krafton.com' },
            right: { heading: '新聞和資源', description: '閱讀《Thunder Tier One》<a href="https://press.pubg.com/TTO" target="_blank" rel="noopener nofollow">的媒體資料與新聞</a>' },
        },
    },

    privacy: {
        heading: '雷霆一號 (Thunder Tier One) 隱私權政策',
        content: {
            default: `
                <p>生效日期：2021 年 12 月 7 日</p>

                <h2 id="概述">1.概述</h2>
                <p>KRAFTON, Inc. 及/或其關係企業 (以下稱「KRAFTON」、「我們」或「本公司」) 蒐集並處理有關您個人 (以下稱「您」、「使用者」或「玩家」) 的資訊。我們向玩家提供電玩遊戲 (以下稱「本遊戲」)、官方網站 (以下稱「本網站」) 及本遊戲相關服務 (例如活動、問卷調查或客戶服務) (以下稱「本服務」)。本隱私權政策 (以下稱「本政策」) 說明在您與我們建立關係之期間及其後，根據適用的資料保護法律，我們如何蒐集、使用與分享您的個人資訊。
                請您務必詳閱本政策，以及我們在蒐集或處理您個人資訊之特定情況下所提供的其他通知，以便瞭解我們使用該個人資訊的方式與目的，以及您所享有的權利。</p>

                <h2 id="我們蒐集的資訊">2.我們蒐集的資訊</h2>
                <p>我們蒐集有關您及您使用本服務時的資訊。與您建立合約關係或向您履行合約義務 (例如依您的要求提供本服務) 可能需要此類資訊，若您未提供任一資訊，可能導致我們無法提供所需服務。我們蒐集的資訊分為三種：</p>

                <h3>1) 直接向您蒐集的資訊</h3>
                <p>您可能需向我們提供下列資訊，方能玩本遊戲及/或使用本服務。所需提供的資訊可能視情況而有所不同。資訊包括：</p>

                <ul>
                    <li>Steam ID (用於提供或營運本遊戲)；以及</li>
                    <li>電子郵件地址、居住國家以及交付商品所需資訊 (亦即姓名、郵寄地址與電話號碼) (用於參加測試、問卷調查及其他活動)。</li>
                </ul>

                <p>當您使用社交功能時 (例如：遊戲內聊天)，您所揭露的資訊屬於公開資訊。我們可能儲存並存取此類資訊，且其他使用者得未經您的同意而閱讀、複製、蒐集或使用該資訊。</p>

                <h3>2) 自動蒐集的資訊</h3>
                <p>當您玩本遊戲及/或使用本服務時，我們將自動蒐集某些資訊。我們自動蒐集的資訊類型不盡相同，但通常包括：</p>

                <ul>
                    <li>裝置資訊 (包括但不限於 IP 位址、裝置 ID、裝置型號、網路類型、作業系統版本、時區設定、電池狀態、螢幕解析度等)；</li>
                    <li>裝置的事件資訊，包括當機報告與系統活動詳細資訊 (例如使用我們的服務時是否發生錯誤、或無法連線等)；以及</li>
                    <li>您使用本網站及/或本服務時的瀏覽活動。</li>
                </ul>

                <h3>3) 向第三方蒐集的資訊</h3>
                <p>當您透過第三方平台存取本遊戲及/或本服務時，我們可能根據第三方平台條款與您的隱私權相關設定允許之範圍，接收您在第三方平台的個人資料/帳戶中的某些資訊。例如 Steam ID 等。</p>

                
                <h2 id="Cookie-與相關技術">3.Cookie 與相關技術</h2>
                <p>我們使用 Cookie 及其他技術來辨識您的瀏覽器或裝置，進一步瞭解您的興趣，並且為您提供基本功能與服務及供其他目的之用。經授權第三方也可能在您使用我們的網站或遊戲時，設定 Cookie 及相關技術。</p>

                <h3>1) Cookie</h3>

                <p>Cookie 是下載至您電腦的小型檔案 (若您的瀏覽器同意使用 Cookie)，可用以追蹤網站上的行動軌跡，並為您提供網站的個人化體驗。大多數網路瀏覽器允許您清除電腦硬碟中的 Cookie、拒絕接受 Cookie 或在儲存 Cookie 之前發出警告。您應參考瀏覽器的說明資訊或「說明」畫面，進一步瞭解如何管理 Cookie。但請注意，若您封鎖 Cookie，可能導致本網站的某些部分無法正常運作。</p>
                <p>我們並未控管第三方廣告中的 Cookie，建議您查看廣告商及/或廣告服務的隱私權政策，以瞭解他們如何使用 Cookie 與其他技術。本網站上出現的廣告可能是由第三方廣告公司所投放。這些公司可能使用有關您造訪本網站及其他網站的資訊，以便在本網站及其他網站上提供您可能感興趣的商品與服務廣告。</p>

                <h3>2) 網站分析</h3>
                <p>我們在本網站使用分析服務，以協助我們記錄本網站的使用資訊，並且持續改善我們提供的產品。</p>
                <p>本網站使用 Google® Analytics，這是由 Google Inc. (以下稱「Google」) 提供的網路分析服務。我們可能利用後述之 Google® Analytics 的廣告功能，並蒐集由 Google 提供的個人資訊，您可以在 Google 廣告設定中停用此類功能，以撤回您的同意：使用 Google® Analytics 進行再行銷；Google® Display Network Exposure Report；Google® Analytics 客層與興趣報告；以及透過 Google® Analytics 且使用廣告 Cookie 與匿名 ID 蒐集資料的整合服務。</p>

                <p>如欲進一步瞭解 Google 的隱私權政策資訊，請造訪 <a href="https://www.google.com/policies/privacy" target="_blank" rel="noreferrer">https://www.google.com/policies/privacy。</a></p>
                <p>若您不願意讓 Google 繼續使用您的資訊或資料，您可以至後述網站下載 Google Analytics 不透露資訊瀏覽器外掛程式：<a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout</a></p>

                <h3>4) 反制作弊及防範詐欺政策</h3>
                <p>我們努力為所有玩家提供安全且公平的遊戲環境。當您玩本遊戲時，我們或第三方可能使用 Cookie 與類似技術，或蒐集您的機器或裝置之相關資料，以供防範詐欺、安全與身分驗證之用。我們也可能在服務上使用其他反制作弊技術。</p>

                <h2 id="我們如何使用您的資訊">4.我們如何使用您的資訊</h2>
                <p>我們蒐集所得之資訊將用於各項目的，但主要是向玩家 (例如您) 提供本遊戲與本服務 (合稱「本遊戲服務」)，並且改善本遊戲服務品質。包括：</p>

                <ul>
                    <li>營運並提供本遊戲服務</li>
                    <li>提供參加本遊戲服務相關活動或問卷調查的機會；</li>
                    <li>解決技術問題並改善本遊戲服務品質 (例如向使用者提供逼真的遊戲環境)；</li>
                    <li>識別個人玩家，以便提供本遊戲服務；</li>
                    <li>防止使用未經授權程式的技術保護；</li>
                    <li>避免不適當的遊玩方式，以保護其他玩家權益</li>
                    <li>預防、偵測、識別、調查並回應潛在或實際的索賠、責任、禁止行為與犯罪活動。</li>
                </ul>

                <p>在大多數情況下，我們處理您的個人資訊是基於回應或處理您的請求所必要。我們也可能為遵循相關法律義務而處理您的資訊。我們可能基於某些原因，不定時要求您同意我們使用您的資訊。您可以隨時按本政策提供的地址，與我們聯絡並撤回同意，但該撤回並不影響先前基於同意所為處理的合法性。</p>

                <h2 id="分享您的資訊">5.分享您的資訊</h2>
                <p>我們允許第三方 (包括代為處理個人資訊的服務供應商) 提供客戶支援並管理本公司社群。前述第三方包括：</p>

                <ul>
                    <li>封鎖作弊工具與未經授權程式的第三方程式限制供應商；</li>
                    <li>提供雲端儲存服務的雲端運算服務供應商；</li>
                    <li>線上問卷調查服務供應商，以便改善本遊戲。</li>
                </ul>

                <p>我們也可能在後述情況分享您的資訊：此類揭露是為遵守法律義務所必要，或我們的關係企業基於本政策所述目的且根據合併或收購所提出之合法資料存取請求，或者您以其他方式表示同意或按照您的指示。</p>

                <h2 id="國際資料傳輸">6.國際資料傳輸</h2>
                <p>我們的業務範圍遍及全球，可能將您的資訊傳輸至您所在地以外之國家與其他地區。一旦跨境傳輸、儲存或處理您的個人資訊，我們將採取合理措施保護個人資訊隱私。針對特定國家，我們會處理某些地區的資料 (詳述如後)。</p>

                <h2 id="保存及刪除個人資訊">7.保存及刪除個人資訊</h2>
                <p>原則上，我們在達成資訊蒐集之目的後，將立即移除您的個人資訊。若是實體資訊，將以切碎或焚化方式處理；若是電子資訊，則利用技術措施以無法重製該資訊之方式移除。然而，即使您已關閉在本公司的帳戶或我們已停止向您提供本服務，我們仍可能在合理必要之範圍內保存您的個人資訊，以遵守法律義務、符合法規要求、解決爭議、防止詐欺、矇騙或濫用行為，或執行本政策或我們與玩家訂立之其他協議。</p>

                <h2 id="我們的兒少政策">8.我們的兒少政策</h2>
                <p>除取得附證明之父母同意書或法律允許之情形外，我們不會刻意蒐集、使用或分享有關兒少 (在美國通常指未滿 13 歲，或依您居住地國家法律規定) 的資訊。若您是父母或監護人，且確信子女或受監護人曾向我們提供資訊，您可以聯絡我們，並從本公司系統中移除此類資訊。</p>

                <h2 id="連結與第三方服務">9.連結與第三方服務</h2>
                <p>若您點選連往第三方網站的連結 (包括但不限於廣告)，您將離開本網站並連結至所點選的網站。由於我們無法控制第三方的活動，因此，我們對該第三方使用您的個人資訊概不承擔任何責任，也不保證其將遵循與本公司相同的隱私權及資訊安全實務作法。若您有意使用其他服務供應商的服務，我們建議您查看其隱私權政策。當您造訪在本網站設有連結的第三方網站時，應先瞭解該網站的隱私權政策，再提供個人資訊。</p>

                <h2 id="您的資訊安全">10.您的資訊安全</h2>
                <p>我們已採取適當的安全措施 (包括加密與去識別化)，以防止您的個人資訊遭到意外洩露、未經授權使用或存取、篡改或揭露。此外，我們僅限基於業務需要必須知悉的員工、代理商等，方能存取您的個人資訊。此等對象僅能按照我們的指示來處理您的個人資訊，並且負有保密義務。我們已制定安全程序來處理任何可疑的資料安全漏洞，並且在法律要求之情況下，向您及適用的主管機關通知該可疑漏洞。然而，縱有前述安全措施及程序，仍可能發生各項問題 (例如因您的過失或網路問題)，我們對此概不負責。請注意，網路上的資料傳輸均非絕對安全，因此，無法保證我們蒐集與使用的任何資訊安全無虞。</p>

                <h2 id="您的隱私權">11.您的隱私權</h2>
                <p>在適用法律允許之範圍內，您有權反對或限制我們處理您的個人資訊，且亦有權存取、更改及刪去您的個人資訊，並得主張資料可攜權。若您需提出相關權利之請求或有其他疑問，請發送電子郵件至「13.聯絡我們」一節所載地址，與我們聯繫。我們將根據適用的資料保護法律，向有意行使資料保護權利之個人回覆其所提出的各項請求。</p>

                <h2 id="修訂">12.修訂</h2>
                <p>我們可能隨時酌情修訂本政策之任何部分，並在本網站中發布或公告修訂後的本政策。若您繼續使用本服務，將視為已接受此類修訂，因此建議您經常查看本政策。</p>

                <h2 id="聯絡我們">13.聯絡我們</h2>
                <p>若您對本政策或在本網站互動時有任何疑問，歡迎利用下列溝通管道聯絡我們。</p>
                <ul>
                    <li>電子郵件請發送至：<a href="mailto:privacy@krafton.com" target="_blank">privacy@krafton.com</a></li>
                    <li>地址：Centerfield East Tower, 231, Teheran-ro, Gangnam-gu, Seoul, Republic of Korea</li>
                </ul>

                <p>本隱私權政策已翻譯成多種語言版本，以便參考。如英文版本與其他版本之間有不一致之處，則以英文版本為準。但韓國玩家仍以隱私權政策之韓文版本為準。</p>

                <h2 id="特定司法管轄區補充條款">14.特定司法管轄區補充條款</h2>

                <p>您玩本遊戲或使用本服務時所在司法管轄區之相關特定司法管轄區補充條款，如與本政策之其餘部分有所牴觸，將由相關司法管轄區之特定司法管轄區補充條款取代及管控。</p>

                <h3>美國</h3>
                <h4>加州</h4>
                <p>若您是美國加州居民，則可能享有其他權利。如欲進一步瞭解您的隱私權相關資訊，請查閱本公司的 <a href="/en/privacy/california">《加州居民隱私權聲明》。</a></p>

                <h4>內華達州</h4>
                <p>若您是美國內華達州居民，我們不會出售我們所蒐集之您的個人資訊；但是，若您是向我們購買遊戲物品或商品的內華達州居民，您可以提交紀錄偏好的請求，以在日後選擇退出。</p>

                <h3>EEA、瑞士和英國</h3>
                <p>若您是 EEA、瑞士和英國居民，則可能享有其他權利。</p>

                <h4>國際資料傳輸</h4>
                <p>當您的資料傳輸至 EEA 以外之其他國家，且該國家未經歐盟執委會認可具適足的資料保護水準，我們將根據適用的資料保護及隱私權法律，採取適當措施以保障您的個人資料。這些保護措施包括採用標準契約條款 (指歐盟執委會為確保適足的個人資料保護措施而預先核准之資料傳輸協議內容) 的資料傳輸協議。您可以按本政策提供的方式聯絡我們，索取前述保護措施的副本。此外，我們得基於後述理由傳輸個人資料：經您的同意；為履行與您訂立之合約；或以不損害您權利與自由的方式實現有合理憑據之合法利益。若您對本政策有任何疑問，請聯絡本公司的資料保護主管 (<a href="mailto:DPO@pubg.com" target="_blank" rel="noreferrer">DPO@pubg.com</a>)。</p>
            `,
        },
    },

    terms: {
        heading: 'Thunder Tier One官方網站服務條款',
        content: `
            <p>生效日期：2020年12月10日</p>

            <p>KRAFTON, Inc.（以下簡稱「KRAFTON」或「我們」）透過其官方網站（OOO）提供遊戲相關服務（以下統稱「服務」）。您與KRAFTON之間有關服務使用方面的關係須受本服務條款（以下簡稱「條款」）約束，其中的「使用者」和「您」是指同意遵守本條款的當事人。使用服務後即代表您同意遵守本條款並受其約束，如果您不願同意接受本條款，請勿使用服務。 </p>

            <h2>1.服務和條款更新</h2>
            <ol>
                <li>1) 您可以隨時在KRAFTON網站上找到本服務條款。</li>
                <li>2) 必要時，KRAFTON可能會在符合適用法律和法規的範圍內，隨時更動本條款。</li>
                <li>3) 您有責任定期查閱本網頁並記錄任何更動。繼續使用服務即代表您接受並同意本條款的更動內容。</li>
            </ol>

            <h2>2.禁止使用</h2>
            <p>KRAFTON提供給使用者的服務僅限於個人、非商業和娛樂用途。您同意不違反本條款中載述的條款和條件。若是違反本協議中的任何準則，依據本條款規定可能會暫時或永久中止您使用服務。您同意依照本條款規範的方式使用服務或其任何部分，且您不得：</p>
            <ul>
                <li>(a) 在未經KRAFTON的書面許可，以任何方式使用、宣傳或利用服務或其任何部分以遂行商業目的，其中包括但不限於網路咖啡廳（網咖）、電腦遊戲中心或任何其他適地性場所；</li>
                <li>(b) 對整體或部分服務進行反向工程、導出原始碼、修改、反編譯、拆解或創作其衍生作品（不包括適用法律明確許可的作為，在此情況下，所有合法修改、改編、改良等內容於創作之際，包含其所有版權和人格權，一律須視為轉讓給並屬於和授予KRAFTON和／或其授權人，並且為其專屬財產）；</li>
                <li>(c) 移除、停用或規避控管服務存取的任何安全防護機制或技術措施；</li>
                <li>(d) 移除、修改、破壞或規避存在於服務內或其中包含的任何所有權聲明或標籤；</li>
                <li>(e) 竊取和／或公布他人資訊（包括但不限於個人資訊、非個人資訊和付款資訊）；</li>
                <li>(f) 冒充KRAFTON或我們的員工；</li>
                <li>(g) 損害KRAFTON、我們的附屬公司、服務提供者及授權人的名譽；</li>
                <li>(h) 張貼、連結到、宣傳、鼓吹或發送任何非法、有害、威脅、辱罵、騷擾、侵權、誹謗、粗鄙、色情、淫穢、仇恨，抑或是種族歧視或民族偏見的資訊；</li>
                <li>(i) 以任何剝削他人的方式佔便宜；</li>
                <li>(j) 以非法或妨礙服務提供的方式使用服務；</li>
                <li>(k) 為了非法或超出服務之預期使用範圍（由公司自行決定）的任何目的而存取或使用服務；</li>
                <li>(l) 不尊重他人或用言論攻擊他人，包括貶抑種族、性別和國籍方面的言論；</li>
            </ul>
            <p>本條款中未定義，但是會妨礙KRAFTON提供服務或是對其他使用者造成負面影響的任何行動，可能在本公司謹慎審核情節後受到警告與處罰。</p>

            <h2>3.存取服務</h2>
            <p>一般而言，服務為24小時全年無休開放存取。然而，若是在KRAFTON認定須對服務啟用更新或技術維護，或KRAFTON自行決定有採取任何行動之必要而關閉所有或部分服務的期間，使用者將無法存取服務。</p>
            <p>我們可以在不事先通知使用者的情況下，修改或限制所有或特定使用者存取服務，無須擔負任何責任。此外，在某些地理位置有可能會無法使用服務。</p>

            <h2>4.所有權</h2>
            <p>您同意並承認與服務及其所有或任何副本（包括但不限於衍生作品、標題、電腦代碼、主題、物件、人物、人物名稱、故事、對話、口號、地點、概念、藝術品、圖像、動畫、音效、音樂作品、視聽特效、文字、螢幕顯示畫面、操作方法、人格權及任何相關書面文件）相關聯的所有權利、所有權和智慧財產權，均屬KRAFTON或其授權人所有。</p>

            <h2>5.責任和損害賠償限制</h2>
            <p>對於您在使用服務時，造成您蒙受您我雙方均無法合理預見的任何損害或損失，包括我們違反本條款所引發的損害或損失在內，KRAFTON概不負責。</p>
            <p>系統出現故障、進行維護或修復，或者出於我們無法合理控制的理由時，服務可能會暫時中止，恕不另行通知。除非本條款另有規定，否則對於任何無法使用、中止提供、修改或更動全部或部分服務之情況，我們對您或其他所有人概不承擔任何責任。</p>
            <p>KRAFTON對以下情況概不負責：</p>
            <ul>
                <li>(a) 在您未以消費者身分簽訂本條款的情況下，對您造成任何損害或損失；</li>
                <li>(b) 由於不可抗力事件或其相關因素所致，而造成遊戲或服務或其任何內容出現故障、暫停和／或終止存取的情況；本條款提及的「不可抗力」事件包括但不限於罷工、停工或其他勞資糾紛、核事故或天災、戰爭或恐怖行動、暴動、民變、惡意破壞、遵守任何法律或政府命令、法規管制或指令、工廠或機器發生意外或故障、火災、水災、風暴或供應商違約，以及我們無法合理控制的情況、其他任何行動、事件、疏漏或意外；和／或</li>
                <li>(c) 第三方對您提出本條款未載明的任何索賠請求。</li>
                <li>(d) 因使用者犯錯而導致服務中斷、服務使用中斷或合約終止。</li>
                <li>(e) 電信業者停止或無法提供電信服務。電信服務無法正常運作期間所引起的任何損害，須由使用者自行承擔。</li>
                <li>(f) 因無可避免的理由而造成服務停止運作，例如維護、替換、定期檢查、施工等。</li>
                <li>(g) 使用者的電腦或網路環境相關問題所造成的任何問題。</li>
                <li>(h) 使用者或第三方在服務中或KRAFTON網站上張貼或發送的資訊、資料、事實、可靠性和正確性等內容。</li>
                <li>(i) KRAFTON的免費服務所造成的損害。</li>
                <li>(j) 其他服務提供者造成的損害。</li>
            </ul>
            <p>本條款無法：</p>
            <ul>
                <li>(a) 限制您身為消費者的法定權利（包括陳述或得到合理水準之服務的權利，更多相關細節可向當地貿易標準局或公民諮詢局洽詢）；或是</li>
                <li>(b) 限縮我們因為自身的疏失或詐欺，造成有人傷亡所須擔負的責任。</li>
            </ul>
            <p>使用服務即代表您同意對於因為您使用或誤用服務，或因違反KRAFTON政策或與之相關的因素，而引發任何索賠、訴訟、損害、損失、責任和費用（包括律師費），您會負起捍衛和保護KRAFTON的責任，且會負責賠償使KRAFTON免受傷害。對於使用者負責賠償的所有相關事宜，KRAFTON保留全權負責辯護和處置的權利。</p>
            <p>在適用法律允許的最大範圍之內，對於任何間接、附帶、衍生、特殊、懲罰或懲戒性損害（包括但不限於商業損失、資料遺失、喪失商業權利、利潤虧損），以及因為您使用或誤用服務而引發的損害（包括但不限於工作運行中斷、硬體故障或其他商業損害或損失），KRAFTON一律不負任何責任。無論KRAFTON是否已知或應當知曉可能會有該等損害，上述條文依然適用。</p>
            <p>對於任何服務中斷，其中包括但不限於ISP服務異常、軟硬體故障或任何其他可能導致資料遺失或服務中斷的情況，本公司概不負責。有些司法管轄區禁止排除或限制衍生或附帶損害的賠償責任，因此在這類國家或司法管轄區中，上述限制條款的效力以適用法律允許的最大範圍為限。</p>

            <h2>6.排除擔保</h2>
            <p>服務和內容均以「現狀」提供，您必須自負任何風險。在法律允許的最大範圍內，KRAFTON明確聲明不提供書面或口頭、明示、默示或法定等任何形式的擔保或條件，包括但不限於任何所有權利、非侵權、適售性、平靜受益權、正確性或特殊目的之適用性的默示擔保。</p>
            <p>在不影響前述條文規定的前提下，KRAFTON不保證會持續提供KRAFTON服務，亦不保證服務不會出錯、安全無虞且運作時不含病毒。部分州或國家禁止排除默示擔保，因此前述排除聲明可能並不適用於您。本擔保給予您特定的法律權利，取決於所在州或國家的不同，您可能還擁有其他法律權利。</p>

            <h2>7.期間和終止</h2>
            <p>本條款具有永久效力，直到您或KRAFTON任何一方終止本條款為止。KRAFTON與使用者可以隨時終止本條款，無需事先通知。KRAFTON可自行決定終止本條款的生效時間。您可以藉由停用服務的方式來終止本條款。第4條（所有權）、第5條（責任和損害賠償限制）、第6條（排除擔保）、第7條（期間和終止）、第8條（處理投訴和解決糾紛）、第9條（準據法）、第10條（放棄集體訴訟權）和第12條（其他政策）的效力不受到本條款中任何條文終止的影響。</p>

            <h2>8.處理投訴和解決糾紛</h2>
            <p>如果您和KRAFTON之間發生糾紛，我們的目標是協助您以中立且划算的方式快速解決糾紛。因此，在不違反當地法律且法律允許的範圍之內，使用服務即代表您和KRAFTON均同意若索賠金額低於10,000美元或等值金額時，會採用個案的仲裁處理方式，透過公司與您共同選定且經雙方同意的仲裁人來解決糾紛。採用個案的仲裁處理方式意指您不具有並放棄由法官或陪審團做出賠償判決的權利，且您不得以多人聯合的集體方式，亦不得以代表人身分進行訴訟。其他例如證據開示和上訴等雙方原本在法院中擁有的權利，仲裁時可能無法使用這些權利或權利會較為限縮。若雙方尚未共同議定仲裁人，所有這類糾紛須依據當時有效的仲裁人規則，提交給選定的仲裁人作出具有約束力的仲裁。 </p>

            <h2>9.準據法</h2>
            <p>本條款須依據大韓民國的法律解釋並以此為準據法。有關本條款的所有法律訴訟，須遵照如《韓國民事訴訟法》等相關法律，交由具管轄權的法院審理。若您是在大韓民國以外的地方使用服務，可能另適用其他法律。在此情況下，本條款的效力受到當地適用法律的影響僅以適用司法管轄權規定的範圍為限，且必須盡可能以確保條款和條件效力的方式來解釋本條款。您必須遵守所有適用範圍內的當地法律。</p>

            <h2>10.放棄集體訴訟權</h2>
            <p>您同意不參與（包括但不限於）與服務和本協議有關的集體或代表人訴訟、共同私人代理人訴訟或集體仲裁。您也同意不會在未經本協議所有當事人許可的情況下，設法將有關服務和本協議的任何訴訟或仲裁併入任何其他訴訟或仲裁案和所有其他訴訟或仲裁案中。</p>

            <h2>11.版權聲明</h2>
            <p>若您認為KRAFTON和／或KRAFTON品牌網站的任何資料內容侵犯到您依著作權法受到保護的作品，您可以提供侵權通知書給下述KRAFTON指定代理人：此外，為確保通知的效力，請務必檢附下列資訊：</p>
            <ul>
                <li>(a) 充足的細節以利我們找出指稱受到侵權的作品；</li>
                <li>(b) 您的聯絡資訊，包括您的居住地址和電子郵件地址；</li>
                <li>(c) 一段聲明，表明您真誠認為爭議作品並未獲得版權所有者、其代理人或法律的授權；</li>
                <li>(d) 一段聲明，保證通知書所含資訊一律屬實，並且您已獲得授權，代表其專有權受侵害之所有者提出檢舉，如有虛假則願受偽證罪懲處；以及</li>
                <li>(e) 您的親筆或電子簽名。</li>
            </ul>

            <p>公司的指定代理人聯絡資訊如下：</p>
            <ol>
                <li>KRAFTON, Inc.</li>
                <li>收件人：Legal Affairs Department Agent</li>
                <li>Tower, 117, Bundangnaegok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea. </li>
                <li>電子郵件：privacy@krafton.com</li>
            </ol>
            <h2>12.其他政策</h2>
            <ol>
                <li>1) 為了保護使用者的利益並維護秩序，KRAFTON可能會在隱私權政策中具體列出服務條款中未涵蓋的細節。</li>
                <li>2) 同意服務條款所載條件時，即代表您也同意受到隱私權政策載明之條款和條件的約束。</li>
                <li>3) 您可以隨時在KRAFTON網站上找到隱私權政策。</li>
            </ol>

            <p>本服務條款提供數種語言的翻譯版本，以方便使用者閱讀。若英文版本與其他語言版本間有歧異之處，悉以英文版本為準。但是，韓國使用者須優先以韓文版的服務條款為依據。</p>
        `,
    },

    header: {
        links: [
            { to: 'press', label: '媒體素材包' },
        ],
    },

    menu: [
        { to: '', label: 'Thunder Tier One' },
        { to: 'press', label: '媒體素材包' },
        { to: 'privacy', label: '隱私權政策' },
    ],
    
    footer: {
        links: [
            { to: 'press', label: '媒體素材包' },
            { to: 'privacy', label: '隱私權政策' },
            { to: 'terms', label: '條款' },
        ],
        icon: {
            type: 'pegi',
            src: '/pegi16.jpg',
            alt: '分級待定 (PEGI 16)',
        },
        termsMessage: 'KRAFTON, Inc。版權所有，並保留一切權利。',
    },

    languageToggle: {
        questionText: '要變更為該 <strong class="c-blonde">{LANG}</strong> 嗎？',
        yesLabel: '是',
        noLabel: '否',
    },

    error: {
        seo: {
            title: '很抱歉，你要查找的网页找不到。',
            description: 'Thunder Tier One is a realistic top-down shooter where the player will join an elite task force fighting enemy operatives. Players must utilize all their tactical skills in real time to neutralize enemy combatants and achieve mission objectives.',
        },
        heading: '很抱歉，你要查找的网页找不到。',
    },
}
