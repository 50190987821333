import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import is from 'react-jss'

import LanguageSuggestion from '../LanguageSuggestion'
import LanguageSelector from '../LanguageSelector'
import Menu from '../Menu'

import style from './style'

const HeaderDefault = ({
    classes,
    ISO,
    locale,
    header,
    links,
}) => {
    const [ bodyOffset, setBodyOffset ] = useState({ y: 0 })

    const scrollListener = () => {
        if (typeof document.body !== 'undefined') {
            setBodyOffset(document.body.getBoundingClientRect())
        }
    }

    const handleLinkClick = (label) => {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: 'TTO',
                action: 'Click',
                label,
            },
        })
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollListener)

        return () => {
            window.removeEventListener('scroll', scrollListener)
        }
    }, [])

    return [
        <LanguageSuggestion { ...{ key: 'language-suggestion', locale, ISO } } />,
        <div key="header" className={ cx(classes.header, { scrolling: bodyOffset.y <= -100 }) }>
            <div className={classes.left}>
                <Link to={ `/${locale}` }>
                    <img src="/logo.png" className={classes.logoImage} alt="Thunder Tier One" />
                </Link>
            </div>

            <div className={classes.middle}>
                <ul className={classes.items}>
                    { header.links.map(({ to, label }, i) => (
                        <li key={i} className={classes.item}>
                            <Link to={ `/${locale}/${to}` } className={classes.itemLink} onClick={ () => handleLinkClick(label) }>{ label }</Link>
                        </li>
                    )) }
                </ul>
            </div>

            <div className={classes.right}>
                <LanguageSelector { ...{ locale } } />
                <Menu
                    { ...{
                        locale,
                        links,
                        classNames: {
                            toggle: classes.menuToggle,
                        }
                    } }
                />
            </div>
        </div>
    ]
}

export default is(style)(HeaderDefault)
