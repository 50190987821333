import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import is from 'react-jss'

import { useMenuState } from '../../context'

import IconButton from '../IconButton'

import style from './style'

const Menu = ({
    classes,
    locale,
    links = [],
    classNames = {},
}) => {
    const { menuOpen, setMenuOpen } = useMenuState()

    return [
        <div
            key="menu"
            className={ cx(classes.menu, { open: menuOpen }) }
        >
            <div className={classes.menuInner}>
                <ul className={classes.items}>
                    { links.map(({ to, label }, i) => (
                        <li key={i} className={classes.item}>
                            <Link
                                { ...{
                                    to: `/${locale}/${to}`,
                                    className: classes.link,
                                    onClick: () => setMenuOpen(false),
                                } }
                            >{ label }</Link>
                        </li>
                    )) }
                </ul>
            </div>
        </div>,
        <IconButton
            key="toggle"
            name={ menuOpen ? 'close' : 'menu' }
            color={ menuOpen ? 'white' : 'black' }
            iconSize={ menuOpen ? 'md' : 'lg' }
            classNames={{ btn: cx(classes.toggle, classNames.toggle, { open: menuOpen }) }}
            clickHandler={ () => setMenuOpen(!menuOpen) }
        />,
    ]
}

export default is(style)(Menu)
