import React from 'react';

const Headings = ({ headings, activeId }) => {

    console.log('headings', headings);
    return (
        <ul>
            {headings.map((heading) => (
                <li key={heading.id} className={heading.id === activeId ? "active" : null}>
                    <a
                        href={`#${heading.id}`}
                        onClick={(e) => {
                            e.preventDefault();
                            document.querySelector(`#${heading.id}`).scrollIntoView({
                                behavior: "smooth"
                            })
                        }}>{heading.title}</a>
                </li>
            )
            )}
        </ul>
    )
}

export default Headings;