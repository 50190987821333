import grid from './grid'
import variables from './variables'
import normalize from './normalize'
import reset from './reset'
import fonts from './fonts'

export default {
    grid,
    variables,
	normalize,
    reset,
    fonts,
}
