import React from 'react'
import is from 'react-jss'

import translations from '../../../config/translations'

import Meta from '../../components/Meta'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import style from './style'

const Error = ({
    classes,
    content: {
        ISO,
        slug: locale,
        socialLinks,
        menu,
        header,
        error: {
            seo,
            heading,
            description,
        },
        footer,
    },
}) => {
    const hreflang = translations.map((translation, i) => ({
        rel: ISO !== translation.ISO ? 'alternate' : '',
        href: `/${translation.slug}`,
        hrefLang: translation.ISO,
    }))

    return [
        <Meta { ...{ key: 'meta', seo, hreflang, ISO } } />,
        <Header { ...{ key: 'header', ISO, locale, header, links: menu } } />,
        <div { ...{ key: 'error', className: classes.error }}>
            <div className="container">
                <div className="row">
                    <div className="col-mi-12">
                        { heading && (
                            <h1 className={classes.heading}>{ heading }</h1>
                        ) }

                        { description && (
                            <p className={classes.description}>{ description }</p>
                        ) }
                    </div>
                </div>
            </div>
        </div>,
        <Footer { ...{ key: 'footer', locale, socialLinks, ...footer } } />,
    ]
}

export default is(style)(Error)
