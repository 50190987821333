import React, { Suspense } from 'react'

import { ContextProvider } from '../../context'

import Root from '../Root'

const App = () => (
    <ContextProvider>
        <Suspense fallback={<div>...</div>}>
            <Root />
        </Suspense>
    </ContextProvider>
)

export default App
