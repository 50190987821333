export default {
    name: 'DE',
    label: 'Deutsch',
    slug: 'de',
    ISO: 'de',

    socialLinks: [
        { type: 'Discord', to: 'https://discord.gg/thundertierone' },
        { type: 'Facebook', to: 'https://www.facebook.com/ThunderTierOne/' },
        { type: 'Twitter', to: 'https://twitter.com/thundertierone?lang=en' },
        { type: 'Instagram', to: 'https://www.instagram.com/thundertierone/' },
    ],

    home: {
        seo: {
            title: 'Thunder Tier One',
            description: 'Thunder Tier One ist ein realistischer Shooter in der Vogelperspektive, bei dem sich der Spieler einer Elite-Einsatztruppe anschließt, um feindliche Agenten zu bekämpfen. Der Spieler muss seine taktischen Fähigkeiten in Echtzeit einsetzen, um feindliche Kämpfer zu neutralisieren und Einsatzziele zu erreichen.',
        },

        hero: {
            discordLabel: 'Auf Discord besuchen',
            subscribeLabel: 'Abonnieren',
        },

        newsletter: {
            heading: {
                default: 'Abonniere unseren Newsletter',
                success: 'Gut gemacht',
            },
            inputPlaceholder: 'E-Mail-Adresse',
            buttonText: 'Abonnieren',
            checkboxLabel: 'KRAFTON darf mir hin und wieder Neuigkeiten zu aktuellen Events senden. (Optional)',
            readMore: {
                label: 'Mehr Infos',
                text: '<strong>E-Mail empfangen</strong> Zum Versand von E-Mail rund um das Spiel und seine Dienste, verwenden KRAFTON, Inc. und ihre Partner persönliche Daten mit deiner Zustimmung. Du kannst diese Zustimmung jederzeit zurückziehen, auch nachdem du der Verwendung deiner Daten bereits zugestimmt hast.',
                link: 'Details',
            },
            messages: {
                success: 'Das Abo ist abgeschlossen.',
                error: 'Satelliten-Uplink-Fehler, bitte versuche es erneut',
                existing: 'You are already signed up!',
                missing: 'Bitte überprüfen und erneut versuchen',
            },
        },
    },

    press: {
        seo: {
            title: 'Über Thunder Tier One ',
            description: 'Thunder Tier One ist ein realistischer Shooter in der Vogelperspektive, bei dem sich der Spieler einer Elite-Einsatztruppe anschließt, um feindliche Agenten zu bekämpfen. Der Spieler muss seine taktischen Fähigkeiten in Echtzeit einsetzen, um feindliche Kämpfer zu neutralisieren und Einsatzziele zu erreichen.',
        },

        hero: {
            heading: 'Über Thunder Tier One ',
            description: 'Thunder Tier One ist ein realistischer Shooter in der Vogelperspektive, bei dem sich der Spieler einer Elite-Einsatztruppe anschließt, um feindliche Agenten zu bekämpfen. Der Spieler muss seine taktischen Fähigkeiten in Echtzeit einsetzen, um feindliche Kämpfer zu neutralisieren und Einsatzziele zu erreichen.',
            button: { to: '', label: 'thundertierone.com' },
        },

        about: {
            heading: 'Über die Entwickler',
            description: 'Thunder Tier One begann für PUBGs Leiter der Action- und Gunplay-Abteilung, Pawel Smolewski, als persönliches Projekt, während er 2016 bei Bohemia Interactive arbeitete. Er unterhielt sich mit seinen Kollegen Nelson Duarte und Arseniy Smirnov über die Idee, und gemeinsam begannen sie mit der Entwicklung des Spiels. Nach einem Jahr musste die Produktion aber eingestellt werden, da das Team andere Verpflichtungen hatte. Nachdem sie ihre Arbeit wieder aufgenommen und die Idee 2020 erfolgreich bei Krafton gepitcht hatten, stellte das Team Pedro Pinho, Jorge Aguiar, Lorenzo Salvadori, Ricardo Mota, Ricardo Sousa, Joao Rebelo und Michał Flodrowski ein, um bei der Arbeit am Spiel Unterstützung zu haben.',
        },

        krafton: {
            description: 'KRAFTON, Inc. ist ein Zusammenschluss unabhängiger Spiele-Entwicklerteams, die für verschiedene Unterhaltungstitel verantwortlich sind, einschließlich PLAYERUNKNOWN’S BATTLEGROUNDS, ELYON und TERA. Der Name, der von dem Wort „Craftsmanship“ abgeleitet ist, stellt ein Bekenntnis des Unternehmens zu stetigem Streben nach Innovationen und der Erstellung fesselnder Erlebnisse dar. KRAFTON besteht derzeit aus PUBG Studio, Bluehole Studio, RisingWings Studio und Striking Distance Studios. Weitere Informationen über die KRAFTON, Inc. gibt es unter https://www.krafton.com.',
        },

        links: {
            left: { heading: 'Presse / </br>Business </br>Geschäftskontakt', email: 'mediarequests@krafton.com' },
            right: { heading: 'Presse-News </br>& Medien', description: '<a href="https://press.pubg.com/TTO" target="_blank" rel="noopener nofollow">Sieh dir Presse-Medien und News</a> zu Thunder Tier One an' },
        },
    },

    privacy: {
        heading: 'Datenschutzrichtlinie von Thunder Tier One',
        content: {
            default: `
                <p>Wirksamkeitsdatum: 2021.12.07</p>

                <h2 id="einführung">1. Einführung</h2>
                <p>KRAFTON, Inc. und/oder seine angeschlossenen Unternehmen („KRAFTON“, „wir“, „Unternehmen“ oder „uns“) erfasst und verarbeitet personenbezogene Daten über Sie („Sie“, „Benutzer“ oder „Spieler“). Wir bieten Spielern das Videospiel („Spiel(e)“), offizielle Webseiten („Webseite(n)“) und Services zum Spiel (d. h. Veranstaltungen, Umfragen oder Kundenservice) („Service(s)“). In dieser Datenschutzrichtlinie („Richtlinie“) wird beschrieben, wie wir während und nach Ihrer Beziehung mit uns personenbezogene Daten über Sie gemäß der geltenden Datenschutzgesetze erfassen, verarbeiten und freigeben.</p>

                <p>Es ist wichtig, dass Sie diese Richtlinie zusammen mit allen anderen Hinweisen lesen, die wir zu bestimmten Gelegenheiten bereitstellen, wenn wir personenbezogene Daten über Sie erfassen oder verarbeiten, damit Ihnen bekannt ist, wie und warum wir solche personenbezogenen Daten verwenden und welche Rechte Sie haben.</p>

                <h2 id="von-uns-erfasste-daten">2. Von uns erfasste Daten</h2>

                <p>Wir erfassen Daten über Sie und den von Ihnen verwendeten Service. Diese Daten sind möglicherweise erforderlich, um eine Vertragsbeziehung mit Ihnen einzugehen oder eine vertragliche Verpflichtung mit Ihnen auszuführen (z. B. um auf Ihren Wunsch den Service bereitzustellen), und das Versäumnis der Bereitstellung solcher Daten hat möglicherweise unserer Unfähigkeit zur Bereitstellung der angeforderten Services zufolge. Die von uns erfassten Informationen fallen in drei unterschiedliche Kategorien:</p>

                <h3>1) Direkte Datenerfassung bei Ihnen</h3>

                <p>Sie stellen uns beim Spielen unseres Spiels und/oder der Nutzung unserer Services möglicherweise die folgenden Daten bereit. Die Daten, die Sie uns bereitstellen, können in Abhängigkeit der Umstände abweichen. Diese können beispielsweise Folgendes umfassen:</p>

                <ul>
                    <li>Steam-ID (zur Bereitstellung und zum Betrieb des Spiels) und</li>
                    <li>E-Mail-Adresse, Land des Aufenthalts und Informationen zur Warenzustellung (d. h. Name, Postanschrift und Telefonnummer) (zur Teilnahme am Test, an der Umfrage oder anderen Veranstaltungen).</li>
                </ul>

                <p>Wenn Sie soziale Funktionen nutzen (z. B. Chat im Spiel), sind die von Ihnen offengelegten Informationen öffentlich. Wir speichern diese möglicherweise und greifen darauf zu. Sie werden möglicherweise von anderen Benutzern ohne Ihre Erlaubnis gelesen, kopiert, erfasst oder genutzt.</p>

                <h3>2) Automatische Datenerfassung</h3>

                <p>Wir erfassen bestimmte Daten automatisch, wenn Sie unser Spiel spielen und/oder unsere Services nutzen. Die Art der von uns automatisch erfassten Daten kann variieren, umfasst im Allgemeinen jedoch Folgendes:</p>

                <ul>
                    <li>Geräteinformationen (einschließlich aber nicht begrenzt auf die IP-Adresse, die Geräte-ID, das Gerätemodell, den Netzwerktyp, die Betriebssystemversion, die Zeitzoneneinstellungen, den Akkustatus, die Bildschirmauflösung usw.),</li>
                    <li>Gerätevorfallinformationen, einschließlich Absturzberichten und Details zur Systemaktivität (z. B. wenn bei der Verwendung unserer Services ein Fehler aufgetreten ist oder die Internetverbindung getrennt wurde usw.) und</li>
                    <li>Ihr Surfverhalten während der Nutzung unserer Webseite bzw. unseres Service.</li>
                </ul>

                <h3>3) Durch Drittanbieter erfasste Daten</h3>

                <p>Wenn Sie über eine Drittanbieterplattform auf unsere Spiele und/oder Services zugreifen, erhalten wir möglicherweise bestimmte Daten aus Ihrem Profil/Konto bei Drittanbieterplattformen gemäß den Bedingungen der Drittanbieterplattform und Ihren Datenschutzeinstellungen dort. Beispielsweise Steam-ID usw.</p>

                
                <h2 id="cookie-und-ähnliche-technologien">3. Cookie und ähnliche Technologien</h2>

                <p>Wir verwenden Cookies und andere Technologien zur Erkennung Ihres Browsers oder des Geräts und unter anderem, um mehr über Ihre Interessen zu erfahren und Ihnen grundlegende Funktionen und Services für zusätzliche Zwecke bereitzustellen. Genehmigte Drittanbieter können bei ihrer Nutzung unserer Webseite oder Spiele zudem Cookies und zugehörige Technologien festlegen.</p>

                <h3>1) Cookies</h3>

                <p>Cookies sind kleine Dateien, die auf Ihren Computer heruntergeladen werden (sofern in Ihrem Browser aktiviert ist, das Cookies akzeptiert werden), um Bewegungen innerhalb von Webseiten nachzuverfolgen und Ihre Erfahrung auf der Webseite anzupassen. Die meisten Internetbrowser ermöglichen Ihnen die Löschung von Cookies von der Festplatte Ihres Computers, die Blockierung der Akzeptanz von Cookies oder den Erhalt einer Warnung vor der Speicherung eines Cookies. Sie sollten in der Anleitung Ihres Browsers oder im „Hilfe“-Bildschirm nachsehen, um mehr über die Verwaltung von Cookies zu erfahren. Beachten Sie allerdings, dass bei der Sperrung von Cookies einige Teile der Webseite möglicherweise nicht einwandfrei funktionieren.</p>

                <p>Wir steuern die Cookies in der Werbung von Drittanbietern nicht und Sie sollten die Datenschutzrichtlinien von Werbetreibenden und/oder Werbeservices einsehen, um sich über deren Nutzung von Cookies und anderen Technologien zu informieren. Die auf der Webseite angezeigte Werbung wird Ihnen möglicherweise von Drittanbieter-Werbeunternehmen bereitgestellt. Diese Unternehmen nutzen gegebenenfalls Daten zu Ihren Besuchen auf dieser und anderen Webseiten, um Ihnen auf dieser und anderen Webseiten Werbung zu möglicherweise für Sie interessante Waren und Services bereitzustellen.</p>

                <h3>2) Webseitenanalyse</h3>

                <p>Wir nutzen Analyseservices auf unserer Webseite, damit wir Informationen zur Verwendung dieser Webseite aufzeichnen und unser Angebot kontinuierlich verbessern können.</p>

                <p>Auf dieser Webseite wird Google® Analytics verwendet. Dies ist ein von Google Inc. („Google“) bereitgestellter Service zur Webanalyse. Wir verwenden gegebenenfalls die folgenden Werbefunktionen von Google® Analytics sowie Facebook Pixel und erfassen Daten wie von Google bereitgestellt, und Sie können Ihre Zustimmung durch das Deaktivieren solcher Funktionen in den Werbeeinstellungen von Google zurückziehen: Weitervermarktung durch die Verwendung von Google® Analytics; Google® Display Network Exposure Report; Google® Analytics-Demografiken und Interessenberichte; sowie integrierte Services, die eine Datenerfassung über Google® Analytics mittels Werbe-Cookies und anonymen IDs zulassen.</p>

                <p>Weitere Informationen zur eigenen Datenschutzrichtlinie von Google finden Sie unter <a href="https://www.google.com/policies/privacy" target="_blank" rel="noreferrer">https://www.google.com/policies/privacy.</a></p>

                <p>Wenn Sie sich von der Nutzung Ihrer Informationen oder Daten durch Google Analytics abmelden möchten, können Sie das Add-On zum Abmelden von Google Analytics herunterladen, das hier verfügbar ist: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout</a></p>

                <h3>3) Technologie gegen Schummelei und zur Betrugsverhinderung</h3>

                <p>Wir bemühen uns, allen Spielern eine sichere und ehrliche Spieleumgebung bereitzustellen. Zur Betrugsverhinderung, zur Sicherheit und zu Authentifizierungszwecken verwenden wir oder Drittanbieter Cookies und ähnliche Technologien oder erfassen Daten über Ihren Computer oder Ihr Gerät, wenn Sie das Spiel spielen. Wir verwenden möglicherweise bei unseren Services auch andere Technologie gegen Schummelei.</p>


                <h2 id="so-verwenden-wir-ihre-daten">4. So verwenden wir Ihre Daten</h2>

                <p>Wir verwenden die erfassten Daten zu zahlreichen Zwecken, in erster Linie zur Bereitstellung des Spiels und des Service (zusammen „Spiele-Service“) für Spieler wie Sie und zur Verbesserung der Spiele-Service-Qualität. Dies umfasst Folgendes:</p>

                <ul>
                    <li>Betrieb und Bereitstellung des Spiele-Service,</li>
                    <li>Bereitstellen von Gelegenheiten zur Teilnahme an den Veranstaltungen oder Umfragen in Bezug auf den Spiele-Service,</li>
                    <li>Lösen technischer Probleme und Verbessern der Qualität der Spiele-Service (zum Beispiel zur Bereitstellung einer realistischen Spieleumgebung an Benutzer),</li>
                    <li>Identifizieren einzelner Spieler zur Bereitstellung des Spiele-Service,</li>
                    <li>Technischer Schutz vor der Nutzung nicht autorisierter Programme,</li>
                    <li>Verhindern einer unangemessenen Spielweise, die andere Spieler schaden könnte,</li>
                    <li>Verhindern, Erkennen, Identifizieren, Untersuchen und Reagieren auf potenzielle oder tatsächliche Ansprüche, Haftung, verbotenes Verhalten und kriminelle Aktivität.</li>
                </ul>
                
                <p>Größtenteils ist unsere Verarbeitung Ihrer personenbezogenen Daten für eine Reaktion auf Sie oder zur Verarbeitung Ihrer Anfrage erforderlich. Wir verarbeiten Ihre Informationen möglicherweise auch zur Erfüllung einer relevanten rechtlichen Pflicht. Gelegentlich bitten wir Sie möglicherweise um Ihre Zustimmung zur Nutzung Ihrer Daten aus bestimmten Gründen. Sie können Ihre Zustimmung jederzeit ohne Auswirkung auf die Rechtsgültigkeit der Verarbeitung auf Grundlage der Zustimmung vor der Rücknahme zurückziehen, indem Sie sich unter der in dieser Richtlinie angegebenen Adresse an uns werden.</p>

                <h2 id="weitergabe-ihrer-daten">5. Weitergabe Ihrer Daten</h2>

                <p>Wir erteilen Drittanbietern, einschließlich Serviceanbietern, die personenbezogene Daten in unserem Namen verarbeiten, die Erlaubnis zur Bereitstellung von Kundensupport und zur Verwaltung unserer Community. Beispiele dieser Drittanbieter umfassen:</p>

                <ul>
                    <li>Drittanbieter von Einschränkungsprogrammen zur Sperrung von Schummel-Tools und nicht autorisierten Programmen,</li>
                    <li>Cloud-Computing-Serviceanbieter zur Bereitstellung von Cloud-Speicher-Services,</li>
                    <li>Anbieter von Online-Umfrage-Services zur Verbesserung des Spiels.</li>
                </ul>

                <p>Wir geben Ihre Daten möglicherweise auch da frei, wo eine solche Offenlegung für die Einhaltung einer rechtlichen Pflicht oder einer rechtsgültigen Anfrage auf Datenzugriff, im Rahmen einer Fusion oder Akquisition, für unsere verbundenen Unternehmen für die in dieser Richtlinie beschriebenen Zwecke wichtig ist oder Sie uns Ihre Zustimmung erteilt haben oder es Ihrer Weisung entspricht.</p>

                <h2 id="internationale-datenübertragungen>6. Internationale Datenübertragungen</h2>

                <p>Wir operieren weltweit und übertragen Ihre Daten in Länder oder andere Regionen außerhalb Ihres Aufenthaltsortes. Bei der Übertragung, Speicherung oder Verarbeitung Ihrer personenbezogenen Daten durch uns unternehmen wir angemessene Schritte zur Gewährleistung des Schutzes personenbezogener Daten. In bestimmten Ländern verarbeiten wir Daten in bestimmten Regionen (wie im Folgenden detaillierter dargelegt).</p>

                <h2 id="aufbewahrung-und-löschung-personenbezogener-daten">7. Aufbewahrung und Löschung personenbezogener Daten</h2>

                <p>Prinzipiell entfernen wir Ihre Daten ohne Verzögerung, nachdem der Zweck der Erfassung personenbezogener Daten erreicht wurde. In Papierform gespeicherte personenbezogene Daten werden geschreddert oder verbrannt und elektronisch gespeicherte personenbezogene Daten werden mithilfe technischer Mittel auf eine Weise gelöscht, die keine Reproduzierung ermöglicht. Allerdings behalten wir Ihre personenbezogenen Daten auch nach Schließung Ihres Kontos bei uns oder dem Ende der Servicebereitstellung an Sie, wenn die Aufbewahrung Ihrer personenbezogenen Daten hinreichend Notwendig für die Einhaltung unserer rechtlichen Pflichten, der Erfüllung regulatorischer Anforderungen, der Lösung von Konflikten, der Vermeidung von Betrug, Schummelei oder Missbrauch, oder der Durchsetzung dieser Richtlinie bzw. einer beliebigen anderen Vereinbarung ist, die wir mit einem Spieler geschlossen haben.</p>

                <h2 id="unsere-richtlinie-bezüglich-kindern">8. Unsere Richtlinie bezüglich Kindern</h2>

                <p>Es erfolgt wissentlich keine Erfassung, Verwendung oder Weitergabe von Daten über Kinder (in den USA allgemein Personen unter 13 Jahren bzw. abhängig vom Land Ihres Aufenthalts), ohne nachprüfbare Zustimmung oder soweit gesetzlich zulässig. Wenn Sie ein Elternteil oder Erziehungsberechtigter sind, und glauben, Ihr Kind hat uns Daten bereitgestellt, können Sie sich an uns wenden und die Löschung der Daten Ihres Kindes von unserem System fordern.</p>

                <h2 id="links-und-services-von-drittanbietern">9. Links und Services von Drittanbietern</h2>

                <p>Wenn Sie auf einen Link zu einer Drittanbieter-Webseite, einschließlich und ohne Einschränkungen eine Werbung, klicken, verlassen Sie diese Webseite und gelangen zur gewählten Webseite. Da wir die Aktivitäten von Drittanbietern nicht kontrollieren können, sind wir für die Nutzung Ihrer personenbezogenen Daten durch solche Drittanbieter nicht verantwortlich und können deren Einhaltung derselben Datenschutz- und Sicherheitspraktiken wie der unseren nicht garantieren. Wir empfehlen Ihnen, die Datenschutzrichtlinien von anderen Dienstanbietern, von denen Sie Services anfordern, zu lesen. Bei Besuch einer Drittanbieter-Webseite, die mit der Webseite verknüpft ist, sollten Sie vor Bereitstellung jeglicher personenbezogener Daten die Datenschutzrichtlinie lesen.</p>

                <h2 id="sicherheit-ihrer-daten">10. Sicherheit Ihrer Daten</h2>

                <p>Wir haben angemessene Sicherheitsmaßnahmen getroffen, einschließlich Verschlüsselung und Re-Identifizierung, um zu verhindern, dass Ihre personenbezogenen Daten versehentlich an die Öffentlichkeit gelangen, verwendet, verändert oder offengelegt bzw. nicht in einer nicht autorisierten Weise darauf zugegriffen werden kann. Außerdem begrenzen wir den Zugriff auf Ihre personenbezogenen Daten auf die Mitarbeiter, Agenten usw., bei denen eine geschäftliche Notwendigkeit dafür vorliegt, diese Daten zu kennen. Sie werden Ihre personenbezogenen Daten nur auf unsere Anweisung hin verarbeiten und sie unterliegen einer Verschwiegenheitspflicht. Wir haben Sicherheitsmaßnahmen getroffen, um mit jeglichem verdächtigen Verstoß gegen die Datensicherheit umgehen zu können, und werden Sie und alle entsprechenden Regulatoren über einen verdächtigen Verstoß informieren, wenn wir dazu rechtlich verpflichtet sind. Allerdings sind wir nicht für Probleme verantwortlich, die trotz dieser Sicherheitsmaßnamen und -vorgänge entstehen, z. B. Ihre Fahrlässigkeit oder Probleme im Internet. Beachten Sie, dass keine Datenübertragung über das Internet zu 100 % sicher ist und dass eine vollständige Sicherheit der von uns erfassten und verwendeten Daten daher nicht garantiert werden kann.</p>

                <h2>11. Ihre Datenschutzrechte</h2>

                <p>Unter den Beschränkungen des geltenden Rechts sind Sie berechtigt, der Einschränkung der Verarbeitung Ihrer personenbezogenen Daten sowie dem Zugriff darauf, der Nachbesserung, Löschung sowie Übertragbarkeit Ihrer eigenen personenbezogenen Daten zu wiedersprechen oder zuzustimmen. Wenn Sie eine Anfrage bezüglich Ihrer Rechte oder eine andere Frage stellen möchten wenden Sie sich bitte über die im Abschnitt „13. Kontakt“ angegeben Adresse per E-Mail an uns. Wir antworten auf alle Anfragen, die wir von Personen erhalten, die Ihre Datenschutzrechte gemäß der geltenden Datenschutzgesetze auszuüben wünschen.</p>

                <h2 id="änderungen">12. Änderungen</h2>

                <p>Wir behalten uns vor jeden beliebigen Teil dieser Richtlinie jederzeit nach unserem Ermessen durch Veröffentlichung oder Anzeige dieser geänderten Richtlinie in unserer Webseite zu ändern. Durch die fortgesetzte Nutzung des Service wird davon ausgegangen, dass Sie solchen Änderungen zustimmen. Informieren Sie sich daher bitte häufig.</p>
                
                <h2 id="kontakt">13. Kontakt</h2>

                <p>Bei Fragen zu dieser Richtlinie oder zu Ihrem Umgang mit dieser Webseite kontaktieren Sie uns bitte über einen der folgenden Kommunikationskanäle.</p>

                <ul>
                    <li>E-Mail: <a href="mailto:privacy@krafton.com" target="_blank" rel="noreferrer">privacy@krafton.com</a></li>
                    <li>Anschrift: Centerfield East Tower, 231, Teheran-ro, Gangnam-gu, Seoul, Republik Korea</li>
                </ul>

                <p>Diese Datenschutzrichtlinie wurde für Ihren Komfort in andere Sprachen übersetzt. Im Falle von Unterschieden zwischen der englischen Version und anderen Versionen gilt die englische Version. Allerdings gilt für koreanische Spieler die koreanische Datenschutzrichtlinie.</p>


                <h2 id="zusatzbedingungen-rechtsgebietsspezifisch">14. ZUSATZBEDINGUNGEN – RECHTSGEBIETSSPEZIFISCH</h2>

                <p>Im Falle eines Konflikts zwischen den Zusatzbedingungen – rechtsgebietsspezifisch, die für Ihr Rechtsgebiet, aus dem Sie unsere Spiele spielen oder unsere Services nutzen, relevant sind, und dem Rest der Richtlinie gelten die Zusatzbedingungen – rechtsgebietsspezifisch des relevanten Rechtsgebiets als übergeordnet und steuernd.</p>

                <h3>Vereinte Staaten</h3>

                <h4>Kalifornien</h4>

                <p>Als Einwohner von Kalifornien haben Sie möglicherweise zusätzliche Rechte. Weitere Informationen bezüglich Ihrer Datenschutzrechte finden Sie in unserem <a href="/en/privacy/california">Datenschutzhinweis für Einwohner Kaliforniens.</a></p>

                <h4>Nevada</h4>

                <p>Wenn Sie ein Einwohner Nevadas sind, verkaufen wir Ihre von uns erfassten Daten personenbezogenen Daten nicht. Dennoch können Sie als Einwohner Nevadas, der unsere Spielelemente oder Waren von uns gekauft hat, eine Anfrage zur Aufzeichnung Ihrer Vorzüge einreichen, um sich künftig dagegen zu entscheiden.</p>

                <h3>EWR, Schweiz und Vereintes Königreich </h3>

                <p>Als Einwohner des EWR, der Schweiz und des Vereinten Königreichs haben Sie möglicherweise zusätzliche Rechte. </p>

                <h4>Internationale Datenübertragungen</h4>

                <p>Bei Übertragung Ihre Daten außerhalb des EWR in ein Land, das von der Europäischen Kommission nicht als ein Land anerkannt wird, in dem ein ausreichendes Datenschutzniveau sichergestellt wird, implementieren wir geeignete Maßnahmen, um Ihre personenbezogenen Daten entsprechend geltender Datenschutzgesetzte zu gewährleisten. Diese Garantien können Datenübertragungsvereinbarungen umfassen, mit denen standardmäßige vertragliche Klauseln (eine Form der von der Europäischen Kommission bereits als adäquate Garantie für personenbezogene Daten genehmigten Datenübertragungsvereinbarung) implementiert werden. Sie können uns wie in dieser Richtlinie beschrieben kontaktieren, um eine Kopie solcher geeigneten Maßnahmen anzufordern. Andernfalls übertragen wir personenbezogene Daten mit Einwilligung; um einen Vertrag mit Ihnen zu erfüllen; oder um ein zwingendes legitimes Interesse auf eine Weise zu erfüllen, die nicht durch Ihre Rechte und Freiheiten aufgehoben wird. Bei Fragen zu dieser Richtlinie können Sie uns über den Datenschutzbeauftragten kontaktieren (<a href="mailto:DPO@pubg.com" target="_blank" rel="noreferrer">DPO@pubg.com</a>) </p>
            `,
        },
    },

    terms: {
        heading: 'Nutzungsbedingungen der offiziellen Thunder Tier One-Website',
        content: `
            <p>Datum des Inkrafttretens: 10. Dezember 2020</p>

            <p>KRAFTON, Inc. („KRAFTON“, „wir“, oder „uns“) stellt über seine offizielle Website (OOO) spielbezogene Dienste (zusammenfassend „Dienst(e)“) zur Verfügung. Diese Nutzungsbedingungen regeln die Beziehung zwischen Ihnen und KRAFTON in Bezug auf die Nutzung der Dienste (die „Bedingungen“). Die Begriffe „Benutzer“ oder „Sie“ bezeichnen die Person, die diesen Bedingungen zustimmt. Durch die Nutzung der Dienste nehmen Sie diese Bedingungen an und erklären sich damit einverstanden, an sie gebunden zu sein und sie einzuhalten. Sind Sie mit diesen Bedingungen nicht einverstanden, dürfen Sie den Dienst nicht nutzen. </p>

            <h2>1. Aktualisierung der Dienste und Bedingungen</h2>
            <p>1) Die Nutzungsbedingungen können jederzeit auf der Website von KRAFTON eingesehen werden.</p>
            <p>2) KRAFTON kann diese Bedingungen jederzeit nach Bedarf ändern, soweit dies mit den maßgeblichen Gesetzen und Vorschriften vereinbar ist.</p>
            <p>3) Es obliegt Ihnen, diese Seite regelmäßig zu überprüfen, um Änderungen zur Kenntnis zu nehmen. Mit Ihrer fortgesetzten Nutzung der Dienste erklären Sie die Annahme der geänderten Bedingungen.</p>

            <h2>2. Unzulässige Nutzung</h2>
            <p>KRAFTON stellt Benutzern den Dienst nur für persönliche, nicht-kommerzielle und Unterhaltungszwecke zur Verfügung. Sie verpflichten sich, gegen den Inhalt dieser Bedingungen nicht zu verstoßen. Ein Verstoß gegen einen beliebigen Gesichtspunkt einer in dieser Vereinbarung genannten Regelung kann zu einer vorübergehenden oder dauerhaften Sperrung des Dienstes nach Maßgabe dieser Bedingungen führen. Sie verpflichten sich, den Dienst insgesamt oder einen beliebigen Teil davon nur in einer Weise zu nutzen, die mit diesen Bedingungen vereinbar ist. Sie DÜRFEN NICHT:</p>
            <ul>
                <li>(a) den Dienst insgesamt oder beliebige Teile davon ohne schriftliche Genehmigung von KRAFTON in irgendeiner Weise kommerziell nutzen, bewerben oder verwerten; dies gilt insbesondere für die Nutzung im Rahmen eines Cyber-(Internet-)Cafés, Computerspielzentrums oder einer anderen standortbasierten Lokalität;</li>
                <li>(b) den Dienst ganz oder teilweise zurückentwickeln, modifizieren, dekompilieren oder disassemblieren, den Quellcode ermitteln oder abgeleitete Werke aus dem Dienst erstellen (es sei denn, dies ist nach geltendem Recht ausdrücklich zulässig; ist dies der Fall, gelten alle rechtmäßigen Modifikationen, Anpassungen, Weiterentwicklungen u.ä. sowie alle Urheberrechte und Persönlichkeitsrechte daran in jedem Fall ab Erstellung als an KRAFTON und/oder seine Lizenzgeber abgetreten und in deren ausschließliches Eigentum übergegangen);</li>
                <li>(c) den Zugriff auf den Dienst steuernde Sicherheitsvorkehrungen oder technische Maßnahmen entfernen, deaktivieren oder umgehen;</li>
                <li>(d) auf oder innerhalb des Dienstes enthaltene Eigentumshinweise oder -kennzeichnungen entfernen, modifizieren, unkenntlich machen oder umgehen;</li>
                <li>(e) Informationen von anderen stehlen und/oder veröffentlichen (insbesondere personenbezogene oder nicht-personenbezogene Daten sowie Zahlungsinformationen);</li>
                <li>(f) sich als KRAFTON oder einer unserer Mitarbeiter ausgeben;</li>
                <li>(g) den Ruf von KRAFTON, unseren verbundenen Unternehmen, unseren Dienstanbietern und Lizenzgebern schädigen;</li>
                <li>(h) rechtswidrige, schädliche, bedrohende, missbräuchliche, belästigende, strafbare, verleumderische, vulgäre, sexuell explizite, obszöne, hasserfüllte oder rassistische bzw. ethnisch anstößige Inhalte posten, verlinken, bewerben, fördern oder übertragen;</li>
                <li>(i) andere in beliebiger Weise ausnutzen, um einen Vorteil zu erlangen;</li>
                <li>(j) den Dienst in einer Weise nutzen, die rechtswidrig ist oder sich nachteilig auf die Erbringung des Dienstes auswirkt;</li>
                <li>(k) die Dienste für einen (nach Auffassung des Unternehmens) rechtswidrigen oder über den Umfang der beabsichtigten Nutzung der Dienste hinausgehenden Zweck aufrufen oder nutzen;</li>
                <li>(l) anderen gegenüber respektlos oder beleidigend handeln, einschließlich in Bezug auf deren Rasse, Geschlecht oder Nationalität;</li>
            </ul>
            <p>Jede nicht ausdrücklich in diesen Bedingungen bezeichnete Handlung, die sich nachteilig auf die Erbringung des Dienstes von KRAFTON oder auf andere Benutzer auswirkt, kann nach sorgfältiger Prüfung der Umstände mit einer Verwarnung und Sanktion belegt werden.</p>

            <h2>3. Zugriff auf den Dienst</h2>
            <p>Der Dienst ist in der Regel ganzjährig 24 Stunden am Tag erreichbar.  Die Zugänglichkeit des Dienstes unterliegt jedoch dem Vorbehalt, dass KRAFTON den Dienst ganz oder teilweise für einen von KRAFTON als angemessen erachteten Zeitraum abschalten kann, um Aktualisierungen oder technische Wartungsarbeiten am Dienst oder andere nach dem alleinigen Ermessen von KRAFTON notwendige Maßnahmen durchzuführen.</p>
            <p>Wir können Änderungen am Dienst vornehmen oder den Zugang für alle oder bestimmte Benutzer ohne Vorankündigung und Haftung einschränken. Darüber hinaus kann der Dienst abhängig vom jeweiligen Standort nicht verfügbar sein.</p>

            <h2>4. Eigentum</h2>
            <p>Sie stimmen zu und erkennen an, dass alle mit dem Dienst und allen Kopien davon verbundenen Titel, Eigentumsrechte und geistigen Eigentumsrechte (insbesondere an abgeleiteten Werken, Bezeichnungen, Computercode, Themen, Objekten, Charakteren, Charakternamen, Geschichten, Dialogen, Schlagworten, Orten, Konzepten, künstlerischen Werken, Grafiken, Animationen, Klängen, musikalischen Kompositionen, audiovisuellen Effekten, Texten, Bildschirmanzeigen, Betriebsmethoden, Persönlichkeitsrechten und etwaigen zugehörigen Dokumentationen) das Eigentum von KRAFTON oder seinen Lizenzgebern sind.</p>

            <h2>5. Haftungsbeschränkung und Schadloshaltung</h2>
            <p>KRAFTON haftet nicht für Ihnen entstehende Schäden, wenn der betreffende Schaden für Sie und uns bei der Nutzung des Dienstes nicht vernünftigerweise vorhersehbar war. Dies gilt auch, wenn der Schaden auf einer Verletzung dieser Bedingungen durch uns beruht.</p>
            <p>Der Dienst kann im Falle eines Systemausfalls, einer Wartung oder Reparatur oder aus außerhalb unserer angemessenen Kontrolle befindlichen Gründen vorübergehend und ohne Vorankündigung ausgesetzt werden. Sofern nicht an anderer Stelle in diesen Bedingungen geregelt, sind wir weder Ihnen noch anderen gegenüber dafür haftbar, dass der Dienst ganz oder teilweise nicht verfügbar ist oder eingestellt, modifiziert oder in irgendeiner Weise verändert wurde.</p>
            <p>KRAFTON ist nicht haftbar:</p>
            <ul>
                <li>(a) für Ihnen entstehende Schäden, wenn Sie die diesen Bedingungen zugrunde liegende Vereinbarung nicht als Verbraucher eingehen;</li>
                <li>(b) für das Fehlschlagen, die Aussetzung und/oder die Beendigung des Zugriffs auf das Spiel oder die Dienste oder Inhalte in Verbindung mit oder wegen eines außerhalb unserer Kontrolle liegenden Ereignisses; Im Sinne dieser Bedingungen gelten als „außerhalb unserer Kontrolle liegendes Ereignis“ unter anderem Streiks, Aussperrungen oder sonstige Arbeitskonflikte, nukleare Unfälle oder Ereignisse höherer Gewalt, Kriege oder terroristische Handlungen, Ausschreitungen, zivile Unruhen, vorsätzliche Sachbeschädigungen, sich aus dem geltenden Recht oder behördlichen Anordnungen, Vorschriften oder Anweisungen ergebende Verpflichtungen, Unfälle oder Ausfälle von Anlagen oder Maschinen, Feuer, Überschwemmungen, Unwetter oder Ausfälle von Zulieferern sowie alle sonstigen sich unserer angemessenen Kontrolle entziehenden Handlungen, Ereignisse, Unterlassungen oder Unfälle;</li>
                <li>(c) für gegen Sie von Dritten geltend gemachte Forderungen, außer in den in diesen Bedingungen gegebenenfalls abweichend geregelten Fällen;</li>
                <li>(d) für Unterbrechungen des Dienstes, der Nutzung des Dienstes oder die Beendigung der diesen Bedingungen zugrunde liegenden Vereinbarung, wenn diese vom Benutzer zu vertreten sind;</li>
                <li>(e) wenn die Bereitstellung des Telekommunikationsdienstes seitens des Telekommunikationsanbieters eingestellt wird oder unterbleibt; Benutzer haften für alle während des Ausfalls des Telekommunikationsdienstes entstehenden Schäden;</li>
                <li>(f) bei Vorliegen unvermeidbarer Gründe, die zu einem Ausfall des Dienstes führen, wie z.B. Wartungsarbeiten, Austauschmaßnahmen, regelmäßige Inspektionen, Baumaßnahmen u.ä.</li>
                <li>(g) bei etwaigen Problemen, die durch den Computer des Benutzers verursacht werden, oder bei Problemen im Zusammenhang mit der Netzwerkumgebung;</li>
                <li>(h) für den Inhalt, die Zuverlässigkeit und die Genauigkeit der Informationen, Daten, Fakten usw., die vom Benutzer oder einem Dritten auf dem Dienst oder auf der Website von KRAFTON veröffentlicht oder übertragen werden;</li>
                <li>(i) für Schäden aus Leistungen, die von KRAFTON kostenlos erbracht wurden;</li>
                <li>(j) für Schäden, die von anderen Dienstanbietern verursacht wurden.</li>
            </ul>
            <p>Durch keine Regelungen dieser Bedingungen:</p>
            <ul>
                <li>(a) werden Ihre gesetzlichen Verbraucherrechte eingeschränkt (einschließlich Ihrer Rechte, einen angemessenen Leistungsstandard zu verlangen oder zu erhalten; weitere Informationen hierzu können Sie bei Ihrem örtlichen Amt für Handelsnormen oder Bürgerberatungsbüro erfragen) oder</li>
                <li>(b) wird unsere Haftung für Tod oder Personenschäden aufgrund von Fahrlässigkeit oder für Betrug beschränkt.</li>
            </ul>
            <p>Durch die Nutzung des Dienstes verpflichten Sie sich, KRAFTON hinsichtlich sämtlicher Forderungen, Klagen, Schäden, Haftpflichten und Kosten (einschließlich Anwaltskosten), die sich aus oder in Verbindung mit der Nutzung oder dem Missbrauch des Dienstes und der Verletzung der Richtlinien von KRAFTON durch Sie ergeben, freizustellen und schadlos zu halten. KRAFTON behält sich das Recht vor, die ausschließliche Abwehr und Kontrolle aller Angelegenheiten zu übernehmen, die unter die Schadloshaltungspflicht von Benutzern fallen.</p>
            <p>IN DEM NACH GELTENDEM RECHT WEITESTMÖGLICHEN UMFANG HAFTET KRAFTON UNTER KEINEN UMSTÄNDEN FÜR MITTELBARE, NEBEN-, FOLGE-, SONDER-, EXEMPLARISCHE ODER STRAFSCHÄDEN (INSBESONDERE WEGEN ENTGANGENER GESCHÄFTE, DATENVERLUST, VERLUST VON GESCHÄFTSRECHTEN, ENTGANGENEM GEWINN) UND SCHÄDEN (WIE Z.B. ARBEITSUNTERBRECHUNGEN, HARDWAREFEHLFUNKTIONEN ODER SONSTIGE GESCHÄFTLICHE SCHÄDEN), DIE AUS IHRER NUTZUNG ODER MISSBRÄUCHLICHEN NUTZUNG DES DIENSTES ENTSTEHEN. DIES GILT AUCH, WENN KRAFTON DIE MÖGLICHKEIT DES SCHADENSEINTRITTS BEKANNT WAR ODER HÄTTE BEKANNT SEIN MÜSSEN.</p>
            <p>DAS UNTERNEHMEN HAFTET NICHT FÜR UNTERBRECHUNGEN DES DIENSTES, INBESONDERE NICHT FÜR ISP-AUSFÄLLE, SOFTWARE- ODER HARDWAREAUSFÄLLE ODER ANDERE SITUATIONEN, DIE ZU DATENVERLUSTEN ODER UNTERBRECHUNGEN DES DIENSTES FÜHREN KÖNNEN. IN EINIGEN RECHTSORDNUNGEN SIND DER AUSSCHLUSS ODER DIE BESCHRÄNKUNG DER HAFTUNG FÜR FOLGE- ODER NEBENSCHÄDEN UNZULÄSSIG, SO DASS DIE OBEN GENANNTEN BESCHRÄNKUNGEN NUR IN DEM UMFANG GELTEN, DER NACH DEM IN DEN BETREFFENDEN LÄNDERN ODER RECHTSORDNUNGEN GELTENDEN RECHT MAXIMAL ZULÄSSIG IST.</p>

            <h2>6. Gewährleistungsausschluss</h2>
            <p>DIE DIENSTE UND INHALTE WERDEN OHNE MÄNGELGEWÄHR UND AUF IHR EIGENES RISIKO BEREITGESTELLT. IM WEITESTMÖGLICHEN RECHTLICH ZULÄSSIGEN UMFANG SCHLIESST KRAFTON HIERMIT JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNG JEDER ART AUS, UNABHÄNGIG DAVON, OB SCHRIFTLICH ODER MÜNDLICH, AUSDRÜCKLICH, STILLSCHWEIGEND ODER GESETZLICH, INSBESONDERE JEDE STILLSCHWEIGENDE GEWÄHRLEISTUNG DES EIGENTUMS, DER NICHTVERLETZUNG, DER MARKTGÄNGIGKEIT, DES UNGESTÖRTEN GENUSSES, DER GENAUIGKEIT ODER DER EIGNUNG FÜR EINEN BESTIMMTEN ZWECK.</p>
            <p>Ohne damit das Vorstehende einzuschränken, gewährleistet KRAFTON keinen kontinuierlichen, fehlerfreien, sicheren oder virusfreien Betrieb der Dienste von KRAFTON. In einigen Staaten oder Ländern ist der Ausschluss stillschweigender Gewährleistungen unzulässig, sodass der vorstehende Haftungsausschluss möglicherweise nicht für Sie gilt. Diese Gewährleistung gibt Ihnen bestimmte gesetzliche Rechte. Sie können zudem weitere gesetzliche Rechte haben, die von Staat zu Staat oder Land zu Land unterschiedlich sind.</p>

            <h2>7. Gültigkeitsdauer und Beendigung</h2>
            <p>Diese Bedingungen gelten, sofern und solange sie nicht entweder von Ihnen oder von KRAFTON gekündigt werden. Die Kündigung dieser Bedingungen kann durch KRAFTON und den Benutzer jederzeit und ohne vorherige Ankündigung erfolgen. Die Wirksamkeit einer Kündigung durch KRAFTON kann nach dem alleinigen Ermessen von KRAFTON jederzeit eintreten. Sie können diese Bedingungen kündigen, indem Sie den Dienst nicht nutzen.  Die Abschnitte 4 (Eigentum), 5 (Haftungsbeschränkung und Schadloshaltung), 6 (Gewährleistungsausschluss), 7 (Gültigkeitsdauer und Beendigung), 8 (Umgang mit Beschwerden und Beilegung von Streitigkeiten), 9 (Geltendes Recht), 10 (Verzicht auf Sammelklagen) und 12 (Sonstige Bestimmungen) gelten auch nach Beendigung dieser Bedingungen fort.</p>

            <h2>8. Umgang mit Beschwerden und Beilegung von Streitigkeiten</h2>
            <p>Im Falle einer Streitigkeit zwischen Ihnen und KRAFTON möchten wir Ihnen ein neutrales und kostengünstiges Mittel zur zügigen Beilegung des Streitigkeit bieten. Außer wenn und soweit dies rechtlich unzulässig ist, vereinbaren Sie daher durch die Nutzung der Dienste mit KRAFTON, bei Forderungen in Höhe von weniger als 10.000 US-Dollar oder dem entsprechenden Gegenwert die Inanspruchnahme eines Schiedsgerichtsverfahrens auf individueller Basis durch einen vom Unternehmen und von Ihnen einvernehmlich ausgewählten Schiedsrichter. SCHLICHTUNG AUF INDIVIDUELLER BASIS BEDEUTET, DASS SIE NICHT DAS RECHT HABEN BZW. AUF DAS RECHT VERZICHTEN, DASS DURCH EINEN RICHTER ODER EINE GRUPPE VON GESCHWORENEN ÜBER IHRE FORDERUNG ENTSCHIEDEN WIRD, SOWIE DASS SIE NICHT IM WEGE EINER SAMMELKLAGE, EINEM VERBUNDENEN VERFAHREN ODER EINER REPRÄSENTATIVKLAGE VORGEHEN KÖNNEN. Andere Rechte, die Ihnen und uns sonst in einem Gerichtsverfahren offenstehen würden, sind bei einem Schiedsverfahren nicht oder nur eingeschränkt verfügbar. Darunter fallen auch Rechte auf Offenlegung und Berufung. Alle betreffenden Streitigkeiten werden ausschließlich dem gewählten Schiedsrichter zur verbindlichen Schlichtung nach den zum betreffenden Zeitpunkt geltenden Schlichtungsregeln vorgelegt. Der Schiedsrichter ist von beiden Parteien einvernehmlich zu bestimmen. </p>

            <h2>9. Geltendes Recht</h2>
            <p>Diese Bedingungen unterliegen dem Recht der Republik Korea. Alle Rechtsstreitigkeiten im Zusammenhang mit diesen Bedingungen unterliegen der Gerichtsbarkeit der Gerichte gemäß den einschlägigen Gesetzen wie z.B. der koreanischen Zivilprozessordnung. Nutzen Sie den Dienst außerhalb der Republik Korea, gelten gegebenenfalls andere Gesetze. In einem solchen Fall wirkt sich das geltende lokale Recht nur insoweit auf diese Bedingungen aus, wie dies nach der betreffenden Rechtsordnung erforderlich ist, und diese Bedingungen sind so auszulegen, dass ihren Bestimmungen so wirksam wie möglich zur Geltung verholfen wird. Soweit lokales Recht zu beachten ist, sind Sie zu dessen Einhaltung verpflichtet.</p>

            <h2>10. Verzicht auf Sammelklagen</h2>
            <p>Sie verpflichten sich, sich insbesondere nicht an einer Sammelklage oder einer Repräsentativklage, einer privatrechtlichen Klage des Generalstaatsanwalts oder einem kollektiven Schiedsverfahren im Zusammenhang mit dem Dienst oder der diesen Bedingungen zugrunde liegenden Vereinbarung zu beteiligen. Sie verpflichten sich außerdem, nicht ohne die Zustimmung aller Parteien dieser Vereinbarung und aller anderen Klage- oder Schiedsgerichtsverfahren die Verbindung eines Klage- oder Schiedsgerichtsverfahren im Zusammenhang mit dem Dienst oder der diesen Bedingungen zugrunde liegenden Vereinbarung mit einem anderen Klage- oder Schiedsgerichtsverfahren anzustreben.</p>

            <h2>11. Urheberrechtshinweis</h2>
            <p>Wenn Sie der Meinung sind, dass Materialien von KRAFTON und/oder von Websites unter der Marke KRAFTON urheberrechtlich geschützte Werke von Ihnen widerrechtlich verwenden, können Sie die Verletzung dem von KRAFTON für solche Fälle benannten Vertreter wie unten beschrieben melden. Damit Ihrer Meldung nachgegangen werden kann, müssen Sie außerdem die folgenden Informationen angeben:</p>
            <ul>
                <li>(a) ausreichende Einzelheiten, anhand derer wir das mutmaßlich betroffene Werk identifizieren können;</li>
                <li>(b) Ihre Kontaktdaten, einschließlich Ihrer Anschrift und E-Mail-Adresse;</li>
                <li>(c) eine Erklärung, dass Sie in gutem Glauben davon ausgehen, dass für das streitige Werk keine Autorisierung durch den Urheberrechtsinhaber, einen seiner Vertreter oder ein Gesetz vorliegt;</li>
                <li>(d) eine eidesstattliche Erklärung, dass die in der Meldung angegebenen Informationen zutreffend sind und Sie befugt sind, im Namen des Eigentümers eines mutmaßlich verletzten Exklusivrechts zu handeln, und</li>
                <li>(e) Ihre physische oder elektronische Unterschrift.</li>
            </ul>

            <p>Die Kontaktdaten des vom Unternehmen für solche Fälle benannten Vertreters lauten wie folgt:</p>
            <ul>
                <li>KRAFTON, Inc.</li>
                <li>z. Hd.: Vertreter der Rechtsabteilung (Legal Affairs Department Agent)</li>
                <li>Tower, 117, Bundangnaegok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republik Korea. </li>
                <li>E-Mail: privacy@krafton.com</li>
            </ul>

            <h2>12. Sonstige Bestimmungen</h2>
            <p>1) KRAFTON kann bestimmte Einzelheiten, die nicht von den Nutzungsbedingungen abgedeckt sind, über die Datenschutzrichtlinie regeln, um die Interessen der Benutzer zu schützen und die Ordnung aufrechtzuerhalten.</p>
            <p>2) Indem Sie den in den Nutzungsbedingungen festgelegten Bedingungen zustimmen, verpflichten Sie sich gleichzeitig, an die in der Datenschutzrichtlinie festgelegten Bedingungen gebunden zu sein.</p>
            <p>3) Die Datenschutzrichtlinie kann jederzeit auf der Website von KRAFTON eingesehen werden.</p>

            <p>Diese Nutzungsbedingungen wurden zur Erleichterung für die Benutzer in andere Sprachen übersetzt. Im Falle von Abweichungen zwischen der englischen Fassung und anderen Fassungen ist die englische Fassung maßgebend. Für koreanische Benutzer sind jedoch die Nutzungsbedingungen in koreanischer Sprache maßgebend.</p>
        `,
    },

    header: {
        links: [
            { to: 'press', label: 'Presse-Medien' },
        ],
    },

    menu: [
        { to: '', label: 'Thunder Tier One' },
        { to: 'press', label: 'Presse-Medien' },
        { to: 'privacy', label: 'Datenschutz' },
    ],
    
    footer: {
        links: [
            { to: 'press', label: 'Presse-Medien' },
            { to: 'privacy', label: 'Datenschutz' },
            { to: 'terms', label: 'Bestimmungen' },
        ],
        icon: {
            type: 'pegi',
            src: '/pegi16.jpg',
            alt: 'Ausstehende Altersfreigabe (PEGI 16)',
        },
        termsMessage: 'KRAFTON, Inc. Alle Rechte vorbehalten.',
    },

    languageToggle: {
        questionText: 'Möchtest du die Sprache ändern zu <strong class="c-blonde">{LANG}</strong>',
        yesLabel: 'Ja',
        noLabel: 'Nein',
    },

    error: {
        seo: {
            title: 'Leider wurde die gewünschte Seite nicht gefunden.',
            description: 'Thunder Tier One is a realistic top-down shooter where the player will join an elite task force fighting enemy operatives. Players must utilize all their tactical skills in real time to neutralize enemy combatants and achieve mission objectives.',
        },
        heading: 'Leider wurde die gewünschte Seite nicht gefunden.',
    },
}
