import discord from './discord.svg'
import globe from './globe.svg'
import krafton from './krafton.svg'
import arrowRight from './arrow-right.svg'
import play from './play.svg'
import menu from './menu.svg'
import close from './close.svg'
import facebook from './facebook.svg'
import twitter from './twitter.svg'
import instagram from './instagram.svg'
import crosshair from './crosshair.svg'

export default {
    discord,
    globe,
    krafton,
    arrowRight,
    play,
    menu,
    close,
    facebook,
    twitter,
    instagram,
    crosshair,
}
