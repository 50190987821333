import React from 'react'
import { Helmet } from 'react-helmet-async'
import htmlParser from 'react-html-parser'

const Meta = ({
    seo,
    ISO,
    hreflang = [],
}) => (
    <Helmet>
        { seo?.title && <title>{ htmlParser(seo.title) }</title> }
        { seo?.description && <meta name="description" content={ htmlParser(seo.description) }></meta> }
        { hreflang && hreflang.map(({ rel, href, hrefLang }, i) => (
            <link { ...{ key: i, rel, href, 'href-lang': hrefLang } } />
        )) }

        <meta property="og:site_name" content="Thunder Tier One" />
        <meta property="og:locale" content={ISO} />
        { seo?.title && <meta property="og:title" content={ htmlParser(seo.title) } /> }
        { seo?.description && <meta property="og:description" content={ htmlParser(seo.description) } /> }
        <meta property="og:image" content="/share-thumb.jpg" />
        <meta name="twitter:card" content="summary" />
    </Helmet>
)

export default Meta
