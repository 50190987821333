import { styles } from '../../../config'

const {
    variables,
    grid,
    reset,
    fonts,
} = styles

const { colors } = variables

export default {
    '@global': {
        ...reset,
        ...grid,
        ...fonts,

        body: {
            backgroundColor: colors.black,

            '&.menu-open': {
                overflow: 'hidden',
            },
        },

        '.c-blonde': {
            color: colors.blonde,
        },
    },
}
