export default {
    name: '简体中文',
    label: '简体中文',
    slug: 'zh-ch',
    ISO: 'zh_CN',

    socialLinks: [
        { type: 'Discord', to: 'https://discord.gg/thundertierone' },
        { type: 'Facebook', to: 'https://www.facebook.com/ThunderTierOne/' },
        { type: 'Twitter', to: 'https://twitter.com/thundertierone?lang=en' },
        { type: 'Instagram', to: 'https://www.instagram.com/thundertierone/' },
    ],

    home: {
        seo: {
            title: 'Thunder Tier One',
            description: 'Thunder Tier One is a realistic top-down shooter where the player will join an elite task force fighting enemy operatives. Players must utilize all their tactical skills in real time to neutralize enemy combatants and achieve mission objectives.',
        },

        hero: {
            discordLabel: '通过Discord加入',
            subscribeLabel: '订阅',
        },

        newsletter: {
            heading: {
                default: '订阅我们的新闻',
                success: '好样的做得好。特工',
            },
            inputPlaceholder: '电子邮箱',
            buttonText: '提交',
            checkboxLabel: 'KRAFTON可不定期向我发送电子邮件，以便通知我相关新闻和限时福利（可选）',
            readMore: {
                label: '阅读全文',
                text: '<strong>接收电子邮件</strong> KRAFTON, Inc.和/或其附属公司在您明确同意的前提下处理您的信息，以便提供与游戏和服务相关的电子邮件。即使您已同意对您的个人信息进行收集和处理，您仍有权随时撤回您的同意或选择不再接收此类电子邮件。',
                link: '详细信息',
            },
            messages: {
                success: 'You are subscribed.',
                error: '卫星上行链路错误，请重试',
                existing: '您已成功订阅。',
                missing: '请检查并重试',
            },
        },
    },

    press: {
        seo: {
            title: '关于《Thunder Tier One》 ',
            description: '《Thunder Tier One》是一款写实风格的俯视视角射击游戏，玩家将在游戏中加入一支精锐部队与敌方特工作战。为了减灭敌方战斗力，玩家必须实时调整战略战术技能达成任务目标。',
        },

        hero: {
            heading: '关于《Thunder Tier One》 ',
            description: '《Thunder Tier One》是一款写实风格的俯视视角射击游戏，玩家将在游戏中加入一支精锐部队与敌方特工作战。为了减灭敌方战斗力，玩家必须实时调整战略战术技能达成任务目标。',
            button: { to: '', label: 'thundertierone.com' },
        },

        about: {
            heading: '关于开发商',
            description: '《Thunder Tier One》是PUBG动作及枪战团队负责人Pawel Smolewski在2016年任职于Bohemia Interactive期间所启动的个人项目。他与同事Nelson Duarte以及Arseniy Smirnov分享了这个构想后，三人便开始着手开发这款游戏。然而，一年后开发工作因其他业务而搁置。2020年，团队再次向Krafton提案并着手重启项目。随后他们招募了Pedro Pinho、Jorge Aguiar、Lorenzo Salvadori、Ricardo Mota、Ricardo Sousa、Joao Rebelo和Michał Flodrowski来共同开发这款游戏。',
        },

        krafton: {
            description: 'KRAFTON（魁匠团）旗下拥有多个独立游戏开发团队，游戏作品包括《PUBG》、《ELYON》和《TERA》。公司名称源于核心价值观“匠人精神”，包含着制作游戏大作的匠人精神、不惧失败的挑战精神。KRAFTON目前由PUBG、Bluehole、RisingWings和Striking Distance四大工作室构成。更多关于KRAFTON的信息，请访问https://www.krafton.com。',
        },

        links: {
            left: { heading: '新闻/业务联系', email: 'mediarequests@krafton.com' },
            right: { heading: '新闻和素材', description: '阅读《Thunder Tier One》<a href="https://press.pubg.com/TTO" target="_blank" rel="noopener nofollow">的媒体资料和新闻</a>' },
        },
    },

    privacy: {
        heading: '《Thunder Tier One 隐私政策》',
        content: {
            default: `
                <p>生效日期：2021 年 12 月 7 日</p>

                <h2 id="简介">1.简介</h2>
                <p>KRAFTON, Inc. 和/或其关联方（以下称“KRAFTON”、“我们”、“我司”或“我方”）收集并处理您（以下称“您”、“用户”或“玩家”）的信息。我们向玩家提供视频游戏（以下称“游戏”）、官方网站（以下称“网站”）以及与游戏相关的服务（例如，活动、调查或客户服务等）（以下称“服务”)。本《隐私政策》（以下称“本《政策》”）阐明了我们在您与我方关系存续期间及之后如何根据适用的数据保护法收集、使用及共享您的个人信息。
                请务必阅读本《政策》以及我们将在收集或处理您的个人信息时在特定情况下提供的其他声明，以便了解我们使用此类个人信息的方式和原因，并且知晓您的权利。</p>

                <h2 id="我们收集的信息">2.我们收集的信息</h2>
                <p>我们收集有关您和您使用服务的信息。在与您订立合同关系或为您履行合同义务（例如，根据您的要求提供服务）时可能会需要有关信息，未提供任何信息可能会导致我们无法提供所要求提供的服务。我们收集的信息有三类：</p>

                <h3>1) 直接向您收集的信息</h3>
                <p>我们收集有关您和您使用服务的信息。在与您订立合同关系或为您履行合同义务（例如，根据您的要求提供服务）时可能会需要有关信息，未提供任何信息可能会导致我们无法提供所要求提供的服务。我们收集的信息有三类：</p>
                <ul>
                    <li>Steam ID（提供或运营游戏），以及</li>
                    <li>电子邮箱地址、居住国家/地区和交货信息（即：姓名/名称、邮政地址和电话号码）（用于参加测试、调查和其他活动）。</li>
                </ul>

                <p>当您使用社交功能（例如：游戏内聊天）时，您披露的信息为公开信息。我们可能会存储及访问该信息，并且其他用户可能会在未经您同意的情况下，阅读、复制、收集或使用该信息。</p>

                <h3>2) 自动收集的信息</h3>
                <p>在您玩我们的游戏和/或使用我们的服务时，我们会自动收集某些信息。我们自动收集的信息类型可能会发生变化，但通常包括：</p>

                <ul>
                    <li>设备信息（包括但不限于 IP 地址、设备 ID、设备型号、网络类型、操作系统版本、时区设置、电池状态、屏幕分辨率等）；</li>
                    <li>设备活动信息，包括崩溃报告和系统活动详细信息（例如，您在使用我们的服务时是否有遇到故障或无法访问互联网等），以及</li>
                    <li>您在使用我们的网站和/或服务时的浏览行为。</li>
                </ul>

                <h3>3) 从第三方处收集的信息</h3>
                <p>在您使用第三方平台访问我们的游戏和/或服务时，我们可能会在第三方平台条款以及您在该平台上的隐私设置允许的情况下，接收您在第三方平台上的某些个人资料/帐户信息。例如，Steam ID 等。</p>

                <h2 id="Cookie-和相关技术">3.Cookie 和相关技术</h2>
                <p>我们利用 Cookie 等技术来识别您的浏览器或设备，了解更多关于您的兴趣的信息，为您提供基本功能和服务以及用于其他用途。当您使用我们的网站或游戏时，经批准的第三方还可能会设置 Cookie 和相关技术。</p>

                <h3>1) Cookie</h3>
                <p>Cookie 是下载到您计算机中的小文件（如果您的浏览器允许接受 Cookie），用于跟踪网站范围内的活动以及对您在网站范围内的体验进行定制。大多数互联网浏览器都会允许您从计算机硬盘驱动器中删除 Cookie、阻止接受 Cookie 或者在存储 Cookie 之前收到警示信息。您可参阅浏览器说明或“帮助”页面，了解更多有关如何管理 Cookie 的信息。但请注意，如果您阻止 Cookie，则网站的某些部分可能无法正常运行。</p>
                <p>我们无法控制第三方广告中的 Cookie，建议您查看广告方和/或广告服务的隐私政策，了解其有关使用 Cookie 等技术的信息。网站中出现的广告可能会由第三方广告公司发送给您。这些公司可能会使用您在本网站和其他网站的访问信息，以便在本网站和其他网站上提供您可能感兴趣的商品和服务广告。</p>

                <h3>2) 网站分析</h3>
                <p>我们使用本网站的分析服务，以便我们记录本网站的使用信息以及不断改进我方产品。</p>
                <p>本网站使用由 Google Inc.（以下称“Google”）提供的网站分析服务 Google® Analytics。我们可能还会利用 Google® Analytics 的以下广告功能并收集由 Google 提供的个人信息，您可以在 Google 广告设置中禁用该等功能，撤回您给予的同意：Google® Analytics 再营销；Google® 展示广告网络曝光报告；Google® Analytics 人口统计和兴趣报告；以及允许利用广告 Cookie 和匿名 ID 通过 Google® Analytics 收集数据的集成服务。</p>

                <p>更多有关 Google 自身隐私政策的信息，请访问 <a href="https://www.google.com/policies/privacy" target="_blank" rel="noreferrer">https://www.google.com/policies/privacy</a></p>

                <p>如果您想要禁止 Google Analytics 使用您的信息或数据，您可以通过以下链接下载 Google Analytics Opt-out Browser Add-on：<a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout</a></p>

                <h3>4) 反作弊和防欺诈技术</h3>
                <p>我们努力为所有玩家提供安全、公平的游戏环境。当您玩游戏时，我们或第三方可能会使用 Cookie 和类似技术，或者会收集您的装置或设备数据，以用于防欺诈、安全和身份验证目的。我们还可能会在我们的服务中使用其他反作弊技术。</p>

                <h2 id="我们如何使用您的信息">4.我们如何使用您的信息</h2>
                <p>我们将所收集的信息用于多种目的，主要用于向玩家（例如：您）提供游戏和服务（统称为“游戏服务”）以及改进游戏服务质量。这些目的包括：</p>

                <ul>
                    <li>运营及提供游戏服务；</li>
                    <li>提供参与游戏服务相关活动或调查的机会；</li>
                    <li>解决技术问题，改善游戏服务质量（例如，为用户提供逼真的游戏环境）；</li>
                    <li>识别个人玩家，提供游戏服务；</li>
                    <li>提供技术保护，防止使用未经授权的程序；</li>
                    <li>防止以可能会伤害其他玩家的不当玩法玩游戏；</li>
                    <li>防止、检测、确认、调查以及应对潜在或实际的索赔、责任、禁止行为和犯罪活动。</li>
                </ul>

                <p>在大多数情况下，我们需要处理您的个人信息，以便对您做出回应或处理您的请求。我们还可能会为了遵守相关法定义务而处理您的信息。我们可能会出于某些具体原因，不时征求您同意我们使用您的信息。您可以根据本《政策》所载地址联系我方，随时撤回您的同意，而不会影响撤回之前我方基于同意所采取的处理行为的合法性。</p>

                <h2 id="共享您的信息">5.共享您的信息</h2>
                <p>我们向第三方（包括为了提供客户支持以及管理我们的社区，而代表我们处理个人信息的服务提供商）授予许可。这些第三方的示例包括：</p>

                <ul>
                    <li>阻止作弊工具和未授权程序的第三方程序限制提供商；</li>
                    <li>提供云存储服务的云计算服务提供商；</li>
                    <li>为了改进游戏而涉及的在线调查服务提供商。</li>
                </ul>

                <p>我们还会在为了遵守法定义务或合法数据访问要求而有必要进行披露的情况下，根据合并情况，出于本《政策》所述之目的，与我们的关联方共享您的信息，或者在您以其他方式表示同意或酌情决定的情况下共享您的信息。</p>

                <h2 id="国际数据传输">6.国际数据传输</h2>
                <p>我们在全球开展业务，并将您的数据传输至您所在国家和地区以外的其他国家和地区。如果您的个人信息由我们进行传输、存储或处理，我们会采取合理措施保护个人信息隐私。对于某些国家/地区，我们会在某些区域处理数据（详情如下）。</p>

                <h2 id="保留与删除个人信息">7.保留与删除个人信息</h2>
                <p>原则上，我们会在实现个人信息收集目的后第一时间删除您的个人信息。以实物形式存储的个人信息将被粉碎或烧毁，以电子形式存储的个人信息将采用技术措施以信息无法复制的方式删除。但是，如果为了遵守我们的法定义务，满足监管要求，解决纠纷，防止欺诈、欺骗或滥用行为，或履行本《政策》或我们可能与玩家订立的任何其他协议的目的，而有合理必要保留您的信息，那么，即使您已关闭在我方注册的帐户或者我们已经停止为您提供服务，我们仍可保留您的信息。</p>

                <h2 id="我们有关儿童的政策">8.我们有关儿童的政策</h2>
                <p>未经可核实的家长同意或未经法律准许，我们不会有意收集、使用或共享儿童（在美国通常指未满 13 周岁的孩子，或者以您所居住的国家/地区规定的儿童年龄为准）的任何信息。如果您是家长或监护人，并且认为您的孩子已向我们提供信息，则您可以与我们联系，并要求从我们的系统中删除您孩子的信息。</p>
                
                <h2 id="链接和第三方服务">9.链接和第三方服务</h2>
                <p>如果您点击第三方网站链接，包括但不限于广告，则您会离开本网站，跳转至您选择的网站。由于我们无法控制第三方活动，因此，对于该等第三方使用您的个人信息的行为，我们概不负责，同时我们无法保证他们会遵循与我们相同的隐私与安全惯例。我们鼓励您查看您要求其提供服务的任何其他服务提供商的隐私政策。如果您访问与本网站相链接的第三方网站，则应在提供任何个人信息之前参阅该网站的隐私政策。</p>

                <h2 id="您的信息安全">10.您的信息安全</h2>
                <p>我们已采取适当的安全措施（包括加密和去身份识别化)，防止您的个人信息被意外泄露、以未经授权的方式被使用或访问、更改或披露。此外，我们将您的个人信息访问权限限于那些因业务而需要收悉的员工、代理等。他们只会按照我们的指示处理您的个人信息，并且他们受保密义务约束。我们有安全程序来处理可疑的数据安全漏洞，并且会在法律要求我们通知的情况下，通知您和任何适用监管机构关于可疑漏洞的情况。但对于尽管采取了这些安全措施和程序而还有可能出现的任何问题（例如，您的疏忽或互联网问题），我们概不负责。请注意，通过互联网传输数据并非 100% 安全，因此无法保证我们收集和使用的任何信息均完全安全。</p>

                <h2 id="您的隐私权">11.您的隐私权</h2>
                <p>根据适用法律的限制性规定，您有权反对或要求限制处理您的个人信息，并有权要求访问、更正、删除以及迁移您自己的个人信息。如需提出与您的权利有关的申请，或者您有任何其他疑问，请发送电子邮件至“13. 联系我们”一节中载明的地址，与我们联系。我们会对希望根据适用数据保护法行使其数据保护权的个人提出的所有申请做出响应。</p>

                <h2 id="修改">12.修改</h2>
                <p>我们可通过在网站中公布或展示修改后的本《政策》，随时酌情修改本《政策》的任何部分。一经继续使用服务，您将被视为已接受该等修改，因此，请经常对其进行查看。</p>

                <h2 id="联系我们">13.联系我们</h2>
                <p>如果您对本《政策》或您与本网站的往来有任何疑问，请通过以下沟通渠道之一，联系我们。</p>

                <ul>
                    <li>电子邮件：<a href="mailto:privacy@krafton.com" target="_blank" rel="noreferrer">privacy@krafton.com</a></li>
                    <li>地址：Centerfield East Tower, 231, Teheran-ro, Gangnam-gu, Seoul, Republic of Korea</li>
                </ul>

                <p>本《隐私政策》已翻译成其他语言，方便您使用。如果英文版与其他版本有不符之处，请以英文版为准。但韩国玩家应以韩文版《隐私政策》为准。</p>

                <h2 id="补充条款–特定司法管辖区">14.补充条款 – 特定司法管辖区</h2>
                <p>对于与您在玩我们的游戏或使用我们的服务时所在的司法管辖区相关的《补充条款 – 特定司法管辖区》，如果与本政策的其余部分发生冲突，则相关司法管辖区的《补充条款 – 特定司法管辖区》将取代这些部分并具有更高效力。</p>

                <h3>美国</h3>
                <h4>加利福尼亚州</h4>
                <p>如果您是加州居民，您可能还享有其他权利。如需获取有关您的隐私权的更多信息，请查看我们的 <a href="/en/privacy/california">《加 州居民隐私声明》。</a></p>

                <h4>内华达州</h4>
                <p>如果您是内华达州居民，我们不会出售所收集的有关您的个人信息，但是，如果您是向我们购买了游戏物品或商品的内华达州居民，您可以提交请求，让我们记录您的偏好，以便在将来选择停用。</p>

                <h3>EEA、瑞士和英国</h3>
                <p>如果您居住在 EEA、瑞士和英国，您可能拥有其他权利。</p>

                <h4>国际数据传输</h4>
                <p>如果我们将您的数据传输至 EEA 以外的国家/地区，并且欧盟委员会认为这些国家/地区无法确保足够的数据保护水平，则我们会根据适用的数据保护法和隐私法采取适当措施，保护您的个人数据。这些保护措施可包括实施标准合同条款的数据传输协议（为了向个人数据提供足够的保护措施，而由欧盟委员会预先批准的一种数据传输协议）。您可以按照本《政策》所载方式联系我们，获取该等适当措施的副本。我们在征得同意的情况下、为了履行与您订立的合同、或为了以不超过您的权利与自由的方式实现必要的合法权益，而以其他方式传输个人数据。如果您对本《政策》有任何疑问，您可以联系我们的数据保护官 (<a href="mailto:DPO@pubg.com" target="_blank" rel="noreferrer">DPO@pubg.com</a>)。</p>
            `,
        },
    },

    terms: {
        heading: 'Thunder Tier One官方网站服务条款',
        content: `
            <p>生效日期：2020年12月10日</p>

            <p>KRAFTON, Inc.（以下称“KRAFTON”或“我们”）通过其官方网站（OOO）提供游戏相关服务（以下统称“服务”）。本服务条款就服务使用管辖您与KRAFTON之间的关系（以下称“条款”），而“用户”或“您”指同意本条款之人。使用本服务即表示您接受并同意受本条款约束及遵守本条款，如您不愿接受本条款，则您不得使用本服务。 </p>
            
            <h2>1.更新服务与条款</h2>
            <ol>
                <li>1) 本服务条款可随时在KRAFTON网站找到。</li>
                <li>2) 在遵守适用法律法规的范围内，如有必要，KRAFTON可能随时变更本条款。</li>
                <li>3) 您有责任定期查阅本页面以获知任何变更。您继续使用服务即表示您接受并同意本条款的变更。</li>
            </ol>
            
            <h2>2.违禁使用</h2>
            <p>KRAFTON仅针对个人、非商业或娱乐用途向用户提供本服务。您同意不违反本条款中所订立的条款与条件。违反本协议中的任何准则均可能导致根据本条款而提供的服务暂时性或永久性中止。您同意仅以符合本条款的方式使用服务或其中的任何部分，并且您不得：</p>
            <ul>
                <li>(a) 未经KRAFTON书面同意，以任何方式对本服务或其中的任何部分进行商业性质的使用、宣传或恶意利用，包括但不限于在网咖（网吧）、电脑游戏中心或任何其他基于位置的场地使用；</li>
                <li>(b) 对本服务的全部或部分进行反向工程、派生源代码、修改、反编译、反汇编或创建派生作品（除非适用法律明确允许，在这种情况下，所有及任何合法修改、改编、改进等，以及其中的所有版权和道德权利，在任何情况下均应视作转让给且应属于、授予KRAFTON和/或其授权方并成为其独占财产）；</li>
                <li>(c) 移除、禁用或规避任何安全保护措施或任何控制对本服务之访问权限的技术措施；</li>
                <li>(d) 移除、修改、毁坏或规避本服务上或其内部包含的任何所有权通告或标签；</li>
                <li>(e) 窃取和/或发布从他人处得到的信息（包括但不限于个人信息、非个人信息和支付信息）；</li>
                <li>(f) 冒充KRAFTON或我们的员工；</li>
                <li>(g) 损害KRAFTON、我们的附属公司、我们的服务提供商和授权方的声誉；</li>
                <li>(h) 发布、链接至、宣传、推广或传播任何非法、有害、威胁、辱骂、骚扰、侵权、诽谤、粗俗、色情、淫秽、仇恨、种族或民族性质的攻击性内容；</li>
                <li>(i) 以任何方式恶意利用他人而获利；</li>
                <li>(j) 以非法的方式使用本服务，或使用本服务的方式对提供本服务产生负面影响；</li>
                <li>(k) 以非法或超出本服务预期用途范围（由本公司自行判断）的任何目的访问或使用本服务；</li>
                <li>(l) 对他人不尊重或使用冒犯性字词，包括基于他人的种族、性别、国籍；</li>
            </ul>
            <p>在情况得到审慎审查后，任何未在本条款中定义的、对KRAFTON服务的提供或对其他用户产生负面影响的行为均可能会受到警告和处罚。</p>
            
            <h2>3.访问本服务</h2>
            <p>原则上全年每天24小时提供本服务。然而，影响本服务可访问性的有：KRAFTON在任何KRAFTON认为适于启动更新或技术维护的期间内将本服务全部或部分关闭，或KRAFTON自行判断为必要的任何其他行动。</p>
            <p>我们可能在不另行通知的情况下修改服务或限制所有或特定用户的访问，且不承担任何责任。此外，本服务可能因地理位置而不可用。</p>
            
            <h2>4.所有权</h2>
            <p>您同意并承认与本服务及其任何及所有副本有关的所有权利、所有权和知识产权（包括但不限于任何衍生作品、标题、计算机代码、主题、物品、角色、角色名称、故事、对话、口号、地点、概念、艺术作品、图形、动画、声音、音乐作品、视听效果、文本、屏幕显示、操作方法、道德权利及任何相关文件）均归KRAFTON或其授权方所有。</p>
            
            <h2>5.责任限制与赔偿</h2>
            <p>对于您在使用本服务时遭受的您和我们均无法合理预见的任何损害或损失，包括因我们违反本条款而造成的损害或损失，KRAFTON概不负责。</p>
            <p>如发生系统故障、维护或维修，或由于超出我们合理控制的原因，本服务可能会在不另行通知的情况下暂时中止。除非本条款中另有规定，否则我们将不会在本服务的全部或部分以任何方式不可用、中断、修改或变更的情况下，对您或任何其他人承担责任。</p>
            <p>KRAFTON对以下事项不承担任何责任：</p>
            <ul>
                <li>(a) 您在并未作为消费者签订本条款时遭受到的任何损害或损失；</li>
                <li>(b) 任何与不可抗力事件相关或因而导致的对游戏、服务或任何内容的访问失败、中止和/或终止。在本条款中，“不可抗力”事件包括但不限于罢工、停业或其他劳工冲突，核事故或天灾，战争或恐怖活动，暴乱、民众骚乱、蓄意破坏，遵守任何法律或政府命令、规章制度或指令，工厂或机械事故或故障，火灾、洪水、风暴或供应商违约，以及超出我们合理控制的任何其他行为、事件、疏忽或事故；及/或</li>
                <li>(c) 第三方对您提出的本条款规定之外的任何索赔。</li>
                <li>(d) 由于用户失误而导致本服务的中断、本服务使用的中断或本合同的终止。</li>
                <li>(e) 电信运营商停止或无法提供电信服务。在电信服务失效期间发生的损失由用户承担。</li>
                <li>(f) 任何不可避免的导致服务失效的原因，如维护、更换、例行检查、施工等。</li>
                <li>(g) 由用户计算机引起的任何问题或与网络环境有关的问题。</li>
                <li>(h) 用户或第三方在本服务中或在KRAFTON网站上发布或传播的信息、数据、事实、可靠性、准确性等内容。</li>
                <li>(i) 由KRAFTON免费提供的服务而产生的损害。</li>
                <li>(j) 其他服务提供方造成的损害。</li>
            </ul>
            <p>本条款中任何内容均不：</p>
            <ul>
                <li>(a) 限制您作为消费者的法定权利（包括您提出或接受合理服务标准的权利，可从您所在当地的贸易标准局或公民咨询局获取有关此权利的更多详情）；或</li>
                <li>(b) 限制我们对因我们的过失而造成的死亡或人身伤害或对欺诈所承担的责任。</li>
            </ul>
            <p>使用本服务即表明，您同意就因您使用或滥用本服务以及违反KRAFTON政策而产生的或与之相关的任何索赔、诉讼、损害、损失、债务和费用（包括律师费）对KRAFTON进行辩护、保护、作出补偿并使其免受损害。KRAFTON保留对所有接受用户赔偿的事宜进行独家辩护和控制的权利。</p>
            <p>在适用法律允许的最大范围内，在任何情况下，KRAFTON均不对任何间接、附带、结果性、特殊、惩戒性或惩罚性的损害（包括但不限于业务损失、数据丢失、业务权利损失、利润损失）及由于您使用或滥用本服务而引起的损害（包括但不限于作业操作中断、硬件故障，或其他商业损害或损失）负责。无论KRAFTON是否已知或难以知晓损害发生的可能性，上述条款同样适用。</p>
            <p>本公司概不承担任何服务中断的责任，包括但不限于ISP故障、软件或硬件故障或任何其他可能导致数据丢失或服务中断的情况。部分司法管辖区不允许排除或限制附带或间接损害的责任，因此上述限制限于该等国家/地区或司法管辖区适用法律允许的最大范围内。</p>
            
            <h2>6.担保免责声明</h2>
            <p>本服务和内容均按“原样”提供，风险由您自行承担。在法律允许的最大范围内，KRAFTON特此明确否认以书面或口头、明示、默示或法定等任何形式的所有担保或条件，包括但不限于对权利、非侵权、适销性、平静受益权、准确性或针对特定目的的适用性的任何默示担保。</p>
            <p>在不限制上述任何规定的情况下，KRAFTON不保证其服务持续、无错误、安全或无病毒运行。部分州或国家/地区不允许就默示担保作出免责声明，前述免责声明可能不适用于您。本担保赋予您特定的法律权利，而因州或国家/地区而异，您也可能拥有其他法律权利。</p>
            
            <h2>7.期限与终止</h2>
            <p>本条款有效期直至您或KRAFTON终止本条款为止。KRAFTON和用户可在不事先通知的情况下随时终止本条款。由KRAFTON作出的终止将由KRAFTON自行决定生效时间，任意时间均可。您可以通过不使用本服务来终止本条款。第4条（所有权）、第5条（责任限制与赔偿）、第6条（担保免责声明）、第7条（期限与终止）、第8条（申诉处理与争议解决）、第9条（管辖法律）、第10条（集体诉讼豁免）和第12条（其他政策）在本条款终止后依然有效。</p>
            
            <h2>8.申诉处理与争议解决</h2>
            <p>如果您与KRAFTON之间产生争议，我们的目标是为您提供一个得以迅速解决争议的中立且经济的途径。因此，除非法律禁止以及在法律禁止的范围内，否则，使用服务即表明，在索赔金额低于10,000美元或同等价值的情况下，您和KRAFTON同意通过在本公司和您的同意下选择的双方商定的仲裁人在个案的基础上使用仲裁。在个案的基础上使用仲裁即表明，您将不具有且您放弃法官或陪审团判决您的仲裁的权利，并且您不得提起集体诉讼、合并诉讼或代理诉讼。您和我们在法庭上以其他方式享有的其他权利（包括发现权和上诉权），在仲裁中将无法行使或将受到更多限制。在双方商定仲裁人之前，所有此类争议应根据届时有效的仲裁规则，提交给选定的仲裁人进行有约束力仲裁。 </p>
            
            <h2>9.管辖法律</h2>
            <p>本条款由大韩民国法律进行解释和管辖。与本条款相关的所有诉讼均依据相关法律（如韩国民事诉讼法）受法院管辖。若您在大韩民国境外使用本服务，则其他法律可能适用。在这种情况下，适用的当地法律仅在适用管辖权的所需范围内影响本条款，且本条款应以使其条款与条件尽可能有效的方式进行解释。您必须在当地法律适用的范围内遵守当地法律。</p>
            
            <h2>10.集体诉讼豁免</h2>
            <p>您同意不参与（包括但不限于）与本服务或本协议有关的集体诉讼或代理诉讼、私人律师一般性诉讼或集体仲裁。此外，您同意在未经本协议各方同意的情况下，不会试图将与本服务或本协议相关的任何诉讼或仲裁与任何其他诉讼或仲裁与任何其他诉讼或仲裁进行合并。</p>
            
            <h2>11.版权声明</h2>
            <p>如果您认为来自KRAFTON和/或KRAFTON品牌网站的任何材料侵犯了您依据版权法受版权保护的作品，您可以向KRAFTON的指定代理人提供如下侵权通知。此外，为确保您通知的有效性，您必须纳入以下信息：</p>
            <ul>
                <li>(a) 足以使我们确定所述作品被侵权的详细信息；</li>
                <li>(b) 您的联系信息，包括您的地址和电子邮件地址；</li>
                <li>(c) 一份表明您真诚相信有争议作品未得到版权所有人、其代理人或法律授权的声明；</li>
                <li>(d) 一份表明在伪证处罚法律的约束下，通知中提供的信息是准确的，并且您已获授权代表据称被侵犯的专有权所有人行事的声明；以及</li>
                <li>(e) 您的亲笔签名或电子签名。</li>
            </ul>
            
            <p>本公司的指定代理人联系方式如下：</p>
            <ol>
                <li>KRAFTON, Inc.</li>
                <li>收件人：Legal Affairs Department Agent</li>
                <li>Tower, 117, Bundangnaegok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea. </li>
                <li>电子邮件：privacy@krafton.com</li>
            </ol>
            
            <h2>12.其他政策</h2>
            <ol>
                <li>1) 为保护用户利益和维持秩序，KRAFTON可能会使用隐私政策来涵盖服务条款中未涵盖的具体细节。</li>
                <li>2) 同意本服务条款中规定的条件，即表明您同样同意受到隐私政策中所规定的条款与条件的约束。</li>
                <li>3) 隐私政策可随时在KRAFTON网站找到。</li>
            </ol>
            
            <p>为方便用户阅读，本服务条款已翻译成其他语言。如英文版本与其他版本有任何差异，以英文版本为准。然而，韩国用户以韩国服务条款为准。</p>        
        `,
    },

    header: {
        links: [
            { to: 'press', label: '新闻包' },
        ],
    },

    menu: [
        { to: '', label: 'Thunder Tier One' },
        { to: 'press', label: '新闻包' },
        { to: 'privacy', label: '隐私' },
    ],
    
    footer: {
        links: [
            { to: 'press', label: '新闻包' },
            { to: 'privacy', label: '隐私' },
            { to: 'terms', label: '条款' },
        ],
        icon: {
            type: 'pegi',
            src: '/pegi16.jpg',
            alt: '分级待定（PEGI 16）',
        },
        termsMessage: 'KRAFTON, Inc。版权所有。',
    },

    languageToggle: {
        questionText: '要變更為該 <strong class="c-blonde">{LANG}</strong> 嗎',
        yesLabel: '是',
        noLabel: '否',
    },

    error: {
        seo: {
            title: '很抱歉，你要查找的网页找不到。',
            descripton: 'Thunder Tier One is a realistic top-down shooter where the player will join an elite task force fighting enemy operatives. Players must utilize all their tactical skills in real time to neutralize enemy combatants and achieve mission objectives.',
        },
        heading: '很抱歉，你要查找的网页找不到。',
    },
}
