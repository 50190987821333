import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { loadableReady } from '@loadable/component'
import { HelmetProvider } from 'react-helmet-async'
import { JssProvider } from 'react-jss'

import { generateId } from '../config'

import App from './containers/App'
import { CookiesProvider } from 'react-cookie'

delete window.__RELAY_BOOTSTRAP_DATA__

const ClientWrapper = ({ children }) => (
    <HelmetProvider>
        <JssProvider {...{ generateId }}>
            <CookiesProvider>
                <BrowserRouter>
                    { children }
                </BrowserRouter>
            </CookiesProvider>
        </JssProvider>
    </HelmetProvider>
)

loadableReady(() => {
    const r = ReactDOM.createRoot(document.getElementById('root'), { hydrate: true })

    r.render(
        <ClientWrapper>
            <App />
        </ClientWrapper>
    )

    if (module.hot && process.env.DEVELOPMENT) {
        module.hot.accept('./containers/App', () => {
            const { default: App } = require('./containers/App')

            r.render(
                <ClientWrapper>
                    <App />
                </ClientWrapper>
            )
        })
    }
})
