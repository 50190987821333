export default ({
    spaces,
    colors,
    breakpoints,
    typography,
}) => ({
    key: { name: 'Footer' },

    footer: {
        width: '100%',
        padding: spaces[4],
        backgroundColor: colors.black,
        color: colors.white,
    },

    top: {
        textAlign: 'center',

        [breakpoints.md]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'left',
        },
    },

    left: {
        flex: 1,
        marginTop: spaces[1],
        textAlign: 'center',
        
        '&:first-of-type': {
            marginTop: 0,
        },

        [breakpoints.md]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: 0,
            textAlign: 'left',
        },
    },

    right: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: spaces[3],
        ...typography.sm,
        textAlign: 'center',

        '&:last-of-type': {
            marginTop: spaces[1],
        },

        [breakpoints.md]: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 0,
            textAlign: 'right',

            '&:last-of-type': {
                marginTop: 0,
            },
        },
    },

    logo: {
        width: 160,
        margin: [0, 'auto'],

        [breakpoints.md]: {
            margin: 0,
        },
    },

    logos: {
        display: 'inline-flex',
        alignItems: 'center',
        marginBottom: spaces[1],

        [breakpoints.md]: {
            marginRight: spaces[2],
            marginBottom: 0,
        },

        [breakpoints.lg]: {
            marginRight: spaces[4],
        },
    },

    krafton: {
        width: 120,
        marginLeft: spaces[2],

        '& > div, & svg': {
            width: 120,
        },

        [breakpoints.md]: {
            marginLeft: spaces[2],
        },

        [breakpoints.lg]: {
            marginLeft: spaces[4],
        },
    },

    pegi16: {
        width: 42,
    },

    socialLink: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: spaces[3],
        height: spaces[3],
        marginLeft: spaces[0],
        textAlign: 'center',

        '&:first-child': {
            marginLeft: 0,
        },

        '&:hover': {
            '& .icon-fill': {
                fill: colors.blonde,
            },
        },
    },

    bottom: {
        marginTop: spaces[1],
        textAlign: 'center',

        [breakpoints.md]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: spaces[3],
            textAlign: 'left',
        },
    },

    links: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        [breakpoints.md]: {
            flexDirection: 'row',
        },
    },
    
    link: {
        display: 'inline-block',
        marginTop: spaces[1],
        paddingBottom: spaces[0]/4,
        position: 'relative',
        ...typography.sm,
        textTransform: 'uppercase',
        color: colors.white,

        '&:first-child': {
            marginTop: 0,
        },

        '&::after': {
            display: 'block',
            width: '100%',
            height: 1,
            position: 'absolute',
            top: '100%',
            left: 0,
            backgroundColor: colors.white,
            content: '""',
        },

        '&:hover': {
            color: colors.blonde,

            '&::after': {
                backgroundColor: colors.blonde,
            },
        },

        [breakpoints.md]: {
            marginTop: 0,
            marginLeft: spaces[2],

            '&:first-child': {
                marginLeft: 0,
            },
        },
    },
})
