import React, { useTransition } from 'react'
import { useHistory } from 'react-router-dom'

const Link = ({
    className,
    to,
    children,
    external = false,
    clickHandler,
}) => {
    const history = useHistory()
    const [ startTransition, isPending ] = useTransition({ timeoutMs: 2000 })

    return (
        <a
            href={ to === '/home/' ? '/' : to }
            className={className}
            onClick={ (e) => {
                if (typeof clickHandler === 'function') clickHandler()

                if ((!e.ctrlKey && !e.metaKey) && !external) {
                    e.preventDefault()
                    
                    if (!isPending) {
                        startTransition(() => {
                            history.push(to === '/home/' ? '/' : to)
                        })
                    }
                }
            } }
            { ...(external ? { target: '_blank', rel: 'noopener noreferrer' } : {}) }
        >{ children }</a>
    )
}

export default Link