import React from 'react'
import is from 'react-jss'
import cx from 'classnames'

import Link from '../Link'

import style from './style'

const Button = ({
    classes,
    link = null,
    size = 'md',
    clickHandler = null,
    className,
    color = '',
    disabled = false,
    children,
}) => {
    if (typeof clickHandler === 'function') {
        return (
            <button
                onClick={!disabled ? clickHandler : null}
                className={ cx(classes.button, classes[color], className, [classes[size]] || classes.md) }
                disabled={disabled}
            >{ children }</button>
        )
    }

    if (link) {
        return (
            <Link
                to={link}
                className={ cx(classes.button, classes[color], className, [classes[size]] || classes.md) }
                disabled={disabled}
                external={true}
            >{ children }</Link>
        )
    }

    return null
}

export default is(style)(Button)
