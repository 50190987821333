import React, { useState } from 'react';
import { useHeadingsData } from '../../helpers/useHeadingsData';
import { useIntersectionObserver } from '../../helpers/useIntersectionObserver';
import Headings from './Headings';

const TableOfContents = ({classes}) => {
    const [activeId, setActiveId] = useState();
    const { nestedHeadings } = useHeadingsData();
    useIntersectionObserver(setActiveId)
    return (
        <nav aria-label="Table of Contents">
            <Headings headings={nestedHeadings} activeId={activeId} />
        </nav>
    )
}

export default TableOfContents;