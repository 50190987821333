import React from 'react'
import is from 'react-jss'
import cx from 'classnames'
import SVG from 'react-inlinesvg'

import style from './style'
import icons from './icons'

const Icon = ({
    classes,
    name,
    color = 'aldo',
    size = 'md',
    src,
    clickHandler,
    className,
}) => (
    <div className={ cx(
        classes.icon, 
        classes[size],
        classes[color],
        className, {
            [classes[name]]: classes[name] !== undefined,
        },
    )}>
        <SVG
            src={src || icons[name]}
            onClick={ e => typeof clickHandler === 'function' && clickHandler({ e }) }
        />
    </div>
)

export default is(style)(Icon)
