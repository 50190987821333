export default ({
    spaces,
    colors,
    breakpoints,
}) => ({
    key: { name: 'LanguageSelector' },

    languageSelector: {
        position: 'relative',
    },

    options: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: [0, spaces[1]],
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translate3d(-50%, 0, 0)',
        backgroundColor: 'rgba(0, 0, 0, .85)',
        textAlign: 'center',
        borderRadius: 3,
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none',
        userSelect: 'none',
        transition: 'all ease-in-out .1s',

        '&.active': {
            transform: `translate3d(-50%, 0, 0) translateY(${spaces[0]}px)`,
            opacity: 1,
            visibility: 'visible',
            pointerEvents: 'initial',
        },

        [breakpoints.md]: {
            flexDirection: 'row',
            top: '50%',
            left: 'auto',
            right: `calc(100% + ${spaces[1]}px)`,
            transform: `translate3d(0, -50%, 0) translateX(${spaces[2]}px)`,

            '&.active': {
                transform: 'translate3d(0, -50%, 0)',
            },
        },
    },

    option: {
        padding: [0, spaces[0]],
        lineHeight: `${spaces[3]}px`,
        color: colors.boulder,
        whiteSpace: 'nowrap',

        '&.active': {
            color: colors.white,
        },

        '&:hover': {
            color: colors.blonde,
        },

        '&:first-child': {
            marginLeft: 0,
        },

        [breakpoints.md]: {
            height: spaces[3],
        },
    },
})
