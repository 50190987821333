export default ({
    spaces,
    colors,
}) => ({
    key: { name: 'Icon' },

    icon: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        pointerEvents: 'none',
        userSelect: 'none',
        verticalAlign: 'top',

        '& > div': {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            verticalAlign: 'middle',
        },

        '& svg': {
            verticalAlign: 'middle',
        },
    },

    mi: {
        width: spaces[0],
        height: spaces[0],

        '& > div, & svg': {
            width: spaces[0],
            height: spaces[0],
        },
    },

    sm: {
        width: spaces[1],
        height: spaces[1],

        '& > div, & svg': {
            width: spaces[1],
            height: spaces[1],
        },
    },

    md: {
        width: spaces[2],
        height: spaces[2],

        '& > div, & svg': {
            width: spaces[2],
            height: spaces[2],
        },
    },

    lg: {
        width: spaces[3],
        height: spaces[3],

        '& > div, & svg': {
            width: spaces[3],
            height: spaces[3],
        },
    },

    xl: {
        width: spaces[4],
        height: spaces[4],

        '& > div, & svg': {
            width: spaces[4],
            height: spaces[4],
        },
    },

    white: {
        '& .icon-fill': {
            fill: colors.white,
        },
    },

    bare: {
        '& .icon-fill': {
            fill: colors.bare,
        },
    },

    gallery: {
        '& .icon-fill': {
            fill: colors.gallery,
        },
    },

    aldo: {
        '& .icon-fill': {
            fill: colors.aldo,
        },
    },

    silverEdge: {
        '& .icon-fill': {
            fill: colors.silverEdge,
        },
    },

    boulder: {
        '& .icon-fill': {
            fill: colors.boulder,
        },
    },

    black: {
        '& .icon-fill': {
            fill: colors.black,
        },
    },

    blonde: {
        '& .icon-fill': {
            fill: colors.blonde,
        },
    },
})
