export default {
    '@global': {
        html: {
            lineHeight: 1.15,
            '-webkit-text-size-adjust': '100%',
            boxSizing: 'border-box',
            fontFamily: 'sans-serif',
        },

        body: {
            margin: 0,
            overscrollBehaviorY: 'none',
        },

        main: {
            display: 'block',
        },

        hr: {
            boxSizing: 'content-box',
            height: 0,
            overflow: 'visible',
        },

        pre: {
            fontFamily: 'monospace, monospace',
            fontSize: '1em',
        },

        a: {
            backgroundColor: 'transparent',
        },

        'abbr[title]': {
            borderBottom: 'none',
            textDecoration: 'underline dotted',
            '-webkit-textDecoration': 'underline dotted',
        },

        'b, strong': {
            fontWeight: 700,
        },

        'code, kbd, samp': {
            fontFamily: 'monospace, monospace',
            fontSize: '1em',
        },

        small: {
            fontSize: '80%',
        },

        'sub, sup': {
            fontSize: '75%',
            lineHeight: 0,
            position: 'relative',
            verticalAlign: 'baseline',
        },

        sub: {
            bottom: '-0.25em',
        },

        sup: {
            top: '-0.5em',
        },

        img: {
            borderStyle: 'none',
        },

        'button, input, optgroup, select, textarea': {
            fontFamily: 'inherit',
            fontSize: '100%',
            lineHeight: 1.15,
            margin: 0,
        },

        'button, input': {
            overflow: 'visible',
        },

        'button, select': {
            textTransform: 'none',
        },

        'button, [type="button"], [type="reset"], [type="submit"]': {
            '-webkit-appearance': 'button',
        },

        'button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner': {
            borderStyle: 'none',
            padding: 0,
        },

        'button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring': {
            outline: '1px dotted ButtonText',
        },

        fieldset: {
            padding: ['0.35em', '0.75em', '0.625em'],
        },

        legend: {
            boxSizing: 'border-box',
            color: 'inherit',
            display: 'table',
            maxWidth: '100%',
            padding: 0,
            whiteSpace: 'normal',
        },

        progress: {
            verticalAlign: 'baseline',
        },

        textarea: {
            overflow: 'auto',
        },

        '[type="checkbox"], [type="radio"]': {
            boxSizing: 'border-box',
            padding: 0,
        },

        '[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button': {
            height: 'auto',
        },

        '[type="search"]': {
            '-webkit-appearance': 'textfield',
            outlineOffset: -2,
        },

        '[type="search"]::-webkit-search-decoration': {
            '-webkit-appearance': 'none',
        },

        '::-webkit-file-upload-button': {
            '-webkit-appearance': 'button',
            font: 'inherit',
        },

        details: {
            display: 'block',
        },

        summary: {
            isplay: 'list-item',
        },

        template: {
            display: 'none',
        },

        '[hidden]': {
            display: 'none',
        },

        '*, *::before, *::after': {
            boxSizing: 'inherit',
        },

        'blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre': {
            margin: 0,
        },

        button: {
            background: 'transparent',
            padding: 0,
        },

        'button:focus': {
            outline: '1px dotted',
            outline: '5px auto -webkit-focus-ring-color',
        },

        fieldset: {
            margin: 0,
            padding: 0,
        },

        'ol, ul': {
            listStyle: 'none',
            margin: 0,
            padding: 0,
        },
    },
}
