import { lazy } from 'react'

import promiseDelay from './promiseDelay'

const ReactLazyPreload = importStatement => {
    const Component = lazy(importStatement)
    Component.preload = importStatement
    return Component
}

const isServer = () => typeof window === "undefined" ? true : false

const throttle = (func, delay) => {
    let inProgress = false

    return (...args) => {
        if (inProgress) return

        inProgress = true

        setTimeout(() => {
            func(...args)
            inProgress = false
        }, delay)
    }
}

export {
    promiseDelay,
    ReactLazyPreload,
    isServer,
    throttle,
}