export default ({
    spaces,
    colors,
}) => ({
    key: { name: 'IconButton' },

    btn: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.black,
        textAlign: 'center',
        color: colors.white,
        cursor: 'pointer',
        transition: 'background-color .25s ease, color .25s ease',
        '-webkit-mask-image': 'url(/texture-mask2.png)',

        '& .icon-fill': {
            transition: 'fill .25s ease',
        },

        '&.disabled': {
            pointerEvents: 'none',
        },

        '&:hover': {
            background: colors.white,
            color: colors.black,

            '& .icon-fill': {
                fill: colors.black,
            },
        },
    },

    sm: {
        width: spaces[3],
        minWidth: spaces[3],
        height: spaces[3],
        borderRadius: spaces[3],
    },

    md: {
        width: spaces[4],
        minWidth: spaces[4],
        height: spaces[4],
        borderRadius: spaces[4],
    },

    lg: {
        width: spaces[5],
        minWidth: spaces[5],
        height: spaces[5],
        borderRadius: spaces[5],
    },

    white: {
        background: colors.white,
        color: colors.black,

        '& .icon-fill': {
            fill: colors.black,
        },

        '&:hover': {
            background: colors.black,
            color: colors.white,

            '& .icon-fill': {
                fill: colors.white,
            },
        },
    },

    yellow: {
        background: colors.blonde,
        color: colors.white,

        '& .icon-fill': {
            fill: colors.white,
        },

        '&:hover': {
            background: colors.white,
            color: colors.blonde,

            '& .icon-fill': {
                fill: colors.blonde,
            },
        },

        '& $icon': {
            width: spaces[3],
        },
    },

    icon: {
        width: spaces[2]*1.3,
    }
})
