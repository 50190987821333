export default ({
    spaces,
    breakpoints,
    typography,
    colors,
}) => ({
    key: { name: 'Default' },

    wrapper: {
        padding: [110+spaces[3], 0, spaces[3]],

        [breakpoints.lg]: {
            padding: [110 + spaces[5], 0, spaces[5]],
        },
    },

    heading: {
        margin: [0, 0, spaces[3]],
        ...typography.t1,
        color: colors.white,
    },

    content: {
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            margin: [spaces[3], 0, spaces[2]],
        },

        '& h1': {
            marginTop: 0,
        },
    
        '& h1, & h2': {
            ...typography.t1,
            color: colors.white,
        },
    
        '& h3, & h4': {
            ...typography.t2,
            color: colors.white,
        },
        
        '& h5, & h6': {
            ...typography.t3,
            color: colors.white,
        },
    
        '& ul, & ol': {
            padding: 0,
            margin: 0,
            listStyle: 'none',
            marginBottom: spaces[2],
        },
    
        '& li': {
            marginBottom: spaces[1],
            paddingLeft: spaces[2],
            position: 'relative',
            ...typography.md,
            color: colors.white,

            [breakpoints.sm]: {
                ...typography.sm,
            },
            
            '&:last-child': {
                marginBottom: 0,
            },
            
            '&::before': {
                display: 'block',
                width: spaces[0]/2,
                height: spaces[0]/2,
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translate(0, -50%)',
                borderRadius: spaces[0]/2,
                backgroundColor: colors.blonde,
                content: '""',
            },
        },
    
        '& p': {
            marginBottom: spaces[2],
            ...typography.md,
            
            [breakpoints.sm]: {
                ...typography.sm,
            },
    
            '&:last-of-type, &:last-child': {
                marginBottom: 0,
            },

            '&:first-child': {
                marginBottom: spaces[2],
            },
        },
    
        '& strong': {
            fontWeight: 700,
        },
    
        '& a': {
            color: colors.blonde,
        },

        '& table': {
            width: '100%',
            borderCollapse: 'collapse',
        },

        '& th, & td': {
            padding: spaces[1],
            ...typography.md,
            color: colors.white,
            border: `1px solid ${colors.white}`,
            
            [breakpoints.sm]: {
                ...typography.sm,
            },
        },
    },
})
