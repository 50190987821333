export default ({
    spaces,
    typography,
    colors,
    breakpoints,
}) => ({
    key: { name: 'Menu' },

    menu: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        padding: spaces[4],
        position: 'fixed',
        top: 0,
        left: 0,
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, .85)',
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none',
        userSelect: 'none',
        transition: 'all ease-in-out .175s',
        zIndex: 11,
        
        '&.open': {
            opacity: 1,
            visibility: 'visible',
            pointerEvents: 'auto',
        },
    },
    
    items: {
        padding: 0,
        margin: 0,
        listStyle: 'none',
    },

    item: {
        margin: [spaces[1], 0, 0],

        '&:first-child': {
            marginTop: 0,
        },

        [breakpoints.sm]: {
            margin: [spaces[2], 0, 0],
        },

        [breakpoints.md]: {
            margin: [spaces[3], 0, 0],
        },
    },

    link: {
        ...typography.t2,
        color: colors.white,
        
        '&:hover': {
            color: colors.blonde,
        },

        [breakpoints.sm]: {
            ...typography.t1,
        },
    },

    toggle: {
        '&.open': {
            position: 'fixed',
            top: spaces[2]+1,
            right: spaces[2],
            zIndex: 11,
        },
    },
})
