export default ({
    spaces,
    typography,
    fontstacks,
    colors,
    breakpoints,
}) => ({
    key: { name: 'Header' },

    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: 110,
        padding: spaces[2],
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9,

        '&.scrolling': {
            '&::before': {
                opacity: 1,
                visibility: 'visible',
            },
        },
        
        '&::before': {
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            background: 'linear-gradient(180deg, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, 0) 100%)',
            opacity: 0,
            visibility: 'hidden',
            transition: 'all ease-in-out .25s',
            pointerEvents: 'none',
            content: '""',
            zIndex: 9,
        },
    },

    left: {
        flex: 1,
        position: 'relative',
        zIndex: 10,
    },

    logoImage: {
        width: 140,
        height: 'auto',

        [breakpoints.sm]: {
            width: 200,
        },
    },

    middle: {
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        position: 'relative',
        zIndex: 10,

        [breakpoints.md]: {
            display: 'flex',
        },
    },

    item: {
        padding: [spaces[1], 0],
        overflow: 'hidden',
    },

    itemLink: {
        padding: [spaces[1], 0],
        position: 'relative',
        ...typography.lg,
        ...fontstacks.secondary,
        '-webkit-mask-image': 'url(/texture-mask2.png)',
        fontWeight: 700,
        textTransform: 'uppercase',
        color: colors.white,
        overflow: 'hidden',

        '&::after': {
            display: 'block',
            width: '100%',
            height: 2,
            position: 'absolute',
            bottom: 4,
            left: 0,
            transform: 'translate3d(-105%, 0, 0)',
            backgroundColor: colors.white,
            pointerEvents: 'none',
            transition: 'all ease-in-out .175s',
            content: '""',
        },

        '&:hover': {
            '&::after': {
                transform: 'translate3d(0, 0, 0)',
            },
        },
    },

    right: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'relative',
        zIndex: 10,
        textAlign: 'right',
    },

    menuToggle: {
        marginLeft: spaces[1],
        
        [breakpoints.sm]: {
            marginLeft: spaces[2],
        },
    },
})
