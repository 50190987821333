export default ({
    buttons,
}) => ({
    key: { name: 'Button' },

    button: {
        ...buttons.base,
    },

    mi: {
        ...buttons.mi,
    },

    sm: {
        ...buttons.sm,
    },

    md: {
        ...buttons.md,
    },

    white: {
        ...buttons.white,
    },

    yellow: {
        ...buttons.yellow,
    },
})
