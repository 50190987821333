import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import translations from '../../../config/translations'

import Meta from '../../components/Meta'
import Header from '../../components/Header'
import Icon from '../../components/Icon'
import Footer from '../../components/Footer'

import style from './style'
import TableOfContents from '../../components/TableOfContents'

const Privacy = ({
    classes,
    california = false,
    content: {
        slug: locale,
        ISO,
        socialLinks,
        menu,
        header,
        privacy: {
            seo,
            heading,
            content,
        },
        footer,
    },
}) => {
    const hreflang = translations.map((translation, i) => ({
        rel: ISO !== translation.ISO ? 'alternate' : '',
        href: `/${translation.slug}/privacy`,
        hrefLang: translation.ISO,
    }))

    return (
        <div className={classes.privacy}>
            <Meta {...{ seo, hreflang, ISO }} />
            <Header {...{ ISO, locale, header, links: menu }} />

            <div className={classes.wrapper}>
                <div className="container">
                    <div className="row">
                        <div className="col-mi-12">
                            {locale === 'en' && (
                                <Link to={california ? '/en/privacy' : '/en/privacy/california'} className={cx(classes.toggle, { california })}>
                                    {california ? (
                                        <span>Show Privacy Notice for Residents Outisde of California</span>
                                    ) : (
                                        <span>Show Privacy Notice for California Residents</span>
                                    )}
                                    <Icon name="arrowRight" size="mi" color="blonde" className={classes.arrow} />
                                </Link>
                            )}
                            <h1 className={classes.heading}>{california ? heading.california : heading.default ? heading.default : heading}</h1>
                            <div className={classes.toc}>
                                <TableOfContents />
                            </div>
                            <div className={classes.content}>{htmlParser(california ? content.california : content.default)}</div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer {...{ locale, socialLinks, ...footer }} />
        </div>
    )
}

export default is(style)(Privacy)
