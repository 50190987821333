export default {
    '@font-face': [
        {
            fontFamily: 'Glorynight',
            src: [
                `url(/fonts/glorynight.woff) format('woff')`,
                `url(/fonts/glorynight.woff2) format('woff2')`,
            ],
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontDisplay: 'block',
        },
    ]
}
