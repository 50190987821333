export default ({
    spaces,
    breakpoints,
    typography,
    colors,
}) => ({
    key: { name: 'Error' },

    error: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 340px)',
        padding: [110+spaces[3], 0, spaces[3]],
        textAlign: 'center',

        [breakpoints.md]: {
            minHeight: 'calc(100vh - 110px - 100px)',
            padding: [110 + spaces[5], 0, spaces[5]],
        },

        [breakpoints.lg]: {
            padding: [110 + (spaces[5]*2), 0, spaces[5]*2],
        },
    },

    heading: {
        ...typography.t1,
        textTransform: 'uppercase',
        fontWeight: 700,
        color: colors.white,
    },

    description: {
        marginTop: spaces[2],
        ...typography.lg,

        [breakpoints.md]: {
            marginTop: spaces[3],
        },
    },
})
