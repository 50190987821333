import React from 'react'
import cx from 'classnames'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import translations from '../../../config/translations'

import Meta from '../../components/Meta'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import style from './style'

const Terms = ({
    classes,
    content: {
        slug: locale,
        ISO,
        socialLinks,
        menu,
        header,
        languageSuggestion,
        terms: {
            seo,
            heading,
            content,
        },
        footer,
    },
}) => {
    const hreflang = translations.map((translation, i) => ({
        rel: ISO !== translation.ISO ? 'alternate' : '',
        href: `/${translation.slug}/privacy`,
        hrefLang: translation.ISO,
    }))

    return (
        <div className={classes.terms}>
            <Meta { ...{ seo, hreflang, ISO } } />
            <Header { ...{ ISO, locale, header, links: menu } } />
            
            <div className={classes.wrapper}>
                <div className="container">
                    <div className="row">
                        <div className="col-mi-12">
                            <h1 className={classes.heading}>{ heading }</h1>
                            <div className={classes.content}>{ htmlParser(content) }</div>
                        </div>
                    </div>
                </div>
                </div>

            <Footer { ...{ locale, socialLinks, ...footer } } />
        </div>
    )
}

export default is(style)(Terms)
