import React from 'react'
import loadable from '@loadable/component'
import is from 'react-jss'

import translations from '../../../config/translations'
import { isServer, ReactLazyPreload } from '../../../client/helpers'

import Meta from '../../components/Meta'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import style from './style'

const ImageHero = isServer() ? loadable(() => import('../../components/ImageHero'), { ssr: true }) : ReactLazyPreload(() => import('../../components/ImageHero'))
const Newsletter = isServer() ? loadable(() => import('../../components/Newsletter'), { ssr: true }) : ReactLazyPreload(() => import('../../components/Newsletter'))

const Home = ({
    classes,
    content: {
        slug: locale,
        ISO,
        socialLinks,
        menu,
        header,
        languageSuggestion,
        home: {
            seo,
            hero,
            newsletter,
        },
        footer,
    },
}) => {
    const hreflang = translations.map((translation, i) => ({
        rel: ISO !== translation.ISO ? 'alternate' : '',
        href: `/${translation.slug}`,
        hrefLang: translation.ISO,
    }))

    return (
        <div className={classes.home}>
            <Meta { ...{ seo, hreflang, ISO } } />
            <Header { ...{ ISO, locale, header, links: menu } } />
            <ImageHero { ...{ socialLinks, ...hero } } />
            <Newsletter { ...{ locale, ...newsletter } } />
            <Footer { ...{ locale, socialLinks, ...footer } } />
        </div>
    )
}

export default is(style)(Home)
