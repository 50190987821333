import React, { useEffect } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router'
import TagManager from 'react-gtm-module'
import cx from 'classnames'
import is, { ThemeProvider } from 'react-jss'

import translations, { getLocaleBase } from '../../../config/translations'
import { styles } from '../../../config'

import { useBrowserState } from '../../context'

import Normalize from '../Normalize'
import Home from '../Home'
import Press from '../Press'
import Privacy from '../Privacy'
import Terms from '../Terms'
import Error from '../Error'

import style from './style'

const { variables } = styles

const base = getLocaleBase()

const Root = ({
    classes,
    location,
    ...props
}) => {
    const { browser, isMobile, isMobileOnly, isTablet } = useBrowserState()

    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-T576FX8' })
    }, [])

    useEffect(() => {
        if (typeof window !== 'undefined') window.scrollTo(0, 0)
    }, [ location ])

    return (
        <div className={ cx('browser', browser, classes.root) }>
            <ThemeProvider {...{
                theme: {
                    browser,
                    isMobile,
                    isMobileOnly,
                    isTablet,
                    ...variables,
                }
            } }>
                <Normalize>
                    <Switch>
                        <Route exact path="/" render={ ({ match }) =>
                            <Redirect locale="en" to="/en" />
                        } />

                        <Route exact path={base} component={ ({ match }) =>
                            <Home content={translations.find(({ slug }) => match.params.locale === slug)} />
                        } />

                        <Route exact path={ `${base}/press` } component={ ({ match }) =>
                            <Press content={translations.find(({ slug }) => match.params.locale === slug)} />
                        } />

                        <Route exact path={ `${base}/privacy` } component={ ({ match }) =>
                            <Privacy content={translations.find(({ slug }) => match.params.locale === slug)} />
                        } />

                        <Route exact path={ `/en/privacy/california` } component={ () =>
                            <Privacy california={true} content={translations.find(({ slug }) => 'en' === slug)} />
                        } />

                        <Route exact path={ `${base}/terms` } component={ ({ match }) =>
                            <Terms content={translations.find(({ slug }) => match.params.locale === slug)} />
                        } />

                        <Route exact path={ `${base}/` } component={ ({ match }) =>
                            <Error content={translations.find(({ slug }) => match.params.locale === slug)} />
                        } />

                        <Route exact path="*" render={ () =>
                            <Error content={translations.find(({ slug }) => 'en' === slug)} />
                        } />
                    </Switch>
                </Normalize>
            </ThemeProvider>
        </div>
    )
}

export default is(style)(withRouter(Root))
