import en from './en'
import de from './de'
import ru from './ru'
import kr from './kr'
import zhCN from './zh-cn'
import zhTW from './zh-tw'

const translations = [
    en,
    de,
    ru,
    kr,
    zhCN,
    zhTW,
]

const getLocaleBase = () => `/:locale(${translations.map(({ slug }) => slug).join('|')})?`

export {
    getLocaleBase,
}

export default translations
