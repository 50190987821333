export default ({
    spaces,
    colors,
    buttons,
}) => ({
    key: { name: 'LanguageSuggestion' },

    languageSuggestion: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        transformOrigin: 'center',
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        color: colors.white,
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none',
        transition: 'all ease-in-out .25s',
        zIndex: 11,

        '&.loaded': {
            opacity: 1,
            visibility: 'visible',
            pointerEvents: 'auto',
        },
    },

    inner: {
        width: '95%',
        maxWidth: 400,
        padding: spaces[3],
        transform: 'scale(.9)',
        backgroundColor: colors.black,
        transition: 'all ease-in-out .25s',

        '.loaded &': {
            transform: 'scale(1)',
        },
    },

    close: {
        position: 'absolute',
        top: 0,
        left: '100%',
        transform: 'translate(-60%, -40%)',
    },

    icon: {
        marginBottom: spaces[1],
    },

    languageLabel: {
        color: colors.blonde,
    },

    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: spaces[2],
    },

    link: {
        ...buttons.base,
        ...buttons.sm,
        ...buttons.yellow,
    },

    btn: {
        marginLeft: spaces[1],
    },
})
