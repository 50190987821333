const promiseDelay = async (promise, minimumDelay = 500, options = {}) => {
	if (typeof window === 'undefined') return promise

	let promiseError

	promise = promise.catch(error => {
		promiseError = error
	})

	const [ value ] = await Promise.all([
		promise,
		new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve()
			}, minimumDelay)
		}),
	])

	return promiseError ? Promise.reject(promiseError) : value
}

export default promiseDelay