const colors = {
    blonde: '#f7941d',
    blue: '#202d3a',

    white: '#FFFFFF',
    bare: '#F4F4F4',
    gallery: '#ECECEC',
    aldo: '#D9D9D9',
    silverEdge: '#555555',
    boulder: '#808080',
    black: '#0a0a0a',
}

const spaces = [ 8, 16, 24, 40, 60, 80 ]

const widths = {
    mi: {
        maxWidth: 750 + (spaces[1] * 2),
        padding: [0, spaces[2]],
        margin: 'auto',
    },

    sm: {
        maxWidth: 970 + (spaces[1] * 2),
        padding: [0, spaces[2]],
        margin: 'auto',
    },

    md: {
        maxWidth: 1070 + (spaces[1] * 2),
        padding: [0, spaces[2]],
        margin: 'auto',
    },

    lg: {
        maxWidth: 1170 + (spaces[1] * 2),
        padding: [0, spaces[2]],
        margin: 'auto',
    },

    xlg: {
        maxWidth: 1250 + (spaces[1] * 2),
        padding: [0, spaces[2]],
        margin: 'auto',
    },
}

const fontstacks = {
    primary: { fontFamily: `'IBM Plex Mono', monospace` },
    secondary: { fontFamily: `'Noto Sans KR', 'Noto Sans SC', 'Noto Sans TC', sans-serif` },
}

const breakpoints = {
    xs: '@media only screen and (min-width: 320px)',
    sm: '@media only screen and (min-width: 540px)',
    md: '@media only screen and (min-width: 768px)',
    lg: '@media only screen and (min-width: 1084px)',
    xl: '@media only screen and (min-width: 1400px)',

    xs2sm: '@media only screen and (min-width: 320px) and (max-width: 540px)',
    sm2md: '@media only screen and (min-width: 540px) and (max-width: 768px)',
    md2lg: '@media only screen and (min-width: 768px) and (max-width: 1084px)',
    lg2xl: '@media only screen and (min-width: 1084px) and (max-width: 1400px)',

    mw: {
        xs: '@media only screen and (max-width: 320px)',
        sm: '@media only screen and (max-width: 540px)',
        md: '@media only screen and (max-width: 768px)',
        lg: '@media only screen and (max-width: 1084px)',
        xl: '@media only screen and (max-width: 1400px)',
    },
}

const typography = {
    t1: {
        display: 'block',
        fontSize: 35,
        lineHeight: '40px',
        ...fontstacks.secondary,
        fontWeight: 900,
        textTransform: 'uppercase',
        '-webkit-mask-image': 'url(/texture-mask2.png)',
    },

    t2: {
        display: 'block',
        fontSize: 22,
        lineHeight: '30px',
        ...fontstacks.secondary,
        fontWeight: 900,
        textTransform: 'uppercase',
        '-webkit-mask-image': 'url(/texture-mask2.png)',
    },

    t3: {
        display: 'block',
        fontSize: 22,
        lineHeight: '26px',
        ...fontstacks.secondary,
        fontWeight: 900,
        textTransform: 'uppercase',
        '-webkit-mask-image': 'url(/texture-mask2.png)',
    },

    lg: {
        fontSize: 20,
        lineHeight: '28px',
        textTransform: 'uppercase',
    },

    md: {
        fontSize: 18,
        lineHeight: '30px',
        ...fontstacks.primary,
    },

    sm: {
        fontSize: 14,
        lineHeight: '18px',
        ...fontstacks.primary,
    },

    mi: {
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 300,
        textTransform: 'uppercase',
        ...fontstacks.primary,
    },

    content: {
        '& p': {
            fontSize: 14,
            lineHeight: '18px',
            ...fontstacks.primary,
            marginBlockStart: '1em',
            marginBlockEnd: '1em',

            '&:first-child': {
                marginBlockStart: 0,
            },
            '&:last-child': {
                marginBlockEnd: 0,
            },
        }
    }
}

const buttons = {
    base: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.black,
        ...fontstacks.secondary,
        textTransform: 'uppercase',
        color: colors.white,
        whiteSpace: 'nowrap',
        transition: 'background-color .25s ease-in-out, color .25s ease-in-out',

        '&:hover:not([disabled])': {
            backgroundColor: colors.white,
            color: colors.black,
        },

        '&[disabled]': {
            backgroundColor: colors.gallery,
            color: colors.boulder,
            userSelect: 'none',
            cursor: 'not-allowed',
        },
    },

    mi: {
        padding: [spaces[0], spaces[1]],
        ...typography.sm,
        ...fontstacks.secondary,
    },

    sm: {
        padding: [spaces[1], spaces[2]],
        ...typography.md,
        ...fontstacks.secondary,
    },

    md: {
        padding: [spaces[1], spaces[2]],
        ...typography.lg,
        ...fontstacks.secondary,

        [breakpoints.md]: {
            padding: [spaces[1], spaces[3]],
        },
    },

    white: {
        backgroundColor: colors.white,
        color: colors.black,

        '&:hover:not([disabled])': {
            backgroundColor: colors.black,
            color: colors.white,
        },
    },

    yellow: {
        backgroundColor: colors.blonde,
        color: colors.white,

        '&:hover:not([disabled])': {
            backgroundColor: colors.white,
            color: colors.blonde,
        },
    },
}

export default {
    colors,
    spaces,
    widths,
    breakpoints,
    fontstacks,
    typography,
    buttons,
}
