export default ({
    spaces,
    typography,
    colors,
    breakpoints,
}) => ({
    key: { name: 'Press' },

    heading: {
        ...typography.t1,
        float: 'left',
        padding: [0, spaces[3], spaces[3], 0],
        color: colors.white,

        '&.yellow': {
            width: '100%',
            color: colors.blonde,
        },
    },

    description: {
        clear: 'left',
    },

    about: {
        padding: [spaces[5], 0],
        backgroundColor: colors.blonde,
        backgroundImage: 'url(/yellow-glitch.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',

        [breakpoints.md]: {
            padding: [spaces[5], 0],
        },

        [breakpoints.lg]: {
            padding: [spaces[5]*2, 0],
        },
    },

    krafton: {
        padding: [spaces[5], 0],
        backgroundColor: colors.blue,
        backgroundImage: 'url(/blue-glitch.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',

        [breakpoints.md]: {
            padding: [spaces[5], 0],
        },

        [breakpoints.lg]: {
            padding: [spaces[5]*2, 0],
        },
    },

    kraftonLogo: {
        width: 180,
        margin: [0, 0, spaces[2], 0],

        '& > div, & svg': {
            width: 180,
        },

        [breakpoints.md]: {
            float: 'left',
            margin: [spaces[2], spaces[3], spaces[2], 0],
        },
    },

    links: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: [spaces[5], 0],
        backgroundColor: colors.white,

        [breakpoints.md]: {
            padding: [spaces[5], 0],
        },

        [breakpoints.lg]: {
            padding: [spaces[5]*2, 0],
        },
    },

    linksCol: {
        marginTop: spaces[3],

        '&:first-child': {
            marginTop: 0,
        },
        
        [breakpoints.md]: {
            marginTop: 0,
        },
    },

    linkDescription: {
        textTransform: 'uppercase',
        color: colors.black,

        '& > a': {
            display: 'inline-block',
            position: 'relative',
            color: colors.blonde,

            '&::after': {
                display: 'block',
                width: '100%',
                height: 1,
                position: 'absolute',
                top: 'calc(100% - 2px)',
                left: 0,
                backgroundColor: colors.blonde,
                content: '""',
            },
        },
    },
})
