import React from 'react'
import is from 'react-jss'

import { styles } from '../../../config'
const { normalize } = styles

/**
 * Normalize
 * 
 * @param {mixed} children
 */
const Normalize = ({
    children,
}) => children

export default is(normalize)(Normalize)
