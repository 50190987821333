import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import is from 'react-jss'

import translations from '../../../config/translations'

import IconButton from '../IconButton'

import style from './style'

const LanguageSelector = ({
    classes,
    locale,
}) => {
    const [ active, setActive ] = useState(false)

    return (
        <div className={classes.languageSelector}>
            <div className={ cx(classes.options, { active }) }>
                { translations.map(({ slug, name }, i) => (
                    <Link { ...{ key: i, to: `/${slug}`, className: cx(classes.option, { active: slug === locale }) } }>{ name }</Link>
                )) }
            </div>
            <IconButton { ...{ active, name: 'globe' } } clickHandler={ () => setActive(!active) } />
        </div>
    )
}

export default is(style)(LanguageSelector)