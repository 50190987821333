export default ({
    spaces,
    breakpoints,
    typography,
    colors,
}) => ({
    key: { name: 'Privacy' },

    wrapper: {
        padding: [110 + spaces[3], 0, spaces[3]],

        [breakpoints.lg]: {
            padding: [110 + spaces[5], 0, spaces[5]],
        },
    },

    toggle: {
        display: 'inline-flex',
        alignItems: 'center',
        margin: [0, 0, spaces[2]],
        ...typography.sm,
        color: colors.blonde,
        textDecoration: 'underline',

        [breakpoints.lg]: {
            margin: [0, 0, spaces[3]],
        },

        '&.california': {
            flexDirection: 'row-reverse',
        },
    },

    arrow: {
        margin: [0, 0, 0, spaces[0]],
        transformOrigin: 'center',

        '.california &': {
            margin: [0, spaces[0], 0, 0],
            transform: 'rotate(180deg)',
        },
    },

    heading: {
        margin: [0, 0, spaces[3]],
        ...typography.t1,
        color: colors.white,
    },

    toc: {
        margin: [spaces[3], 0],
        color: colors.white,
        active: colors.blonde,
        '& li:hover': {
            color: colors.blonde,
        }
    },

    content: {
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            margin: [spaces[3], 0, spaces[2]],
        },

        '& h1': {
            marginTop: 0,
        },

        '& h1, & h2': {
            ...typography.t1,
            color: colors.white,
        },

        '& h2': {
            scrollMarginTop: '68px',
            "@supports (-webkit-hyphens:none)": {
                paddingTop: '68px',
                marginTop: '-68px',
            }
        },

        '& h3, & h4': {
            ...typography.t2,
            color: colors.white,
        },

        '& h5, & h6': {
            ...typography.t3,
            color: colors.white,
        },

        '& ul, & ol': {
            padding: 0,
            margin: 0,
            listStyle: 'none',
            marginBottom: spaces[2],
        },

        '& li': {
            marginBottom: spaces[1],
            paddingLeft: spaces[2],
            position: 'relative',
            ...typography.md,
            color: colors.white,

            [breakpoints.sm]: {
                ...typography.sm,
            },

            '&:last-child': {
                marginBottom: 0,
            },

            '&::before': {
                display: 'block',
                width: spaces[0] / 2,
                height: spaces[0] / 2,
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translate(0, -50%)',
                borderRadius: spaces[0] / 2,
                backgroundColor: colors.blonde,
                content: '""',
            },
        },

        '& p': {
            marginBottom: spaces[2],
            ...typography.md,

            [breakpoints.sm]: {
                ...typography.sm,
            },

            '&:last-of-type, &:last-child': {
                marginBottom: 0,
            },

            '&:first-child': {
                marginBottom: spaces[2],
            },
        },

        '& strong': {
            fontWeight: 700,
        },

        '& a': {
            color: colors.blonde,
        },

        '& table': {
            width: '100%',
            borderCollapse: 'collapse',
        },

        '& th, & td': {
            padding: spaces[1],
            ...typography.md,
            color: colors.white,
            border: `1px solid ${colors.white}`,
            textAlign: 'center',

            [breakpoints.sm]: {
                ...typography.sm,
            },
        },
    },
})
